import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WalletNew from "./pages/WalletNew";
import Home from './pages/Home';
import MobileNav from './components/layout/Navbar/MobileNav/MobileNav';
import Error from './pages/Error';
import TestnetCampaignMint from "./pages/TestnetCampaignMint";
import Swap from './pages/Swap';
import DesktopNav from './components/layout/Navbar/DesktopNav/DesktopNav';
import { Suspense, useState } from 'react';
import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import { useSelector } from 'react-redux';
import Liquidity from './pages/Liquidity';
import MarketPalace from './pages/MarketPlace';
import CreateItem from './pages/CreateItem';
import SinglePage from './pages/SinglePage';
import NFTProfile from './pages/NFTProfile';
import CollectionNFT from './pages/CollectionNFT';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import Dashboard from './pages/Dashboard';
import Borrow from './pages/Borrow';
import Desosit from './pages/Deposit';
import MyNFT from './pages/MyNFT';
import Pools from './pages/Pools';
import LiquidityNew from './pages/LiquidityNew';
//import Withdraw from './pages/Withdraw';
import Repay from './pages/Repay';
import RepayUSDC from './pages/RepayUSDC';

import Footer from './components/Footer';
import HealthFactor from './pages/HealthFactor';
import Profile from './pages/Profile';
import MyCollection from './pages/MyCollection';
import CreateCollection from './pages/CreateCollection';
import WithdrawLiquidity from './pages/WithdrawLiquidity';
import TokenForBorrow from './components/Token/TokenForBorrow';
import NewWithDraw from './pages/NewWithDraw';
import Auction from './pages/Auction';
import AllAuction from './pages/AllAuction';
import MyAuctions from './pages/MyAuctions';
import WhitelistedNFT from './pages/WhitelistedNFT';
import WhiteListCollection from './pages/WhiteListCollection';
import AuctionUsdc from "./pages/AuctionUSDC"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import BorrowUsdc from "./pages/BorrowUsdc"
const TRACKING_ID = "G-CDELE7800K"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
  const [showNav, setShowNav] = useState(true);
  useEffect(() => {
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);
  // getting wallet info from redux
  // const algoAdd = useSelector(state => state.token.myAlgoAddress);
  // const algoBalance = useSelector(state => state.token.myAlgoBalance);
  // const walletAddress = useSelector(state => state.token.walletConnectAddress);
  // const walletAmount = useSelector(state => state.token.walletConnectBalance);
  // const walletConnectStatus = useSelector(state => state.token.walletConnectStatus);
  return (
    <>

      <Router>

        <Routes>
          <Route path='/' element={<WalletNew />} />
          <Route path='/error' element={<Error />} />
          <Route path='/TestnetCampaignMint' element={<TestnetCampaignMint />} />
          <Route path='/*' element={<PrivateRoute />} >
            <Route path='home' element={<Home />} />
            <Route path='swap' element={<Swap />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='marketplace' element={<MarketPalace />} />
            <Route path='collection' element={<CollectionNFT />} />
            <Route path='mynfts' element={<MyNFT />} />
            <Route path='borrow' element={<Borrow />} />
            <Route path='borrowUsdc' element={<BorrowUsdc />} />
            <Route path='pools' element={<Pools />} />
            <Route path='repay' element={<Repay />} />
            <Route path='repayUSDC' element={<RepayUSDC />} />
            <Route path='health' element={<HealthFactor />} />
            <Route path='whitelisted-nft' element={<WhitelistedNFT />} />
            <Route path='whitelisted-collection' element={<WhiteListCollection />} />
            <Route path='liquidity' element={<LiquidityNew />} />
            <Route path='profile' element={<Profile />} />
            <Route path='mycollection' element={<MyCollection />} />
            <Route path='mycollection/:create' element={<CreateCollection />} />
            <Route path='withdraw-test' element={<WithdrawLiquidity />} />
            {/*<Route path='withdraw' element={<Withdraw />} /> */}
            <Route path='withdraw' element={<NewWithDraw />} />
            <Route path='supply' element={<TokenForBorrow />} />
            <Route path='deposit' element={<Desosit />} />
            <Route path='mint' element={<CreateItem />} />
            <Route path='nft-profile' element={<NFTProfile />} />
            <Route path='single' element={<SinglePage />} />
            <Route path='auction' element={<Auction />} />
            <Route path='auction-usdc' element={<AuctionUsdc />} />
            <Route path='all-auction' element={<AllAuction />} />
            <Route path='my-auction' element={<MyAuctions />} />
            {/*<Route path='liquidity' element={<Liquidity />} /> */}
          </Route>

        </Routes>
        <ToastContainer theme="colored" pauseOnFocusLoss={false} />
        <MobileNav />
      </Router>

      <Footer />


    </>

  );
}

export default App;
