import { Box, Container, HStack, useColorModeValue, VStack, Text, Image, Progress, Divider, Radio, Checkbox, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Alert, AlertIcon, useDisclosure, ModalCloseButton, Center, Input, Spinner, Select, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import pagoCoin from '../components/images/pago-head.png';
import pago from '../components/images/Pago-Coin.png';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    Timestamp,
    query,
    where,
    QuerySnapshot,
} from "firebase/firestore";
import { db } from '../components/firebase/FirebaseConfig';
import axios from 'axios';
import algosdk, { algosToMicroalgos, bigIntToBytes, bytesToBigInt, decodeAddress, getApplicationAddress, microalgosToAlgos } from 'algosdk';
import { useNavigate } from 'react-router-dom';
import { getAllAssetsAction, myAlgoWalletInfo, PoolAmountAction } from '../components/redux/actions';
import { rates } from '../components/UtilizationRate/UtilizationRateData';
import { API_URL } from '../url';
import { peraWallet } from "./WalletNew";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { toast } from "react-toastify"
import base32Decode from 'base32-decode';

//const borrowAppId = parseInt(process.env.REACT_APP_BORROW_APP_ID);

const animationVariants = {
    hidden: {
        opacity: 0,
        scale: 0,
        rotate: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
    exit: {
        opacity: 0,
        scale: 0,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
};

function WhitelistedNFT(props) {
    const navigate = useNavigate();
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const appIdFromRedux = useSelector(state => state.token.borrow_app_id);
    //const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const walletAddress = useSelector(state => state.token.walletConnectAddress);
    const walletAmount = useSelector(state => state.token.walletConnectBalance);
    const walletConnectStatus = useSelector(state => state.token.walletConnectStatus);
    const deposit_rate = useSelector(state => state.token.deposit_rate);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const textColor = useColorModeValue("gray.600", "whiteAlpha.900");
    const [allNfts, setAllNfts] = useState([]);
    const [allNftsCollection, setAllNftsCollection] = useState([]);
    const [allMintedItemFromFirebase, setAllMintedItemFromFirebase] = useState([]);
    const [allFloorPriceFromFirebase, setAllFloorPriceFromFirebase] = useState();
    const [whiteListItem, setWhiteListItem] = useState([]);
    const nftCollectionRef1 = collection(db, "whiteListedNFT");
    const allMintedItem = collection(db, "whiteListMintedItem");
    const allFloorPriceRef = collection(db, "whitelisted_nft_collection_floor_prices");
    const allNFTCollectionRef = collection(db, "WhiteListedNFTCollection");
    const nftCollectionRef = collection(db, "nfts");
    const nftBorrowRef = collection(db, "borrow");
    const [checkbox, setCheckBox] = useState(true);
    const [checkBoxItem, setCheckBoxItem] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const modal1 = useDisclosure();
    const modal2 = useDisclosure();
    const modal3 = useDisclosure();
    /*
    const modal4 = useDisclosure();
    const [item1, setItem1] = useState(false);
    const [item2, setItem2] = useState(false);
    const [item3, setItem3] = useState(false);
    const [item4, setItem4] = useState(false);
    const [item5, setItem5] = useState(false);
    const [data, setData] = useState([]);
    const [borrowAmount, setAmount] = useState(0);
    const nftDepositRef = collection(db, "deposit");
    const [totalDeposit, setDeposit] = useState();
    const [totalBorrow, setBorrow] = useState();
    const [poolAmountNew, setPoolAmountNew] = useState();
    const borrowInfoRef = collection(db, "borrow-info");
    const [borrowInfo, setBorrowInfo] = useState()
    const [checkAmount, setCheckAmount] = useState(false)
    const dispatch = useDispatch();
    const poolAmount = useSelector(state => state.token.pool_amount);
    const [sliderValue, setSliderValue] = useState(0);
    let totalBorrowAmount = 0;
    let totalDipositAmount = 0;
    const [fileDataURL, setFileDataURL] = useState("https://ipfs.algonft.tools/ipfs/QmXkZDW83bhpcHkS6uiwUHWyqdppSqNL32mSuwyn1DMqgV");
    const [collectionName, setCollectionName] = useState();
    const [price, setPrice] = useState();
    const [status, setStatus] = useState(true);
    const [image, setImage] = useState(true);

    */
    const [option, setOption] = useState("");
    const [itemNumber, setItemNumber] = useState("");
    const nftCloseAuctionRef = collection(db, "closeAuction");
    const whiteListMintedItemRef = collection(db, "whiteListMintedItem");
    const [popUp, alertPopUp] = useState(false);
    const [selectItem, setSelectItem] = useState();
    const [mostlyFrensUrl, setMostlyFrensUrl] = useState();

    let whiteListedNftData = undefined
    if (allNfts?.length > 0) {
        whiteListedNftData = allNfts?.map((item, key) => (
            item.mint == true ? "" :
                <option value={item.unit_name}>
                    {item.unit_name}
                </option>
        ))
    }

    let whiteListedNftCollectionData = undefined
    if (allNftsCollection?.length > 0) {
        whiteListedNftCollectionData = allNftsCollection?.map((item, key) => (
            <option value={item.creator_address} name={item.collection_name}>
                {item.collection_name}
            </option>
        ))
    }


    useEffect(() => {
        const getNfts = async () => {
            const data = await getDocs(nftCollectionRef1);
            setAllNfts(data.docs.map((doc) => ({ ...doc.data(), nftId: doc.id })));

        };

        getNfts();

        const getNftsCollection = async () => {
            const data = await getDocs(allNFTCollectionRef);
            setAllNftsCollection(data.docs.map((doc) => ({ ...doc.data(), nftId: doc.id })));

        };

        getNftsCollection();

        const allMintedItemAction = async () => {
            const data = await getDocs(allMintedItem);
            setAllMintedItemFromFirebase(data.docs.map((doc) => ({ ...doc.data(), nftId: doc.id })));

        };

        allMintedItemAction();

        const allFloorPriceAction = async () => {
            const data = await getDocs(allFloorPriceRef);
            setAllFloorPriceFromFirebase(data.docs.map((doc) => ({ ...doc.data(), nftId: doc.id })));

        };

        allFloorPriceAction();

    }, []);

    /*
    console.log(allNftsCollection);
    console.log(allFloorPriceFromFirebase);
    console.log(option);
    console.log(allNfts[0].nftId);
    let test = undefined;
     */

    const [floor_price_val, setFloorPriceVal] = useState();
    let searchItemName = undefined;
    let getSearchItem = {};
    let getFloorPrice = undefined;
    let select_item_info = undefined;
    let select_item_id = undefined;
    const CID = require('cids');
    const multihashes = require('multihashes');

    const handleSearchItem = async (e) => {
        e.preventDefault();
        setMostlyFrensUrl();
        setSelectItem();
        if (option && itemNumber) {
            modal3.onOpen();
            //console.log(option);
            //console.log(allNftsCollection);// get floor price according to collection name from firebase --- start

            const getItem = allNftsCollection.find((item) => item.creator_address == option);
            //console.log(getItem);
            const getFloorPriceFromApi = allFloorPriceFromFirebase.find((item) => console.log(item.key))
            //console.log(getFloorPriceFromApi);

            // get floor price according to collection name from firebase --- end
            const collection_name = allNftsCollection.filter((item) => item.creator_address == option);

            getFloorPrice = collection_name[0].floor_price;
            //console.log(getFloorPrice);

            const between = (itemNumber, min, max) => {
                return itemNumber >= min && itemNumber <= max;
            };

            setFloorPriceVal(getFloorPrice);
            if (collection_name[0].name === "Al_Goanna") {
                if (between(itemNumber, 1, 489)) {
                    searchItemName = "goan"
                    if (itemNumber.length == 1) {
                        searchItemName = searchItemName + "00" + itemNumber
                    } else if (itemNumber.length == 2) {
                        searchItemName = searchItemName + "0" + itemNumber
                    } else if (itemNumber.length == 3) {
                        searchItemName = searchItemName + itemNumber
                    }
                } else {
                    toast.error("Invalid Item Number");
                    modal3.onClose();
                }
            } else if (collection_name[0].name === "Yieldlings_Ultra_Rare") {
                if (between(itemNumber, 1, 500)) {
                    searchItemName = "YLDGR"
                    if (itemNumber.length == 1) {
                        searchItemName = searchItemName + "00" + itemNumber
                    } else if (itemNumber.length == 2) {
                        searchItemName = searchItemName + "0" + itemNumber
                    } else if (itemNumber.length == 3) {
                        searchItemName = searchItemName + itemNumber
                    }
                } else {
                    toast.error("Invalid Item Number");
                    modal3.onClose();
                }
            } else if (collection_name[0].name === "DragonFi_Alpha_Dragons") {
                if (between(itemNumber, 1, 500)) {
                    searchItemName = "ADGN"
                    if (itemNumber.length == 1) {
                        searchItemName = searchItemName + "00" + itemNumber
                    } else if (itemNumber.length == 2) {
                        searchItemName = searchItemName + "0" + itemNumber
                    }
                } else {
                    toast.error("Invalid Item Number");
                    modal3.onClose();
                }
            } else if (collection_name[0].name === "Mostly_Frens") {
                if (between(itemNumber, 1, 3573)) {
                    searchItemName = "MFER"
                    if (itemNumber.length == 1) {
                        searchItemName = searchItemName + "000" + itemNumber
                    } else if (itemNumber.length == 2) {
                        searchItemName = searchItemName + "00" + itemNumber
                    } else if (itemNumber.length == 3) {
                        searchItemName = searchItemName + "0" + itemNumber
                    } else if (itemNumber.length == 4) {
                        searchItemName = searchItemName + itemNumber
                    }
                } else {
                    toast.error("Invalid Item Number");
                    modal3.onClose();
                }
            } else if (collection_name[0].name === "Flemish_Giants") {
                if (between(itemNumber, 1, 102)) {
                    searchItemName = "FG"
                    if (itemNumber.length == 1) {
                        searchItemName = searchItemName + "0" + itemNumber
                    } else if (itemNumber.length == 2) {
                        searchItemName = searchItemName + itemNumber
                    } else if (itemNumber.length == 3) {
                        searchItemName = searchItemName + itemNumber
                    }
                } else {
                    toast.error("Invalid Item Number");
                    modal3.onClose();
                }
            } else if (collection_name[0].name === "Crazy_Goose_Flock") {
                if (between(itemNumber, 1, 4444)) {
                    searchItemName = "CGF" + itemNumber
                } else {
                    toast.error("Invalid Item Number");
                    modal3.onClose();
                }
            } else if (collection_name[0].name === "MNGO") {
                if (between(itemNumber, 1, 2999)) {
                    searchItemName = "MNGO" + itemNumber
                } else {
                    toast.error("Invalid Item Number");
                    modal3.onClose();
                }
            } else {
                toast.error("Invalid Collection Name");
            }
            try {
                const indexerServer = "https://mainnet-idx.algonode.cloud";
                const indexerToken = "";
                const indexerPort = '';
                const indexerClient = new algosdk.Indexer(indexerToken, indexerServer, indexerPort);
                const assets = [];
                let nextToken = null;
                let itemFound = false;  // declare it before do-while loop
                do {
                    const searchResponse = await indexerClient.searchForAssets()
                        .creator(option)
                        .nextToken(nextToken)
                        .do();
                    //console.log('searchResponse', searchResponse);
                    assets.push(...searchResponse.assets.map((assetInfo) => {
                        //console.log('assetInfo', assetInfo);
                        if (assetInfo.params["unit-name"] == searchItemName) {
                            itemFound = true;
                            select_item_info = assetInfo.params;

                            if (assetInfo.params["creator"] == "MOSTLYSNUJP7PG6Q3FNJCGGENQXMOH3PXXMIJRFLODLG2DNDBHI7QHJSOE") {
                                let version = 1;
                                let codec = "raw";
                                let hashAlg = "sha2-256";
                                // Decode base64 string to Buffer
                                let reserveBuffer = algosdk.decodeAddress(assetInfo.params['reserve']).publicKey;
                                // Hash the address buffer using the specified hash function
                                let hashedReserve = multihashes.encode(reserveBuffer, hashAlg);
                                // Create an IPFS CID with the hash from the reserve field
                                let ipfsCid = new CID(version, codec, hashedReserve);
                                let ipfsImageMetadata = `https://ipfs.io/ipfs/${ipfsCid.toString()}`;
                                //console.log("++++", ipfsCid.toString());
                                fetch(ipfsImageMetadata)
                                    .then(response => response.json())
                                    .then(metadata => {
                                        //console.log('metadata', metadata);
                                        let imageCid = metadata.image.replace('ipfs://', '');
                                        let imageUrl = `https://ipfs.io/ipfs/${imageCid}`;
                                        setMostlyFrensUrl(imageUrl);
                                        console.log(imageUrl);
                                    })
                                    .catch(error => {
                                        console.error('Error:', error);
                                    });
                            }

                            setTimeout(() => {
                                setSelectItem(assetInfo.params)
                                modal3.onClose();
                            }, 7000);

                            //console.log("============",mostlyFrensUrl);
                            //console.log("++++++++++", selectItem)
                            // getSearchItem = assetInfo.params;
                            return assetInfo.params
                        } else {
                            select_item_info = ""
                        }
                    }))

                    nextToken = searchResponse['next-token'];
                } while (nextToken && !itemFound);

            } catch (error) {
                console.error(error);
                console.log('API call failed with error:', error);
                toast.error("Something went wrong while searching assets!!!");
            }
        } else {
            toast.warning("Please select collection name & item number");
        }
    }

    // get proper image url for the selected item
    let imageUrlForSelectedItem = undefined;
    if (selectItem) {
        console.log(option);
        if (selectItem.creator === option && option != "D5J7H7PIYKLY2U6A5OFUAC7GQHTHSXXNX65DSD3CJYPBV2MVK6NTNW44CA" && option != "5DYIZMX7N4SAB44HLVRUGLYBPSN4UMPDZVTX7V73AIRMJQA3LKTENTLFZ4") {
            console.log(option);
            let result = selectItem.url.replace("://", "/");
            console.log(result);
            imageUrlForSelectedItem = `https://ipfs.io/${result}`
        }
        // else if (selectItem.creator === "D5J7H7PIYKLY2U6A5OFUAC7GQHTHSXXNX65DSD3CJYPBV2MVK6NTNW44CA") {
        //     url = selectItem.url
        //     console.log(url);
        //     alert("HI")
        // }
        // else if (selectItem.creator === "5DYIZMX7N4SAB44HLVRUGLYBPSN4UMPDZVTX7V73AIRMJQA3LKTENTLFZ4") {
        //     url = selectItem.url;
        //     console.log(url);
        // }
        else {
            imageUrlForSelectedItem = selectItem.url
        }
    }

    const handleSubmit = async () => {
        /*
        console.log(selectItem);
        console.log(imageUrlForSelectedItem);
        console.log(select_item_info);

        console.log(floor_price_val);
        console.log(selectItem["unit-name"]);
        //console.log("++++++",mostlyFrensUrl);
        */
        let imgUrl = undefined;
        modal2.onOpen();
        // try {

        if ((floor_price_val / 1000) > algoBalance) {
            modal2.onClose();
            toast.error("Not enough fund");
        } else {
            //const collection_type = location.state ? true : false;
            if (selectItem.creator === option && option != "D5J7H7PIYKLY2U6A5OFUAC7GQHTHSXXNX65DSD3CJYPBV2MVK6NTNW44CA" && option != "5DYIZMX7N4SAB44HLVRUGLYBPSN4UMPDZVTX7V73AIRMJQA3LKTENTLFZ4") {
                //console.log(option);
                let result = selectItem.url.replace("://", "/");
                //console.log("---------------------", result);
                imgUrl = `https://ipfs.io/${result}`
            } else {
                imgUrl = selectItem.url;
            }

            const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
            const suggested_params = await algodClient.getTransactionParams().do();
            const creator = algoAdd;
            const defaultFrozen = false;
            //const unitName = grpname;
            const assetName = selectItem["unit-name"];
            const price = floor_price_val / 1000;
            const url = mostlyFrensUrl || imgUrl;
            const managerAddr = algoAdd;
            const reserveAddr = algoAdd;
            const freezeAddr = algoAdd;
            const clawbackAddr = algoAdd;
            const total = 1;                // NFTs have totalIssuance of exactly 1
            const decimals = 0;             // NFTs have decimals of exactly 0
            // const asset = check ? otherFile : url;
            // const asset = check ? asset_url2 : asset_url;
            const d = new Date();
            ////console.log("Pera Wallet connection check 2 ", peraWallet.isConnected, peraWallet.platform);

            const txn = algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject({
                from: creator,
                total,
                decimals,
                assetName,
                //unitName,
                //assetURL: asset,
                assetMetadataHash: undefined,
                defaultFrozen,
                freeze: freezeAddr,
                manager: managerAddr,
                clawback: clawbackAddr,
                reserve: reserveAddr,
                suggestedParams: suggested_params,
            });
            modal2.onClose();

            //const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());
            const singleTxnGroups = [{ txn: txn, signers: [algoAdd] }];

            const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
            // //console.log(signedTxn); return 0;
            const response = await algodClient.sendRawTransaction(signedTxn).do();
            modal2.onOpen();
            let assetID = null;
            // wait for transaction to be confirmed
            const ptx = await algosdk.waitForConfirmation(algodClient, response.txId, 4);
            assetID = ptx["asset-index"];
            console.log(mostlyFrensUrl);
            //setAssetID(assetID);
            //app-initializer function
            await addDoc(nftCollectionRef, { nft_id: assetID, name: assetName, price: floor_price_val / 1000, url: mostlyFrensUrl ? mostlyFrensUrl : selectItem.url, total: total, address: algoAdd, owner_address: algoAdd, assetID: assetID, sell_status: false });
            // getNfts();

            addDoc(nftCloseAuctionRef, { owner_address: algoAdd, buyer_address: "", assetID: assetID, auctionType: 'created', price: floor_price_val / 1000 });

            // need to work from here

            // console.log(selectItem.nftId);
            // console.log(selectItem.nftId);

            // const userDoc = doc(db, "whiteListedNFT", selectItem.nftId);
            // await updateDoc(userDoc, { mint: true, address: algoAdd, whiteListed: true, assetID: assetID, imageFromApi: selectItem.url });

            addDoc(whiteListMintedItemRef, { mint: true, whiteListed: true, imageFromApi: mostlyFrensUrl ? mostlyFrensUrl : imageUrlForSelectedItem, address: algoAdd, assetID: assetID, price: floor_price_val, name: selectItem["unit-name"], fullName: selectItem.name, borrow: false });
            alertPopUp(true);
            ////console.log();
            modal2.onClose();
            toast.success("NFT Minted Successfully")
            setTimeout(() => {

                alertPopUp(false);
                navigate("/single", { state: { nft: true, name: assetName, price: floor_price_val / 1000, url: mostlyFrensUrl ? mostlyFrensUrl : imageUrlForSelectedItem, total: total, address: algoAdd, option: option, assetID: assetID, type: "" } });
            }, 3000);

        }

        // } catch (error) {
        //     modal2.onClose();
        //     toast.error("Something went wrong !!!")
        // }
    }

    const check_for_mint_item = selectItem ? allMintedItemFromFirebase.filter((item) => item.name == selectItem["unit-name"]) : "";
    console.log(check_for_mint_item);


    // console.log(url);
    // console.log(url);
    return (
        <>
            <Box zIndex="100" top="0" position="fixed" width="100%">{walletConnectStatus ? <DesktopNav name={walletAddress} amount={walletAmount} /> : <DesktopNav name={algoAdd} amount={algoBalance} />}</Box>

            <Box minH="100vh" py="50px">
                {/* first component start from here  */}

                <Box w="100%" mt="70px">
                    <Container maxW={'3xl'} borderRadius="20px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" pb="20px">
                        <HStack alignItems="center" justifyContent="space-between" justifyItems="center" px="20px" py="30px">
                            <VStack align="left">
                                <Text fontSize="30px" fontWeight="600" mb="-5px">Testnet WhiteListed NFT Mint Form</Text>

                            </VStack>

                            <Image src={pagoCoin} h="50px" w="50px" />
                        </HStack>

                        <Stack display="flex" flexDirection="column" gap="5">
                            <Box display="flex" justifyContent="space-between">
                                <Box w="50%">
                                    <Box w="50%" textAlign="left">
                                        <Text fontWeight="500">Select NFT Collection</Text>
                                    </Box>
                                    <Box w="50%">
                                        <Select placeholder='Select option' value={option ?? ""}

                                            onChange={(e) => setOption(e.target.value)}>
                                            {whiteListedNftCollectionData}
                                        </Select>
                                    </Box>
                                </Box>
                                <Box w="50%">
                                    <Box w="50%" textAlign="left">
                                        <Text fontWeight="500">Insert Item Number</Text>
                                    </Box>
                                    <Box w="50%">
                                        <Input
                                            type="number"
                                            name='number'
                                            value={itemNumber ?? ""}
                                            required={true}
                                            onChange={(e) => setItemNumber(e.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </Box>

                            <Box mt="40px">
                                <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} type="submit" w="100%" onClick={handleSearchItem}>Find</Button>
                            </Box>

                        </Stack>

                        {selectItem?.creator ?

                            <Box display="flex" justifyContent="space-between" mt="50px">
                                <HStack w="30%">
                                    {mostlyFrensUrl ?
                                        <img src={mostlyFrensUrl} alt="preview" height="200px" width="200px" /> :
                                        (selectItem ?
                                            <img src={imageUrlForSelectedItem} alt="preview" height="200px" width="200px" /> : "")
                                    }
                                </HStack>

                                <HStack w="70%" px="30px">
                                    <Box w="50%">
                                        <FormControl>
                                            <FormLabel >Item Name</FormLabel>
                                            <Input
                                                type="text"
                                                //name='username'
                                                value={selectItem ? selectItem.name : ""}
                                            //onChange={(e) => setName(e.target.value)}
                                            />
                                        </FormControl>
                                    </Box>

                                    <Box w="50%">
                                        <FormControl>
                                            <FormLabel >Floor Price</FormLabel>
                                            <Input
                                                type="text"
                                                //name='username'
                                                value={floor_price_val ? (floor_price_val / 1000) : ""}

                                            //onChange={(e) => setName(e.target.value)}
                                            />
                                        </FormControl>
                                    </Box>

                                </HStack>

                            </Box>
                            : ""
                        }

                        {selectItem?.creator ? <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} type="submit" w="100%" mt="20px" onClick={handleSubmit} isDisabled={check_for_mint_item?.length > 0 ? true : false}> Mint</Button> : ""}


                    </Container>
                </Box>
            </Box>

            {/* ==================Loader Modal====================== */}
            <Modal isOpen={modal2.isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Creating NFT.....</ModalHeader>
                    <ModalBody alignItems="center" py="20px">
                        <HStack alignItems="center" justifyContent="space-evenly">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                h="120px"
                                w="120px"
                            />
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* ==================Loader Modal ends================= */}

            {/* ==================Loader Modal====================== */}
            <Modal isOpen={modal3.isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Searching NFT.....</ModalHeader>
                    <ModalBody alignItems="center" py="20px">
                        <HStack alignItems="center" justifyContent="space-evenly">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                h="120px"
                                w="120px"
                            />
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* ==================Loader Modal ends================= */}


        </>
    )
}

export default WhitelistedNFT

