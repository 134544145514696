import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, HStack, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, VStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import pago from '../images/Pago-Coin.png';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { API_URL } from '../../url';
import axios from 'axios';
import algosdk, { getApplicationAddress } from "algosdk";

function TrirdSlide({ checkBorrowValidity }) {
    const borrowItemFromRedux = useSelector(state => state.token.borrow_item);
    const supplyAmount = useSelector(state => state.token.supply_amount);
    const availableToBorrow = useSelector(state => state.token.available_to_borrow);
    const appIdFromRedux = useSelector(state => state.token.borrow_app_id);
    //const asset_id = borrowItemFromRedux.assetID;
    const borrowAmountFromRedux = useSelector(state => state.token.nft_borrow_amount);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const [exchange_Rate, setExchangeRate] = useState();
    const [pool_amount, setPool] = useState();

    const maxBorrowAmount = 5;
    const exchangeRate = () => {

        // await axios('http://192.168.0.135:12000/swap/checkOptin?address=' + address)
        axios(`${API_URL}swap/assetPrice`)
            .then(response => {
                if (response.status === 200) {
                    setExchangeRate(response.data)
                }

            })
            .catch(error => {
                console.error("Error fetching data", error);

            })
    }

    const pool_amount_func = async () => {
        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');

        const escrowAddress = await getApplicationAddress(appIdFromRedux);

        let accountInfo = await algodClient.accountInformation(escrowAddress).do();

        let accountAmount = algosdk.microalgosToAlgos(accountInfo.amount)

        setPool(accountAmount);
    }

    useEffect(() => {
        exchangeRate();
        pool_amount_func();
        if (checkBorrowValidity) {
            checkBorrowValidity((pool_amount > borrowAmountFromRedux) && (borrowAmountFromRedux <= maxBorrowAmount));
        }
    }, [pool_amount, borrowAmountFromRedux, maxBorrowAmount]);
    const usdcToAlgoAmount = (supplyAmount * exchange_Rate?.usdc_price) / exchange_Rate?.algo;
    console.log(borrowItemFromRedux);
    return (
        <>
            <TableContainer py="30px">
                {borrowItemFromRedux ?
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>NFT / Token</Th>
                                <Th>Price (ALGO)</Th>
                                <Th >Available to Borrow (ALGO)</Th>

                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td style={{ display: "flex", gap: 5 }}>
                                    {borrowItemFromRedux.map((item) => (
                                        <Image src={item?.imageFromApi} width="50px" h="50px" objectFit="contain" />
                                    ))}
                                </Td>
                                {/* <Td><Image src={borrowItemFromRedux[0]?.url} width="50px" h="50px" objectFit="contain" /></Td> */}
                                <Td><Text>{(borrowItemFromRedux[0]?.price) / 1000}</Text></Td>
                                <Td><Text>{availableToBorrow ? availableToBorrow.toFixed(2) : 0}</Text></Td>

                            </Tr>
                            {supplyAmount ?

                                <Tr>

                                    <Td><Text>USDC</Text></Td>
                                    <Td><Text>{usdcToAlgoAmount ? usdcToAlgoAmount.toFixed(2) : '0.00'}</Text></Td>
                                    <Td><Text>{availableToBorrow ? availableToBorrow.toFixed(2) : 0}</Text></Td>

                                </Tr>

                                : ""}
                        </Tbody>
                    </Table>
                    : ""}


            </TableContainer>
            <Divider />

            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                <Text fontSize="16px" fontWeight="600">Total borrowed</Text>
                <HStack>
                    <Image src={pago} w="25px" h="25px" />
                    <Text>{(borrowAmountFromRedux < pool_amount) && (borrowAmountFromRedux <= maxBorrowAmount) ? borrowAmountFromRedux : "Ooops! Max borrow amount > 5 ALGO!"}</Text>
                </HStack>
            </HStack>
            <Divider />
            <VStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                <IoIosCheckmarkCircleOutline size="50" color='green' />
                <Text fontSize="12px" fontWeight="600" color="green.500">You will be asked to approve all Debt Tokens and all NFT Tokens.</Text>
                <Text fontSize="12px" fontWeight="600" color="green.500">You can now continue and borrow your {borrowAmountFromRedux} ALGO.</Text>

            </VStack>
        </>
    )
}

export default TrirdSlide
