import { Box, Container, HStack, useColorModeValue, VStack, Text, Image, Progress, Divider, Radio, Checkbox, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Alert, AlertIcon, useDisclosure, ModalCloseButton, Center, Input, Flex, Heading, Select } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import pagoCoin from '../components/images/pago-head.png';
import pago from '../components/images/Pago-Coin.png';
import MainComponent from '../components/repayComponent/MainComponent';
import MainComponentUsdc from '../components/repayComponentUSDC/MainComponent';
import Header from '../components/header/Header';

function Repay() {
    const [option, setOption] = useState("algo");
    const textColor = useColorModeValue("gray.600", "whiteAlpha.500");

    return (
        <>
            <Header />
            <Box minH="100vh" py="50px">
                <Box w="100%" mt="70px">
                    <Container maxW={'3xl'} borderRadius="20px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" pb="20px">
                        <HStack alignItems="center" justifyContent="space-between" justifyItems="center" px="20px" py="30px">
                            <VStack align="left">
                                <Text fontSize="30px" fontWeight="600" mb="-5px">Repay</Text>
                                <Text fontSize="18px" color={textColor}>Make a repayment to your loan
                                </Text>
                            </VStack>

                            <Image src={pagoCoin} h="50px" w="50px" />
                        </HStack>

                        <HStack w="50%" ml="10px" mb="20px">
                            <Box>
                                <Box w="100%" textAlign="left" mb="5px">
                                    <Text fontWeight="500">Select Token</Text>
                                </Box>
                                <Box w="100%">
                                    <Select onChange={(e) => setOption(e.target.value)}>
                                        <option value='algo'>Algo</option>
                                        <option value='usdc'>USDC</option>
                                    </Select>
                                </Box>
                            </Box>
                        </HStack>
                        {option == "algo" ? <MainComponent /> : <MainComponentUsdc />}


                    </Container>
                </Box>
            </Box>
        </>
    )
}

export default Repay;
