import { Box, Container, HStack, useColorModeValue, VStack, Text, Image, Progress, Divider, Radio, Checkbox, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Alert, AlertIcon, useDisclosure, ModalCloseButton, Center, Input, Select } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import pagoCoin from '../components/images/pago-head.png';
import pago from '../components/images/Pago-Coin.png';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    Timestamp,
    where,
    query,
} from "firebase/firestore";
import { db } from '../components/firebase/FirebaseConfig';
import axios from 'axios';
import algosdk, { algosToMicroalgos, bigIntToBytes, bytesToBigInt, decodeAddress, getApplicationAddress, microalgosToAlgos } from 'algosdk';
import MyAlgoConnect from "@randlabs/myalgo-connect";
import { useNavigate } from 'react-router-dom';
import { PoolAmountAction } from '../components/redux/actions';
import { rates } from '../components/UtilizationRate/UtilizationRateData';
import { API_URL } from '../url';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { peraWallet } from "./WalletNew";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify"
import user from "../hooks/User";
import BigNumber from 'bignumber.js';
const nftWithdrawRef = collection(db, "withdraw");
const nftWithdrawUsdcRef = collection(db, "withdraw_usdc");
const borrowAppId = parseInt(process.env.REACT_APP_BORROW_APP_ID);
const borrowAppIdUSDC = parseInt(process.env.REACT_APP_USDC_POOL_ID);
const animationVariants = {
    hidden: {
        opacity: 0,
        scale: 0,
        rotate: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
    exit: {
        opacity: 0,
        scale: 0,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
};

function NewWithDraw(props) {
    const navigate = useNavigate();
    const [val, setVal] = useState();
    const [option, setOption] = useState("algo");
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const appIdFromRedux = useSelector(state => state.token.borrow_app_id);
    const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const walletAddress = useSelector(state => state.token.walletConnectAddress);
    const walletAmount = useSelector(state => state.token.walletConnectBalance);
    const walletConnectStatus = useSelector(state => state.token.walletConnectStatus);
    const allAssets = useSelector(state => state.token.assets_from_account_2);
    const deposit_rate = useSelector(state => state.token.deposit_rate);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const textColor = useColorModeValue("gray.600", "whiteAlpha.900");
    const [allNfts, setAllNfts] = useState([]);
    const nftCollectionRef = collection(db, "nfts");
    const nftBorrowRef = collection(db, "borrow");
    const [checkbox, setCheckBox] = useState(true);
    const [checkBoxItem, setCheckBoxItem] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const modal1 = useDisclosure();
    const modal2 = useDisclosure();
    const modal3 = useDisclosure();
    const [item1, setItem1] = useState(false);
    const [item2, setItem2] = useState(false);
    const [item3, setItem3] = useState(false);
    const [item4, setItem4] = useState(false);
    const [item5, setItem5] = useState(false);
    const [data, setData] = useState([]);
    const [borrowAmount, setAmount] = useState(0);
    const nftDepositRef = collection(db, "deposit");
    const [totalDeposit, setDeposit] = useState();
    //const [total_usdc_Deposit, setDepositUSDC] = useState();
    const [totalBorrow, setBorrow] = useState();
    const [poolAmountNew, setPoolAmountNew] = useState();
    const [poolAmountUSDC, setPoolAmountUSDC] = useState();
    const borrowInfoRef = collection(db, "borrow-info");
    const [borrowInfo, setBorrowInfo] = useState();
    const [checkAmountStatus, setCheckAmountStatus] = useState(false);
    //const [depositAccAmount, setDepositAccAmount] = useState();
    const dispatch = useDispatch();
    const poolAmount = useSelector(state => state.token.pool_amount);
    const [depositAmount, setDepositAmount] = useState(0);
    const borrowAppIdUSDC = parseInt(process.env.REACT_APP_USDC_POOL_ID);

    const depositRef = collection(db, "deposit");
    const deposit_usdc_Ref = collection(db, "depositUSDC");
    const depositSummaryRef = collection(db, "depositSummary");
    const depositSummarUsdcyRef = collection(db, "borrow_info_usdc");
    const [allDeposit, setAllDeposit] = useState();
    const [all_usdc_Deposit, setAll_USDC_Deposit] = useState();
    const [depositSum, setAllDepositSum] = useState();
    const [depositSumUsdc, setAllDepositSumUsdc] = useState();
    const [allWithdrawal, setAllWithdrawal] = useState();
    const [allInfo, setAllInfo] = useState();
    const [withdrawAMount, setWithdrawAMount] = useState();
    const [newWithdrawAMount, setNewWithdrawAMount] = useState(0);
    const [withdrawStatus, setWithdrawStatus] = useState(false);
    const [sliderValue, setSliderValue] = useState(0);
    const app_ID = useSelector(state => state.token.borrow_app_id);
    const nftDepositUSDCRef = collection(db, "depositUSDC");
    const interestDepositRef = collection(db, "interest_rate_index");
    const [totalDepositUsdc, setDepositUsdc] = useState();
    const nftBorrowUsdcRef = collection(db, "borrow-usdc");
    const [totalBorrowUsdc, setBorrowUsdc] = useState();
    const [deposit_interest_info, setDepositInterestInfo] = useState();
    //const [deposit_interest_info, setDepositInterestInfo] = useState();
    function isValidMicroAlgos(microalgos) {
        const maxMicroAlgos = new BigNumber(2).pow(253).minus(1);
        const microAlgosBN = new BigNumber(microalgos);
        return microAlgosBN.gte(0) && microAlgosBN.lt(maxMicroAlgos);
    }
    const marks = {

        0: <strong>0%</strong>,
        25: '25%',
        50: '50%',
        75: '75%',
        100: {
            style: {
                color: 'green',
            },
            label: <strong>100%</strong>,
        },
    };

    const usdcAmount = allAssets.find((a) => a["asset-id"] == "10458941");

    let total_Diposit_Amount = 0;
    let totalDipositAmount = 0;
    let totalBorrowAmount = 0;
    let total_usdc_deposit = 0;

    useEffect(() => {
        const getNfts = async () => {
            const data = await getDocs(nftCollectionRef);
            setAllNfts(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
        };

        getNfts();

        pool_amount_func();
        pool_amount_func_usdc();
        let totalUsdcDipositAmount = 0;
        let totalUsdcBorrowAmount = 0;

        let amount = 0;
        const utilisationRate = async () => {

            // let totalDipositAmount = 0;
            const depositData = await getDocs(nftDepositRef);
            await depositData.docs.map((doc) => {
                totalDipositAmount += parseFloat(doc.data().amount);
                // setDeposit(totalDipositAmount);
            })
            setDeposit(totalDipositAmount);
            // let totalBorrowAmount = 0;
            const borrowData = await getDocs(nftBorrowRef);
            await borrowData.docs.map((doc) => {
                amount += parseFloat(doc.data().amount);
                // setBorrow(totalBorrowAmount);
            })
            setBorrow(amount);
            let utilisationRateVar = totalBorrowAmount / totalDipositAmount;

        };

        utilisationRate();

        const getBorrowInfoFromFirebase = async () => {
            const data = await getDocs(borrowInfoRef);
            setBorrowInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getBorrowInfoFromFirebase();

        const getDepositInfo = async () => {
            const data = await getDocs(depositRef);
            setAllDeposit(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getDepositInfo();

        const getDeposit_USDC_Info = async () => {
            const data = await getDocs(deposit_usdc_Ref);
            // await data.docs.map((doc) => {
            //     total_usdc_deposit += parseFloat(doc.data().amount);
            //     console.log(doc.data().amount);
            //     // setDeposit(totalDipositAmount);
            // })
            setAll_USDC_Deposit(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
            //setDepositUSDC(total_usdc_deposit)
        };

        getDeposit_USDC_Info();

        const getWithdrawInfo = async () => {
            const data = await getDocs(nftWithdrawRef);
            setAllWithdrawal(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getWithdrawInfo();

        const getAllBorrowInfo = async () => {

            const q2 = query(collection(db, "deposit"), where("account", "==", algoAdd));
            const data2 = await getDocs(q2);

            data2.forEach(async (doc) => {
                let docData = doc.data();
                await setAllInfo({ ...allInfo, allInfo: docData });
            });

        };
        getAllBorrowInfo()

        const getDepositSUmmary = async () => {
            const data = await getDocs(depositSummaryRef);
            setAllDepositSum(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getDepositSUmmary();

        const depositAmount = async () => {
            const data = await getDocs(nftDepositRef);
            data.docs.map((doc) => {
                total_Diposit_Amount += parseFloat(doc.data().amount)
                setDeposit(total_Diposit_Amount);
            })
        };

        depositAmount();

        const utilisationRateForUSDC = async () => {


            const depositData = await getDocs(nftDepositUSDCRef);
            await depositData.docs.map((doc) => {
                totalUsdcDipositAmount += parseFloat(doc.data().amount)
                setDepositUsdc(totalUsdcDipositAmount);
            })


            const borrowData = await getDocs(nftBorrowUsdcRef);
            await borrowData.docs.map((doc) => {
                totalUsdcBorrowAmount += parseFloat(doc.data().amount)
                setBorrowUsdc(totalUsdcBorrowAmount);

            })

            //let utilisationRateVar = totalBorrowAmount / totalDipositAmount;

        };

        utilisationRateForUSDC();


        const getDepositSUmmaryUsdc = async () => {
            const data = await getDocs(depositSummarUsdcyRef);
            setAllDepositSumUsdc(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getDepositSUmmaryUsdc();

        const getDepositInterestInfoFromFirebase = async () => {
            const data = await getDocs(interestDepositRef);
            setDepositInterestInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getDepositInterestInfoFromFirebase();

    }, []);
    /*
    console.log(borrowInfo);
    console.log(allInfo);
    console.log(allDeposit);
    console.log(depositSum);

     */
    console.log(totalBorrowUsdc);
    console.log(totalDepositUsdc);
    console.log(depositSumUsdc);

    // calculating initial index start

    const depositSummaryInfo = depositSum?.find((item) => item.type === "deposit")
    const depositSummaryInfoUsdc = depositSum?.find((item) => item.type === "usdc")
    const usdc_info = depositSumUsdc?.find((item) => item.type === "usdc")
    console.log(depositSummaryInfoUsdc);
    console.log(usdc_info);

    /* 
    ** Calculating index for deposit - algo
    */
    const alpha = (depositSummaryInfo?.poolAPR / depositSummaryInfo?.timeVal) * 100;
    const time = depositSummaryInfo?.changeOfTime;
    const nowTime = Date.now()
    const nowTimeSecons = Math.round(nowTime / 1000);
    let timeInterval = nowTimeSecons - Number(time?.seconds);
    const index = depositSummaryInfo?.initialIndex * (1 + (timeInterval * alpha))
    console.log(index);
    /* 
    ** Calculating index for deposit - algo - end
    */

    /* 
    ** Calculating index for deposit - USDC - start
    */
    const alpha_usdc = (depositSummaryInfoUsdc?.poolAPR / depositSummaryInfoUsdc?.timeVal) * 100;
    const time_usdc = depositSummaryInfoUsdc?.changeOfTime;
    const nowTime_usdc = Date.now()
    const nowTimeSecons_usdc = Math.round(nowTime_usdc / 1000);
    let timeInterval_usdc = nowTimeSecons_usdc - Number(time_usdc?.seconds);
    //console.log(initialIndex);
    const index_usdc = depositSummaryInfoUsdc?.initialIndex * (1 + (timeInterval_usdc * alpha_usdc))
    console.log(index_usdc);
    /* 
    ** Calculating index for deposit - USDC - end
    */

    // calculating total usdc value
    let totalUSDCValue = 0;
    const total_usdc_value = all_usdc_Deposit?.map((item) => {
        console.log(item.amount);
        if (item.account === algoAdd) {
            console.log("I am herere=============");
            console.log(item.amount);
            return totalUSDCValue += Number(item.amount)
        }
    })
    console.log(totalUSDCValue);
    const depositWithInterest = totalDeposit * (index / depositSummaryInfo?.initialIndex)
    const depositWithInterest_usdc = totalUSDCValue * (index_usdc / depositSummaryInfoUsdc?.initialIndex)
    console.log(depositWithInterest_usdc);
    console.log(index_usdc);
    console.log(depositSummaryInfoUsdc?.initialIndex);
    // calculating initial index ends
    const ownDeposit = allDeposit?.filter((item) => item.account === algoAdd)
    //console.log(ownDeposit);
    let totalDepositOwn = 0;
    const dep = ownDeposit?.map((item) => {
        totalDepositOwn += Number(item.amount)
    })
    //console.log(totalDepositOwn);

    // get user's deposit and withdrawal history from Firestore:
    function getUserBalance() {

        const userDeposit = allDeposit?.filter((item) => item.account === algoAdd);
        const userWithdrawal = allWithdrawal?.filter((item) => item.account === algoAdd);

        // userDepositUSDC
        const userDeposit_usdc = all_usdc_Deposit?.filter((item) => item.account === algoAdd);
        const userWithdrawal_usdc = allWithdrawal?.filter((item) => item.account === algoAdd && item.token == "usdc");

        console.log(userDeposit_usdc);

        let userTotalDeposit = 0;
        const userDep = userDeposit?.map((item) => {
            userTotalDeposit += Number(item.amount)
        })

        let userTotalWithdrawal = 0;
        const userWithdra = userWithdrawal?.map((item) => {
            userTotalWithdrawal += Number(item.amount)
        })

        let userTotalDeposit_usdc = 0;
        const userDep_usdc = userDeposit_usdc?.map((item) => {
            userTotalDeposit_usdc += Number(item.amount)
        })

        let userTotalWithdrawal_usdc = 0;
        const userWithdra_usdc = userWithdrawal_usdc?.map((item) => {
            userTotalWithdrawal_usdc += Number(item.amount)
        })
        console.log(userTotalDeposit);
        console.log(userTotalWithdrawal);
        console.log(userTotalDeposit_usdc);
        console.log(userTotalWithdrawal_usdc);
        if (option == "algo") {
            const remainingBalance = userTotalDeposit - userTotalWithdrawal;
            console.log(remainingBalance);
            return remainingBalance;
        } else {
            const remainingBalance = userTotalDeposit_usdc - userTotalWithdrawal_usdc;
            console.log(remainingBalance);
            return remainingBalance;
        }

    }

    const userBalance = getUserBalance();
    //finding deposit rate
    const borrow_rate_new = borrowInfo?.map((item) => {
        if ((totalBorrow / totalDeposit) < item.Uoptimal) {
            return item.R0 + ((totalBorrow / totalDeposit) / item.Uoptimal) * item.Rslope1
        } else {
            return item.R0 + item.Rslope1 + ((totalBorrow / totalDeposit) - item.Uoptimal) / (1 - item.Uoptimal) * item.Rslope2
        }
    })

    //console.log(borrow_rate_new?.[0]);

    const deposit_rate_new = (totalBorrow / totalDeposit) * borrow_rate_new?.[0] * (1 - 0.3);

    const valueRate = rates.filter((data) => data.utilization === Math.round(totalBorrow / totalDeposit));
    const pool_amount_func = async () => {
        const algodClient = new algosdk.Algodv2("", process.env.REACT_APP_ALGONODE_TESTNET_ADDRESS, '');

        const escrowAddress = await getApplicationAddress(borrowAppId);

        let accountInfo = await algodClient.accountInformation(escrowAddress).do();

        let accountAmount = algosdk.microalgosToAlgos(accountInfo.amount);
        setPoolAmountNew(accountAmount)
        //dispatch(PoolAmountAction(accountAmount));
    }

    const pool_amount_func_usdc = async () => {
        const algodClient = new algosdk.Algodv2("", process.env.REACT_APP_ALGONODE_TESTNET_ADDRESS, '');

        const escrowAddress = await getApplicationAddress(borrowAppIdUSDC);

        let accountInfo = await algodClient.accountInformation(escrowAddress).do();

        let accountAmount = algosdk.microalgosToAlgos(accountInfo.assets[0].amount);
        console.log(accountAmount);
        console.log(accountInfo);
        setPoolAmountUSDC(accountAmount)
        //dispatch(PoolAmountAction(accountAmount));
    }

    const userNFT = allNfts.filter((data) => data.owner_address === algoAdd);

    const handleWithdrawAmount = (e) => {
        // if (e.target.value > userBalance) {
        //     setCheckAmountStatus(true);
        //     setTimeout(() => {
        //         setCheckAmountStatus(false);
        //     }, 3000);
        // } else {
        //     setNewWithdrawAMount(e.target.value)
        // }

        if (e.target.value >= 0 <= userBalance) {
            const inputValue = e.target.value;
            if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= userBalance) {
                setNewWithdrawAMount(inputValue);
                const newSliderValue = (inputValue / algoBalance) * 100;
                setSliderValue(newSliderValue);
            } else if (inputValue < 0) {
                //console.log("erereoreor");
                toast.error("Invalid Amount")
            } else if (inputValue > algoBalance) {
                toast.error("Not enough balance")
            }
        }

    }

    function log(value) {
        setVal(value)
        const newAmount = userBalance * value / 100;
        setNewWithdrawAMount(newAmount.toFixed(2));
        setSliderValue(value);
    }




















    // index calculation - start
    console.log(deposit_interest_info);
    const deposit_info_from_firebase_for_algo = deposit_interest_info?.filter((item) => item.type == "algo")
    const deposit_info_from_firebase_for_usdc = deposit_interest_info?.filter((item) => item.type == "usdc")

    console.log(deposit_info_from_firebase_for_algo);
    console.log(deposit_info_from_firebase_for_usdc);

    const borrowAlgoIndex = () => {
        console.log("hello from index");
        console.log(deposit_info_from_firebase_for_algo);
        const borrow_rate_new = deposit_info_from_firebase_for_algo?.map((item) => {
            if ((totalBorrow / poolAmountNew) < item.Uoptimal) {
                return item.R0 + ((totalBorrow / poolAmountNew) / item.Uoptimal) * item.Rslope1
            } else {
                return item.R0 + item.Rslope1 + ((totalBorrow / poolAmountNew) - item.Uoptimal) / (1 - item.Uoptimal) * item.Rslope2
            }

        })
        console.log(borrow_rate_new[0]);
        const currentTime = Date.now();
        console.log(currentTime);
        console.log(deposit_info_from_firebase_for_algo[0].change_in_time);
        const change_time = (currentTime - deposit_info_from_firebase_for_algo[0].change_in_time);
        console.log(change_time);
        const alpha = borrow_rate_new[0] / (365 * 24 * 60 * 60);
        console.log(alpha);

        const index = deposit_info_from_firebase_for_algo[0].borrow_index_algo * (1 + deposit_info_from_firebase_for_algo[0].sigma * (alpha * change_time));
        console.log("borrow_index_value_in_deposit_function" + index);
        return index;
    }

    // =================== ****************** borrow algo index end ****************** ==========================


    const borrowUSDCIndex = () => {
        const borrow_rate_usdc = deposit_info_from_firebase_for_usdc?.map((item) => {
            if ((totalBorrowUsdc / totalDepositUsdc) < item.Uoptimal) {
                return item.R0 + ((totalBorrowUsdc / totalDepositUsdc) / item.Uoptimal) * item.Rslope1
            } else {
                return item.R0 + item.Rslope1 + ((totalBorrowUsdc / totalDepositUsdc) - item.Uoptimal) / (1 - item.Uoptimal) * item.Rslope2
            }

        })

        const currentTime = Date.now();
        console.log(currentTime);
        console.log(deposit_info_from_firebase_for_usdc[0].change_in_time);
        const change_time = (currentTime - deposit_info_from_firebase_for_usdc[0].change_in_time);
        console.log(change_time);
        console.log(borrow_rate_usdc);


        const alpha = borrow_rate_usdc[0] / (365 * 24 * 60 * 60);
        console.log(alpha);

        const index = deposit_info_from_firebase_for_usdc[0].borrow_index_usdc * (1 + deposit_info_from_firebase_for_usdc[0].sigma * (alpha * change_time));
        console.log("borrow_index_value_in_deposit_function" + index);
        return index;
    }



    // index calculation - end





















    const withdraw = async () => {
        const new_borrow_index_algo = borrowAlgoIndex();
        console.log(new_borrow_index_algo);
        const new_deposit_index_algo = index;
        console.log(new_deposit_index_algo);
        //try {
        const algodClient = new algosdk.Algodv2("", process.env.REACT_APP_ALGONODE_TESTNET_ADDRESS, '');
        const withdraw = new Uint8Array(Buffer.from('withdraw_algo', 'utf8'));
        let withdrawAmt = algosdk.algosToMicroalgos(newWithdrawAMount);
        withdrawAmt = bigIntToBytes(withdrawAmt, 8)
        const totalDepositWithInterest = bigIntToBytes(depositWithInterest, 8)
        const suggested_params = await algodClient.getTransactionParams().do();

        let applicationInfoResponse = await algodClient.accountApplicationInformation("RRO7OHROIWSOA7YXJBACWXOTCG4BWX3XLNSJLTJUSPRI5KHP3JXUTDM63Q", appIdFromRedux).do();
        let depositIndex = 0


        for (let i = 0; i < applicationInfoResponse['created-app']['global-state'].length; i++) {

            if (applicationInfoResponse['created-app']['global-state'][i].key == "REVQT1NJVF9JTlRfSU5ERVg=") {
                depositIndex = applicationInfoResponse['created-app']['global-state'][i].value.uint

            }

        }

        // depositIndex = depositIndex / 100000000

        depositIndex = (borrowInfo[0].initial_borrow_index);
      
     
       

        let depositIndexFront = algosToMicroalgos(depositIndex);
        depositIndexFront = bigIntToBytes(depositIndexFront, 8)

        // let pago_amount = ((newWithdrawAMount) * depositIndex)
        let pago_amount = (algosdk.algosToMicroalgos(newWithdrawAMount) * algosToMicroalgos(depositIndex))/(1000000)
        
        // let pago_amount = ((newWithdrawAMount) / depositIndex);
        let micro_pago_amount = parseInt(pago_amount);

        // let micro_pago_amount = algosToMicroalgos(pago_amount);


        // const logicSigInfo = await escrow_lsg_address();
        // let lsig = new algosdk.LogicSigAccount(logicSigInfo)
        let appAddr = getApplicationAddress(app_ID);

        const params = await algodClient.getTransactionParams().do();
        var note = new Uint8Array([10]);


        let revocationTarget = undefined;
        let closeRemainderTo = undefined;
        let assetID = 212483014;

        //console.log("___________________ Withdraw Amount: ", withdrawAmt);

        const txn2 = algosdk.makeApplicationCallTxnFromObject({
            from: algoAdd,
            appIndex: app_ID,
            onComplete: 0,
            appArgs: [withdraw, withdrawAmt, totalDepositWithInterest, depositIndexFront],
            suggestedParams: suggested_params,

        })

        //console.log("___________________ PAGO Amount: ", micro_pago_amount);

        let txn3 = algosdk.makeAssetTransferTxnWithSuggestedParams(
            algoAdd,
            appAddr,
            closeRemainderTo,
            revocationTarget,
            micro_pago_amount,
            note,
            assetID,
            params);

        const txns = [txn2, txn3];

        const groupID = algosdk.assignGroupID(txns)

        // const myAlgoConnect = new MyAlgoConnect();
        // const signedTxn = await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()));

        //peraWallet connection
        const singleTxnGroups = [{ txn: txn2, signers: [algoAdd] }, { txn: txn3, signers: [algoAdd] }];

        const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);

        const response = await algodClient.sendRawTransaction(signedTxn).do();
        setWithdrawStatus(true);
        await addDoc(nftWithdrawRef, { amount: newWithdrawAMount, account: algoAdd, app_id: app_ID, time: Timestamp.now() });

        //update firebase - algo index
        const userDoc = doc(db, "interest_rate_index", deposit_info_from_firebase_for_algo[0].id);
        await updateDoc(userDoc, { change_in_time: Date.now(), deposit_index_algo: new_deposit_index_algo, borrow_index_algo: new_borrow_index_algo });

        modal2.onOpen();
        setTimeout(() => {
            setWithdrawStatus(false);
        }, 6000);

        setTimeout(() => {
            modal2.onClose();
        }, 12000);
        setTimeout(() => {
            navigate("/dashboard")
        }, 4000);

        /*
        } catch (error) {
            toast.error("ERROR, in Withdraw.")
        }

         */
    }


    const withdraw_function = async () => {

        const newBorrowIndexUsdc = borrowUSDCIndex();
        console.log(newBorrowIndexUsdc);
        const newDepositIndexUsdc = index_usdc;
        console.log(newDepositIndexUsdc);
        console.log(" I am in withdraw function usdc");
        console.log(newWithdrawAMount);
        const algodClient = new algosdk.Algodv2("", process.env.REACT_APP_ALGONODE_TESTNET_ADDRESS, '');
        const withdraw = new Uint8Array(Buffer.from('withdraw_algo', 'utf8'));
        //let withdrawAmt = algosdk.algosToMicroalgos(newWithdrawAMount);
        let withdrawAmt = algosdk.algosToMicroalgos(newWithdrawAMount);
        //alert(withdrawAmt)


        const totalDepositWithInterest = bigIntToBytes(depositWithInterest_usdc, 8)

        const suggested_params = await algodClient.getTransactionParams().do();
        let applicationInfoResponse = await algodClient.accountApplicationInformation("RRO7OHROIWSOA7YXJBACWXOTCG4BWX3XLNSJLTJUSPRI5KHP3JXUTDM63Q", 254192582).do();
        let depositIndex = 0
        for (let i = 0; i < applicationInfoResponse['created-app']['global-state'].length; i++) {
            if (applicationInfoResponse['created-app']['global-state'][i].key == "REVQT1NJVF9JTlRfSU5ERVg=") {
                depositIndex = applicationInfoResponse['created-app']['global-state'][i].value.uint
            }
            console.log(applicationInfoResponse);
        }

        depositIndex = depositIndex / 1000000
        //alert(depositIndex)

        // depositIndex = usdc_info.initial_borrow_index;
        let depositIndexFront = algosToMicroalgos(depositIndex);
        //alert(depositIndexFront)
        // let pago_amount = ((newWithdrawAMount) / depositIndex);
        let pago_amount = ((withdrawAmt) * depositIndexFront) / 1000000;
        //alert(withdrawAmt)
        withdrawAmt = bigIntToBytes(withdrawAmt, 8)
        depositIndexFront = bigIntToBytes(depositIndexFront, 8)

        // pago_amount = pago_amount+0.00001
        // console.log(pago_amount);
        let micro_pago_amount = parseInt(pago_amount);


        // const logicSigInfo = await escrow_lsg_address();
        // let lsig = new algosdk.LogicSigAccount(logicSigInfo)
        let appAddr = getApplicationAddress(254192582);
        const params = await algodClient.getTransactionParams().do();
        var note = new Uint8Array([10]);
        let revocationTarget = undefined;
        let closeRemainderTo = undefined;
        let assetID = 212483014;
        let amount = 0;
        //console.log("___________________ Withdraw Amount: ", withdrawAmt);

        // let txn = algosdk.makeAssetTransferTxnWithSuggestedParams(
        //     algoAdd,
        //     algoAdd,
        //     undefined,
        //     undefined,
        //     0,
        //     note,
        //     //228816363,
        //     212483014,
        //     suggested_params);

        const txn2 = algosdk.makeApplicationCallTxnFromObject({
            from: algoAdd,
            //appIndex: app_ID,
            appIndex: 254192582,
            onComplete: 0,
            appArgs: [withdraw, withdrawAmt, totalDepositWithInterest, depositIndexFront],
            foreignAssets: [10458941],
            suggestedParams: suggested_params,
        })
        //console.log("___________________ PAGO Amount: ", micro_pago_amount);
        console.log(appAddr);
        let txn3 = algosdk.makeAssetTransferTxnWithSuggestedParams(
            algoAdd,
            appAddr,
            closeRemainderTo,
            revocationTarget,
            micro_pago_amount,
            // 1200000,
            note,
            //10458941,
            228816363,
            params);
        const txns = [txn2, txn3];
        const groupID = algosdk.assignGroupID(txns)
        // const myAlgoConnect = new MyAlgoConnect();
        // const signedTxn = await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()));
        //peraWallet connection
        const singleTxnGroups = [{ txn: txn2, signers: [algoAdd] }, { txn: txn3, signers: [algoAdd] }];
        const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
        const response = await algodClient.sendRawTransaction(signedTxn).do();
        setWithdrawStatus(true);
        console.log(response)
        await addDoc(nftWithdrawUsdcRef, { amount: newWithdrawAMount, account: algoAdd, app_id: app_ID, time: Timestamp.now(), token: "usdc" });
        toast.success("Withdraw successful");
        setTimeout(() => {
            navigate("/dashboard")
        }, 2000);

    }

    const escrow_lsg_address = async () => {

        let escrow_fund_program_compiled = undefined
        let escrow_address = undefined

        await axios(`${API_URL}swap/depositLogicSig`)
            .then(response => {

                if (response.status === 200) {

                    let data = response.data;

                    escrow_fund_program_compiled = data.escrow_fund_program_compiled
                    escrow_address = data.escrow_address

                }

            })
            .catch(error => {
                console.error("Error fetching data", error);

            })

        const algodClient = new algosdk.Algodv2("", process.env.REACT_APP_ALGONODE_TESTNET_ADDRESS, '');

        const escrowlcompiledProgram = await algodClient.compile(escrow_fund_program_compiled).do();

        const escrowProgramBytes = new Uint8Array(
            Buffer.from(escrowlcompiledProgram.result, 'base64')
        );


        return escrowProgramBytes;

    }

    console.log(poolAmountUSDC);
    console.log(userBalance);
    console.log(all_usdc_Deposit);
    return (
        <>
            <Box zIndex="100" top="0" position="fixed" width="100%">{walletConnectStatus ? <DesktopNav name={walletAddress} amount={walletAmount} /> : <DesktopNav name={algoAdd} amount={algoBalance} />}</Box>

            <Box minH="100vh" py="50px">

                <Box w="100%" mt="70px">
                    <Container maxW={'3xl'} borderRadius="20px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" pb="20px">
                        <HStack alignItems="center" justifyContent="space-between" justifyItems="center" px="20px" py="30px">
                            <VStack align="left">
                                <Text fontSize="30px" fontWeight="600" mb="-5px">Withdraw</Text>
                                <HStack>
                                    <Text fontSize="18px" color={textColor}>Total Withdrawable amount :
                                    </Text>
                                    <Image src={pago} h="20px" w="20px" />
                                    {/* <Text fontSize="18px" color={textColor} fontWeight="700">{depositWithInterest ? depositWithInterest.toFixed(2) : totalDepositOwn}
                                    </Text> */}

                                    {/* <Text fontSize="18px" color={textColor} fontWeight="700"> {option == "algo" ? algoBalance.toFixed(2) : usdcAmount && isValidMicroAlgos(usdcAmount.amount) ? algosdk.microalgosToAlgos(usdcAmount.amount).toFixed(2) : 'You dont have any USDC!'}
                                    </Text> */}
                                    <Text fontSize="18px" color={textColor} fontWeight="700">{userBalance ? userBalance.toFixed(2) : "0"}
                                    </Text>
                                </HStack>

                            </VStack>

                            <Image src={pagoCoin} h="50px" w="50px" />
                        </HStack>
                        <HStack w="50%" ml="10px">
                            <Box>
                                <Box w="100%" textAlign="left" mb="5px">
                                    <Text fontWeight="500">Select Token</Text>
                                </Box>
                                <Box w="100%">
                                    <Select onChange={(e) => setOption(e.target.value)}>
                                        <option value='algo'>Algo</option>
                                        <option value='usdc'>USDC</option>
                                    </Select>
                                </Box>
                            </Box>
                        </HStack>

                        {/* <VStack spacing={7}>
                            <Progress hasStripe isAnimated value={25} w="100%" />
                            <Divider color="gray.500" p="1px" />
                        </VStack> */}
                        <HStack py="20px" width="100%" bgColor={bgColor} px="20px" my="20px" borderRadius="15px" justifyItems="center" justifyContent="space-between">
                            <VStack>
                                <Text fontSize="12px" color={textColor}>Utilization rate</Text>
                                {/* <Text fontSize="18px" color={textColor} fontWeight="700">{((totalBorrow / totalDeposit) * 100).toFixed(2)}
                                    %</Text> */}
                                <Text fontSize="18px" color={textColor} fontWeight="700">{option == "algo" ? ((totalBorrow / poolAmountNew) * 100).toFixed(2) : totalBorrowUsdc && totalDepositUsdc ? ((totalBorrowUsdc / totalDepositUsdc) * 100).toFixed(2) : "0.00"}
                                    %</Text>

                            </VStack>

                            <VStack>
                                <Text fontSize="12px" color={textColor}>Pool balance</Text>
                                <Text fontSize="18px" color={textColor} fontWeight="700" >{option == "algo" ? poolAmountNew ? Number(poolAmountNew).toFixed(2) : "0.00" : poolAmountUSDC ? Number(poolAmountUSDC).toFixed(2) : "0.00"}</Text>
                            </VStack>

                        </HStack>

                        <Box
                            maxW={'100%'}
                            w={'full'}
                            bg={useColorModeValue('white', 'gray.800')}
                            boxShadow={'2xl'}
                            rounded={'md'}
                            overflow={'hidden'}
                            h="230px"

                        >
                            {/* <VStack align="left" py="20px" px="20px">
                                <Text fontSize="16px" fontWeight="500">Withdraw Amount</Text>
                                <Text fontSize="45px" color="yellow.400" >{withdrawAMount ? withdrawAMount.toFixed(4) : 0}</Text>
                            </VStack> */}
                            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} borderRadius="15px">
                                <VStack w="100%" align="left">

                                    <Text fontSize={{ base: "14px", md: "14px" }} fontWeight="600" textAlign="left">Withdraw Amount</Text>
                                    <Input
                                        type="number"
                                        name='Amount'
                                        value={newWithdrawAMount}
                                        required={true}
                                        border="none"
                                        color="gray.500"
                                        bgColor={'gray.200'}
                                        placeholder='Please enter withdraw amount'
                                        py="30px"
                                        min="0"
                                        onChange={(e) => handleWithdrawAmount(e)}
                                    />
                                </VStack>

                            </HStack>

                            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="30px" width="100%" bgColor={bgColor} m="10px 0px 40px 0px" borderRadius="15px" pb="20px">
                                <Slider min={0} marks={marks} step={1} max={100} onChange={log} value={sliderValue} />
                            </HStack>
                        </Box>

                        <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                            <Text fontSize="16px" fontWeight="400" textAlign="center">Withdraw tokens immediately. </Text>

                        </HStack>

                        <Button width="100%" bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} onClick={option == "algo" ? withdraw : withdraw_function}>Withdraw</Button>

                        {checkAmountStatus ?
                            <Alert status='warning'>
                                <AlertIcon />
                                You can't insert more than {userBalance?.toFixed(2)} !!!
                            </Alert>
                            : ""
                        }

                    </Container>
                </Box>
            </Box>

            {/* ==================successful Withdrwa modal start=================== */}
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose} isCentered>
                <ModalOverlay bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent width="400px" height="350px">
                    <ModalCloseButton />
                    <ModalCloseButton />
                    <AnimatePresence>
                        {modal2.isOpen && (
                            <motion.ModalBody
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={animationVariants}
                                borderRadius="15px"
                                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                            >
                                <Center>
                                    <VStack alignItems="center" justifyContent="space-between" py="20%">
                                        <Text color={textColor} textAlign="center" fontSize="30px"
                                            fontWeight="bold">Congratulations!!</Text>
                                        <Text color={textColor} textAlign="center" fontSize="25px" fontWeight="bold">You
                                            successfully made the WITHDRAW.</Text>
                                        <Text textAlign="center" fontSize="25px" fontWeight="bold" color={textColor}>Have fun.</Text>
                                    </VStack>
                                </Center>
                            </motion.ModalBody>
                        )}
                    </AnimatePresence>
                </ModalContent>
            </Modal>
            {/* ==================successful Withdraw modal ends=================== */}

            <Modal isOpen={modal3.isOpen} onClose={modal3.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Sorry!!</Text>
                                <Text color={textColor}>Please enter amount</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default NewWithDraw
