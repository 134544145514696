import React, { useEffect } from 'react'
import { Box, HStack, Text, Image, VStack, Input, useColorModeValue, Button, Checkbox } from '@chakra-ui/react';
import dummyImage from '../images/Draft.png';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RepayAmountAction, RepayRemainingAmountAction, debtWithInterestAction } from '../redux/actions';
import { IoIosRadioButtonOff, IoIosEgg } from "react-icons/io";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where
} from "firebase/firestore";
import { db } from '../firebase/FirebaseConfig';
import { rates } from '../UtilizationRate/UtilizationRateData';
import axios from 'axios';
import { API_URL } from '../../url';
import { MdCheckCircleOutline, MdCheckCircle } from 'react-icons/md';
import { toast } from "react-toastify"

function SecondSlideForRepay(props) {

    const textColor = useColorModeValue("gray.600", "whiteAlpha.500");
    const [borrowAmount, setBorrowAmount] = useState();
    const [repayAmount, setRepayAmount] = useState();
    const [repayAmountNew, setRepayAmountNew] = useState(0);
    const [fullRepayStatus, setFullRepayStatus] = useState(false);
    const [repayAmountCondition, setRepayAmountCondition] = useState(false);
    const dispatch = useDispatch();
    const repayItemFromRedux = useSelector(state => state.token.repay_item);
    const add = useSelector(state => state.token.myAlgoAddress);
    const borrow_AppID = useSelector(state => state.token.borrow_app_id);
    const nftId = repayItemFromRedux.assetIdForRepay;
    const totalBorrowAmount = useSelector(state => state.token.total_borrow_amount);
    const totalDepositAmount = useSelector(state => state.token.total_deposit_amount);
    const [check_box, setCheckBox] = useState(false)
    const item = useSelector(state => state.token.repay_item);
    const borrowPrice = item.amount;
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const aprRateRef = collection(db, "borrowAPR");
    // const [totalBorrow, setBorrow] = useState();
    // const [deposit, setDeposit] = useState();
    const nftBorrowRef = collection(db, "borrow-usdc");
    const allNftRef = collection(db, "nfts");

    const nftDepositRef = collection(db, "deposit");
    const [repayNFT, setRepayNFT] = useState();
    const [allNft, setAllNfts] = useState();
    const [val, setVal] = useState();
    const borrow_apr = useSelector(state => state.token.borrow_apr);
    //const borrowInfoRef = collection(db, "borrow-info");
    const borrowInfoRef = collection(db, "borrow_info_usdc");
    const borrowInfoNewRef = collection(db, "interest_rate_index");
    //const borrowInfoRefNew = collection(db, "borrow_info_usdc");
    const [totalBorrow, setBorrow] = useState();
    const [deposit, setDeposit] = useState();
    const [borrowInfo, setBorrowInfo] = useState();
    const [allAssets, setAllAssets] = useState();
    const [supply, setSupply] = useState();
    const summaryTableRef = collection(db, "summaryTable");
    const supplyTableRef = collection(db, "supply");
    const [exchange_Rate, setExchangeRate] = useState();
    const exchane_rate = 4.71;
    const [sliderValue, setSliderValue] = useState(0);
    const [trackNowTime, setTime] = useState(0);

    //console.log("================repayItemFromRedux", repayItemFromRedux);


    const marks = {

        0: <strong>0%</strong>,
        25: '25%',
        50: '50%',
        75: '75%',
        100: {
            style: {
                color: 'green',
            },
            label: <strong>100%</strong>,
        },
    };
    let total_Diposit_Amount = 0;
    let total_Borrow_Amount = 0;
    //console.log(repayAmount);

    const exchangeRate = () => {

        // await axios('http://192.168.0.135:12000/swap/checkOptin?address=' + address)
        axios(`${API_URL}swap/assetPrice`)
            .then(response => {
                if (response.status === 200) {
                    //console.log(response.data);
                    setExchangeRate(response.data)
                }

            })
            .catch(error => {
                console.error("Error fetching data", error);

            })
    }
    console.log(repayItemFromRedux);
    useEffect(() => {

        const allNFT = async () => {
            const data = await getDocs(nftBorrowRef);
            setAllNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        allNFT();

        const borrowNFT = async () => {
            const data = await getDocs(nftBorrowRef);
            setRepayNFT(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        borrowNFT();

        const borrowAmount = async () => {
            const data = await getDocs(nftBorrowRef);
            data.docs.map((doc) => {
                total_Borrow_Amount += parseFloat(doc.data().amount)
                setBorrow(total_Borrow_Amount);
            })
        };

        borrowAmount();

        const depositAmount = async () => {
            const data = await getDocs(nftDepositRef);
            data.docs.map((doc) => {
                total_Diposit_Amount += parseFloat(doc.data().amount)
                setDeposit(total_Diposit_Amount);
            })
        };

        depositAmount();

        const getBorrowInfoFromFirebase = async () => {
            //const data = await getDocs(borrowInfoRef);
            const data = await getDocs(borrowInfoNewRef);
            setBorrowInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getBorrowInfoFromFirebase();

        const getAllInfoFromFirebaseSummaryTable = async () => {
            const data = await getDocs(summaryTableRef);
            setAllAssets(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getAllInfoFromFirebaseSummaryTable();

        const getAllSupplyInfo = async () => {
            const data = await getDocs(supplyTableRef);
            setSupply(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getAllSupplyInfo();

        exchangeRate();

        const nowTime = () => {
            setTime(Date.now())
        }
        nowTime();

    }, []);
    //const exchange_amount = (getSupplyValueItem?.supplyAmount * exchange_Rate?.usdc_price) / exchange_Rate?.algo;
    const algoAssetInfo = allAssets?.find((data) => data.asset === "Algo");

    const FlemishAssetInfo = allAssets?.find((data) => data.asset === "Flemish");
    const UsdcAssetInfo = allAssets?.find((data) => data.asset === "USDC");
    const MingoAssetInfo = allAssets?.find((data) => data.asset === "Mingo");
    const AiGonnaAssetInfo = allAssets?.find((data) => data.asset === "AI_Gonna");

    // health factor starts from here
    //const selectItem = allNft?.find((item) => item.assetIdForRepay == repayItemFromRedux.assetIdForRepay);
    const selectItem = repayItemFromRedux;
    console.log(selectItem);
    //const selectItem = allNft?.find((item) => item.assetIdForRepay == "204346006");
    //console.log("===================allNft:", allNft);
    //console.log("====================repayItemFromRedux:", repayItemFromRedux);
    //console.log("===================selectItem", selectItem);
    const getSupplyValueItem = supply?.find((item) => item.borrow_item_asset_id == repayItemFromRedux.assetIdForRepay);
    const getItemForInterest = borrowInfo?.find((item) => item.type === "usdc")
    /*
    console.log("====================getItemForInterest:", getItemForInterest);
    console.log("====================getSupplyValueItem:", getSupplyValueItem);
    console.log("====================ALGO Exchange Rate:", exchange_Rate?.algo);
    console.log("====================USDC Exchange Rate:", exchange_Rate?.usdc_price);

     */
    //const exchange_amount = (getSupplyValueItem?.supplyAmount * exchange_Rate?.usdc_price) / exchange_Rate?.algo;

    //console.log("===================exchangeAmount:", exchange_amount)

    const calculateHealthFactor = () => {
        let nftPart = 0;
        let usdcPart = 0;

        if (selectItem && AiGonnaAssetInfo) {
            nftPart = Number(selectItem.nft_price) * AiGonnaAssetInfo.LT;
            console.log(nftPart);
        }

        if (getSupplyValueItem?.supplyAmount && UsdcAssetInfo && exchange_Rate) {
            const exchangeAmount = (getSupplyValueItem.supplyAmount * exchange_Rate.usdc_price) / exchange_Rate.algo;
            usdcPart = exchangeAmount * UsdcAssetInfo.LT;
            console.log(usdcPart);
        }

        if (!repayItemFromRedux || !getItemForInterest) {
            return 0;
        }

        const denominator = repayItemFromRedux.amount + getItemForInterest.borrow_index_usdc;
        console.log(denominator);
        if (denominator === 0) {
            return 0;
        }

        //console.log("=======================", nftPart, "usdc part: ", usdcPart, "denominator", denominator);
        const healthFactor = (nftPart + usdcPart) / denominator;
        return isNaN(healthFactor) ? 0 : healthFactor;
    }

    const healthFactor = calculateHealthFactor();

    /*
    console.log("NFT Price===========", Number(selectItem?.nft_price));
    console.log("NFT LT===========", AiGonnaAssetInfo?.LT);
    console.log("Token===========", (exchange_amount));
    console.log("Health Factor=============================", healthFactor);

     */

    // health factor ends here

    const findAlgoValue = borrowInfo?.filter((item) => item.type == "usdc")
    console.log(findAlgoValue);
    const borrow_rate_new = findAlgoValue?.map((item) => {
        if ((totalBorrow / deposit) < item.Uoptimal) {

            return item.R0 + ((totalBorrow / deposit) / item.Uoptimal) * item.Rslope1
        } else {
            console.log(totalBorrow)
            return item.R0 + item.Rslope1 + ((totalBorrow / deposit) - item.Uoptimal) / (1 - item.Uoptimal) * item.Rslope2
        }

    })
    var found = borrowInfo?.find(function (element) {
        return element.initial_id == "2";

    });

    // calculating index
    //const currentTime = Date.now();
    console.log(found);
    console.log(borrow_rate_new);
    const alpha = borrow_rate_new?.[0] / (365 * 24 * 60 * 60);
    console.log((alpha * (((trackNowTime / 1000) - (found?.change_in_time / 1000)))));
    const index = found?.borrow_index_usdc * (1 + found?.sigma * (alpha * (((trackNowTime / 1000) - (found?.change_in_time / 1000)))));



    // const currentTime = Date.now();
    // console.log(currentTime);
    // const change_time = ((currentTime - found?.change_in_time_new) * 0.001)
    // const alpha = borrow_rate_new?.[0] / (365 * 24 * 60 * 60);

    // const index = found?.initial_borrow_index * (1 + found?.sigma * (alpha * change_time));
    //alert(index);

    // const rep = repayNFT ? repayNFT.find(e => e.assetID === repayItemFromRedux.assetID) : null;
    const rep = repayItemFromRedux;
    console.log(rep);
    console.log(index);
    console.log(found?.borrow_index_usdc);
    const debtWithInterest = rep?.amount * index / found?.borrow_index_usdc;
    console.log(debtWithInterest);
    //alert(debtWithInterest)
    //======================
    // const repayNft = repayNFT ? repayNFT.filter((data) => data.assetID === repayItemFromRedux.assetID) : null;
    const repayNft = repayItemFromRedux.assetID;

    let repayCondition = repayItemFromRedux.repay;
    let repayRemainingAmount = repayItemFromRedux?.repay_remaining_amount;


    const interest_rate = (Number(repayItemFromRedux.amount) + Number((repayItemFromRedux.amount * (borrow_apr / 100)))).toFixed(2);
    console.log(debtWithInterest);
    //let amount = Number(interest_rate);
    let amount = debtWithInterest;
    dispatch(debtWithInterestAction({
        debtWithInterest: amount,
    }));
    console.log(repayRemainingAmount);
    console.log(repayCondition);
    console.log(amount);
    // updating redux
    let repayRemainingAmountwithDebt = repayItemFromRedux.debtWithInterest;

    const handleRepayFullAmount = (e) => {
        setCheckBox(e.target.checked)

        // {repayCondition ? (repayRemainingAmount).toFixed(2) : (amount).toFixed(2)}
        setFullRepayStatus(true);
        setRepayAmountNew(amount);
        //setSliderValue(repayCondition ? repayRemainingAmount : amount);
        dispatch(RepayAmountAction(repayCondition ? repayRemainingAmount : amount));
        //dispatch(RepayRemainingAmountAction((rep ? amount - repayAmountNew : amount - repayAmountNew)))
        dispatch(RepayRemainingAmountAction((rep ? amount - amount : amount - amount)))
    }

    const handleRepayAmount = (e) => {
        // setRepayAmountNew(e.target.value);

        // if (repayCondition ? repayRemainingAmount : (amount) >= e.target.value && e.target.value >= 0) {
        //     setRepayAmountCondition(false);
        //     // updating redux
        //     dispatch(RepayAmountAction(repayCondition ? repayRemainingAmount : amount));
        //     dispatch(RepayRemainingAmountAction((rep ? amount - e.target.value : amount - e.target.value)))

        // } else {
        //     setRepayAmountCondition(true)
        // }

        //=========================================
        console.log(e.target.value);

        if (repayCondition ? repayRemainingAmount : (amount) >= e.target.value && e.target.value >= 0) {
            const inputValue = e.target.value;
            if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= amount) {
                setRepayAmountNew(e.target.value);
                const newSliderValue = (inputValue / amount) * 100;
                setSliderValue(newSliderValue);

                setRepayAmountCondition(false);
                // updating redux
                dispatch(RepayAmountAction(repayCondition ? repayRemainingAmount : amount));
                dispatch(RepayRemainingAmountAction((rep ? amount - e.target.value : amount - e.target.value)))
            } else if (inputValue < 0) {
                //console.log("erereoreor");
                toast.error("Invalid Amount")
                setRepayAmountCondition(true)
            } else if (inputValue > amount) {
                toast.error("Not enough balance")
                setRepayAmountCondition(true)
            }
        }

    }

    function log(value) {

        const newAmount = repayCondition ? (repayRemainingAmount * (value / 100)).toFixed(2) : (amount * (value / 100)).toFixed(2);
        setRepayAmountNew(newAmount);
        setSliderValue(value);
        dispatch(RepayAmountAction(repayCondition ? repayRemainingAmount * (value / 100) : amount * (value / 100)));

        // dispatch(RepayAmountAction(rep.repay_remaining_amount > 0 ? amount * value / 100 : amount * value / 100));
        // dispatch(RepayRemainingAmountAction((item.amount - (item.amount * value / 100).toFixed(2))))
        //console.log(rep ? amount - (amount * value / 100).toFixed(2) : (amount - (amount * value / 100).toFixed(2)));
        dispatch(RepayRemainingAmountAction((rep ? amount - (amount * value / 100) : (amount - (amount * value / 100)))))


    }

    return (
        <>
            <HStack py="40px">
                <HStack w={{ base: "20%", md: "35%" }}>
                    <Image src={rep ? rep.image : repayItemFromRedux.image} w="250px" h="250px" objectFit="contain" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" borderRadius="12px" />
                </HStack>
                <VStack w={{ base: "80%", md: "65%" }} h="250px" px="20px" spacing={10}>
                    <HStack justifyItems="center" justifyContent="space-between" width="100%">
                        <VStack align="left" w="50%">
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>Collection</Text>
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor} fontWeight="700">{rep ? rep.name : repayItemFromRedux.name}</Text>
                        </VStack>
                        <VStack align="left" w="50%">
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>Token ID</Text>
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor} fontWeight="700">{repayItemFromRedux?.assetID[0]}</Text>
                        </VStack>
                    </HStack>

                    <HStack justifyItems="center" justifyContent="space-between" width="100%">
                        <VStack align="left" w="50%">
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>Your debt</Text>
                            {/* <Text fontSize="18px" color={textColor} fontWeight="700">{rep ? amount ? amount : rep.amount : 0}</Text> */}
                            {/* <Text fontSize="18px" color={textColor} fontWeight="700">{rep && rep.repay_remaining_amount.toFixed(2) > 0 ? rep.repay_remaining_amount.toFixed(2) : amount}</Text> */}
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor} fontWeight="700">{repayCondition ? (repayRemainingAmount).toFixed(2) : (amount).toFixed(2)}</Text>
                            {/* <Text fontSize="18px" color={textColor} fontWeight="700">{rep ? rep.repay_remaining_amount.toFixed(2) : repayItemFromRedux.amount}</Text> */}
                        </VStack>

                    </HStack>

                    <HStack justifyItems="center" justifyContent="space-between" width="100%" style={{ paddingBottom: "40px" }}>
                        <VStack align="left" w="50%" >
                            <Text fontSize="14px" color={textColor}>Remaining debt</Text>
                            {repayAmountNew == undefined ?
                                <Text fontSize="18px" color={textColor} fontWeight="700">0.00</Text>
                                :
                                <Text fontSize="18px" color={textColor} fontWeight="700">{repayCondition ? (repayRemainingAmount - repayAmountNew).toFixed(2) : (amount - repayAmountNew).toFixed(2)}</Text>
                            }
                        </VStack>

                        <VStack align="left" w="50%">
                            <Text fontSize="14px" color={textColor}>Health factor</Text>

                            <HStack>
                                <IoIosEgg color='green' />
                                <Text fontSize="18px" color={textColor} fontWeight="700">{healthFactor.toFixed(2)}</Text>
                            </HStack>
                        </VStack>
                    </HStack>
                </VStack>
            </HStack>

            {/* <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} borderRadius="15px" mt="30px">
                <VStack w="100%" align="left">
                    <Text fontSize="16px" fontWeight="700" textAlign="left">Amount</Text>
                    <Input
                        type="number"
                        name='Amount'
                        value={repayAmount && !isNaN(repayAmount) ? repayAmount.toFixed(2) : ""}
                        required={true}
                        border="none"
                        color="gray.500"
                        bgColor={'gray.200'}
                        placeholder='please enter repay amount'
                        py="30px"
                        min="0"
                        onChange={(e) => handleRepayAmount(e)}
                    />
                </VStack>

            </HStack> */}

            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} borderRadius="15px" mt="30px">
                <VStack w="100%" align="left">
                    <Box display="flex" justifyContent="space-between">
                        <Text fontSize="16px" fontWeight="700" textAlign="left">Amount</Text>
                        {/* <Button size="md" variant="ghost" bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} onClick={handleRepayFullAmount}>Repay Full</Button> */}
                        <Checkbox size='md' colorScheme='red' isChecked={check_box}
                            onChange={(e) => handleRepayFullAmount(e)} fontWeight="bold">
                            Full Repay
                        </Checkbox>
                    </Box>
                    {check_box ?
                        <Input
                            type="number"
                            name='Amount'
                            value={repayAmountNew}
                            required={true}
                            border="none"
                            color="gray.500"
                            bgColor={'gray.200'}
                            placeholder='Please enter repay amount'
                            py="30px"
                            min="0"
                        //onChange={ (e) => handleRepayAmount(e)}
                        />
                        :
                        <Input
                            type="number"
                            name='Amount'
                            value={repayAmountNew ? repayAmountNew : 0}
                            required={true}
                            border="none"
                            color="gray.500"
                            bgColor={'gray.200'}
                            placeholder='please enter repay amount'
                            py="30px"
                            min="0"
                            onChange={(e) => handleRepayAmount(e)}
                        />
                    }
                </VStack>

            </HStack>
            {repayAmountCondition ?
                <HStack justifyContent="space-between" justifyItems="center" px="20px" py="30px" width="100%" bgColor={bgColor} m="10px 0px 40px 0px" borderRadius="15px">
                    <Text style={{ fontWeight: "bold", color: "red" }}>You can't insert more than {repayCondition ? repayRemainingAmount : (amount).toFixed(2)}</Text>

                </HStack>
                :
                ""
            }
            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="30px" width="100%" bgColor={bgColor} m="10px 0px 40px 0px" borderRadius="15px">
                <Slider min={0} marks={marks} step={1} max={100} onChange={log} value={sliderValue} disabled={check_box} />

            </HStack>

        </>
    )
}

export default SecondSlideForRepay


