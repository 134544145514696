import { Box, Button, Container, HStack, Image, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import pagoHead from '../components/images/pago-head.png';
import { db } from '../components/firebase/FirebaseConfig';
import { useState } from 'react';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where,
    update
} from "firebase/firestore";
import { Link } from 'react-router-dom';
import Header from '../components/header/Header';
import { ReactComponent as UsdcCoinImg } from '../components/images/Icons/usd-coin-usdc-logo.svg';
import pagoImage from "../components/images/Pago-Coin.png"

function MyNFT() {
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const nftBorrowtRef = collection(db, "borrow");
    const usdcBorrowtRef = collection(db, "borrow-usdc");
    const [borrowNFT, setBorrowNFT] = useState([]);
    const [usdcBorrowNFT, setusdcBorrowNFT] = useState([{}]);
    const [nfts, setNfts] = useState([]);

    useEffect(() => {
        const getNfts = async () => {

            const q = query(collection(db, "borrow"));
            const data = await getDocs(q);

            const q2 = query(nftBorrowtRef, where("account", "==", algoAdd));
            //query(citiesRef, where("state", "==", "CA"));
            const data2 = await getDocs(q2);

            data2.forEach(async (doc) => {
                // doc.data() is never undefined for query doc snapshots
                let docData = doc.data();


                //   console.log(doc.id, " => ", doc.data());
                await setBorrowNFT({ ...borrowNFT, borrowNFT: docData, nftId: doc.id })

            });

            setNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

        };

        getNfts();


        const whiteListedBorrowNfts = async () => {
            let allData = [];
            const q2 = query(usdcBorrowtRef, where("account", "==", algoAdd));
            const data2 = await getDocs(q2);

            data2.forEach(async (doc) => {
                let docData = doc.data();
                console.log(docData);
                allData.push(docData);
                //await setusdcBorrowNFT({ ...usdcBorrowNFT, borrowNFT: docData, nftId: doc.id })

            });

            //setNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
            setusdcBorrowNFT(allData)

        };

        whiteListedBorrowNfts();


        // const whiteListedBorrowNfts = async () => {
        //     const data = await getDocs(usdcBorrowtRef);
        //     setusdcBorrowNFT(data.docs.map((doc) => ({ ...doc.data() })));
        // };

        // whiteListedBorrowNfts();


    }, []);
    console.log(usdcBorrowNFT);
    const checkItemForUsdcReaminingAmount = usdcBorrowNFT.filter((item) => item.repay_remaining_amount > 0);
    console.log(checkItemForUsdcReaminingAmount);
    let borrow = undefined;
    let BorrowData = [];
    if (nfts.length > 0) {
        borrow = nfts.filter((data) => data.account === algoAdd && Number(data.repay_remaining_amount) > 0);
        //console.log(nftAuction);

        borrow.forEach(function (add) {
            BorrowData.push(add);
        })
    } else {
        BorrowData = undefined;
    }

    const algoAndUsdcBorrowItems = BorrowData?.length > 0 ? checkItemForUsdcReaminingAmount?.length > 0 ? BorrowData.concat(checkItemForUsdcReaminingAmount) : BorrowData : checkItemForUsdcReaminingAmount;
    console.log(algoAndUsdcBorrowItems);

    return (
        <>
            <Header />
            <Box minH="100vh" py="50px">
                <Container maxW={'7xl'} mt="80px">
                    <HStack justifyContent="space-between" justifyItems="center" px="20px" py="40px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                        <HStack>
                            <Image src={pagoHead} h="60px" w="60px" objectFit="cover" />
                            <VStack align="left">
                                <Text fontSize="25px" fontWeight="bold" mb="-5px">My Borrows</Text>
                                <Text>Total Number of Loans: {BorrowData != undefined ? BorrowData.length : 0}</Text>
                            </VStack>
                        </HStack>

                    </HStack>
                    <TableContainer>
                        <Table variant='simple'>

                            <Thead >
                                <Tr bgColor={bgColor} >
                                    <Th>Asset</Th>
                                    <Th>Asset Name</Th>
                                    <Th>Asset ID</Th>
                                    <Th>Repay Amount</Th>
                                    <Th>Remaining Repay Amount</Th>
                                    <Th>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {algoAndUsdcBorrowItems != undefined ?
                                    algoAndUsdcBorrowItems.map((data, index) => (
                                        <Tr key={data.assetID}>
                                            <Td><Image src={data.image} w="50px" h="50px" objectFit="cover" /></Td>
                                            <Td>
                                                <VStack spacing={2} align="start">
                                                    <Text>{data.name ? data.name : "--"}</Text>
                                                    {data?.assetID?.includes(10458941) ? (
                                                        <HStack>
                                                            <UsdcCoinImg width="20px" height="20px" alt="USDC Logo" />
                                                            <img src={pagoImage} width="20px" height="20px" alt="USDC Logo" />
                                                        </HStack>
                                                    )
                                                        : data.borrowType == "usdc" ? <UsdcCoinImg width="20px" height="20px" alt="USDC Logo" /> :
                                                            (<img src={pagoImage} width="20px" height="20px" alt="USDC Logo" />)
                                                    }
                                                </VStack>
                                            </Td>
                                            {/*<Td>{data.name ? data.name : '--'}</Td>*/}
                                            <Td>
                                                {Array.isArray(data.assetID) ?
                                                    data.assetID.map((id, index) => (
                                                        <div key={index}>{id}</div>
                                                    ))
                                                    :
                                                    data.assetID
                                                }
                                            </Td>
                                            <Td>{data.repayAmount}</Td>
                                            <Td>{(Number(data.repay_remaining_amount)).toFixed(2)}</Td>
                                            {/* <Td>
                                                {data.repay_remaining_amount > 0 ?
                                                    <HStack>
                                                        <Link to="/deposit">
                                                            <Button disabled={true}>Deposit</Button>
                                                        </Link>
                                                        <Link to="/repay">
                                                            <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}>Repay</Button>
                                                        </Link>
                                                    </HStack> :
                                                    <Link to="/deposit">
                                                        <Button >Deposit</Button>
                                                    </Link>
                                                }
                                            </Td> */}
                                            <Td>

                                                <HStack>

                                                    <Link to="/repay">
                                                        <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}>Repay</Button>
                                                    </Link>
                                                </HStack>
                                            </Td>

                                        </Tr>
                                    )) :
                                    <Tr>
                                        <Td>--</Td>
                                        <Td>--</Td>
                                        <Td>--</Td>
                                        <Td>--</Td>
                                    </Tr>
                                }

                            </Tbody>

                        </Table>
                    </TableContainer>
                </Container>
            </Box>
        </>
    )
}

export default MyNFT
