import { Box, Checkbox, HStack, Text, Image, useColorModeValue, Button, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'

import { db } from '../firebase/FirebaseConfig';
import pago from '../images/Pago-Coin.png';
import { useDispatch, useSelector } from 'react-redux';
import { NFTcheckBoxAction, NFTborrowItemAction, getAllAssetsAction } from '../redux/actions';
import SecondSlide from './SecondSlide';
import algosdk, { bigIntToBytes, getApplicationAddress, decodeAddress, waitForConfirmation } from "algosdk";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../url';

function FirstSlide({ setSelectedNFTs, setIsFirstStepNextButtonDisabled, setShowAlert }) {
    const [allNfts, setAllNfts] = useState([]);
    const [whiteListedNft, setwhiteListedNfts] = useState([]);
    const nftCollectionRef = collection(db, "nfts");
    //const whiteListedNftRef = collection(db, "whiteListedNFT");
    const whiteListedNftRef = collection(db, "whiteListMintedItem");
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const allAssetsFromWallet = useSelector(state => state.token.assets_from_account_2);
    const [checkbox, setCheckBox] = useState(true);
    const [checkBoxItem, setCheckBoxItem] = useState([]);
    const [borrowItem, setBorrowItem] = useState();
    const dispatch = useDispatch();
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const testItems = useSelector(state => state.token.borrow_item);

    {/*
        ** get users NFT
    */}
    const userNFT = allNfts.filter((data) => data.owner_address === algoAdd && data.borrow == false);


    {/*
        ** finding white listed nft for an individual user
    */}
    const commonValue = whiteListedNft.map(function (x) {

        var result = userNFT.filter(a1 => a1.assetID == x.id);

    })

    const [item, setItem] = useState([])
    const handleCheckBox = (e, checkItem) => {
        const { checked } = e.target;

        if (checked) {
            setItem([...item, checkItem]);
            setSelectedNFTs((prevSelectedNFTs) => {
                const updatedSelectedNFTs = [...prevSelectedNFTs, checkItem];
                setIsFirstStepNextButtonDisabled(false);
                return updatedSelectedNFTs;
            });
            setShowAlert(false);
        } else {
            setItem(item.filter((e) => e !== checkItem));
            setSelectedNFTs((prevSelectedNFTs) => {
                const updatedSelectedNFTs = prevSelectedNFTs.filter((nftId) => nftId !== checkItem);
                setIsFirstStepNextButtonDisabled(true);
                return updatedSelectedNFTs;
            });
        }
    };


    //console.log(allAssetsFromWallet);
    //console.log("+++++++++++++++ showing assets from wallets",allAssetsFromWallet);
    //console.log("showing whitelisted nfts", whiteListedNft);

    dispatch(NFTborrowItemAction(item));
    const thirdArray = whiteListedNft.filter((elem) => {
        return allAssetsFromWallet.some((ele) => {
            return (ele.amount!=0 && ele['asset-id'] == elem.assetID);
            //return ele.index == elem.assetID && elem.borrow == false;
        });
    });

    // whiteListedNft
    // allAssetsFromWallet
    const handleModal = () => {
        onOpen()
    }
    useEffect(() => {

        const getNfts = async () => {
            const data = await getDocs(nftCollectionRef);
            setAllNfts(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
            const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
            const accountAssets = await algodClient.accountInformation(algoAdd).do();

        };

        getNfts();

        const whiteListedNfts = async () => {
            const data = await getDocs(whiteListedNftRef);
            setwhiteListedNfts(data.docs.map((doc) => ({ ...doc.data(), nftId: doc.id })));
        };

        whiteListedNfts();
        fetchAndUpdateWalletData(algoAdd)

    }, [item]);

    async function fetchAndUpdateWalletData(algoAdd) {
        try {
            const response = await axios(`${API_URL}swap/checkOptin?address=` + algoAdd);

            if (response.status === 200) {
                const optinResult = response.data;
                //console.log(">>>>>>>>>>>>>>>", optinResult)

                // dispatch(myAlgoWalletInfo({
                //     algoAddress: address,
                //     algoBalance: response.data.clientInfo.amount / 1000000
                // }));

                dispatch(getAllAssetsAction({
                    assets_from_account: response.data.clientInfo["created-assets"],
                    assets_from_account_2: response.data.clientInfo.assets,
                }));
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    }

    console.log(whiteListedNft);
    console.log("==========", thirdArray);
    console.log(userNFT);
    const currentTime = Date.now();
    return (
        <>
            <HStack py="20px" width="100%" bgColor={bgColor} px="20px" my="20px" borderRadius="15px">
                {/* <Radio colorScheme='red' value='1'>
                                <Text>NFT Name</Text>
                            </Radio> */}
                <Text fontSize="18px" fontWeight="400"> List of NFTs</Text>

            </HStack>

            {/* {userNFT?.length > 0 ? userNFT.map((item) => (
                <>
                    <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px" key={item.id}>
                        <HStack>
                            <Checkbox
                                colorScheme='green'
                                value={item}
                                onChange={(e) => handleCheckBox(e, item)}>
                            </Checkbox>
                            <Image src={item.url} width="80px" height="80px" objectFit="cover" borderRadius="50%" ml="10px" />
                            <Text fontSize="18px" fontWeight="600">{item.name}</Text>
                        </HStack>
                        <HStack>
                            <Image src={pago} w="25px" h="25px" />
                            <Text>{item.price}</Text>
                        </HStack>
                    </HStack>

                </>
            )) :
                <Alert status='warning'>
                    <AlertIcon />
                    Seems you dont have any whitelisted nfts
                </Alert>
            } */}

            {/* {thirdArray?.length > 0 ? thirdArray.map((item) => (
                <>
                    <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                        <Text fontSize="16px" fontWeight="600">Total available borrow 1111</Text>
                        <HStack>
                            <Image src={pago} w="25px" h="25px" />
                            <Text>{borrowItem ? borrowItem.price : 0.00}</Text>
                        </HStack>
                    </HStack>
                </>
            ))
                : <Alert status='warning'>
                    <AlertIcon />
                    Seems you dont have any whitelisted nfts
                </Alert>

            } */}


            {thirdArray?.length > 0 ? thirdArray.map((item) => (
                <>
                    <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px" key={item.assetID}>
                        <HStack>
                            <Checkbox
                                colorScheme='green'
                                value={item}
                                onChange={(e) => handleCheckBox(e, item)}>
                            </Checkbox>
                            <Image src={item.url ? item.url : item.imageFromApi} width="80px" height="80px" objectFit="cover" borderRadius="50%" ml="10px" />
                            <Text fontSize="18px" fontWeight="600">{item.fullName}</Text>
                        </HStack>
                        <HStack>
                            <Image src={pago} w="25px" h="25px" />
                            <Text>{(item.price) / 1000}</Text>
                        </HStack>
                    </HStack>

                </>
            )) :
                <Alert status='warning'>
                    <AlertIcon />
                    Seems you dont have any whitelisted nfts. <Link to="/whitelisted-nft"><span>Click here to get some</span></Link>
                </Alert>
            }



            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                <Text fontSize="16px" fontWeight="400" textAlign="center">Choose at least one NFT token which you want to deposit as collateral. The more collaterals you deposit, the higher ALGO amount you can borrow. </Text>

            </HStack>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>You dont have any whitelisted nfts</Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default FirstSlide
