import { Box, Container, HStack, useColorModeValue, VStack, Text, Image, Progress, Divider, Radio, Checkbox, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Alert, AlertIcon, useDisclosure, ModalCloseButton, Center, Input, Spinner, Select } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import pagoCoin from '../components/images/pago-head.png';
import pago from '../components/images/Pago-Coin.png';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    Timestamp,
} from "firebase/firestore";
import { db } from '../components/firebase/FirebaseConfig';
import axios from 'axios';
import algosdk, { algosToMicroalgos, bigIntToBytes, bytesToBigInt, decodeAddress, getApplicationAddress, microalgosToAlgos } from 'algosdk';
//import MyAlgoConnect from "@randlabs/myalgo-connect";
import { useNavigate } from 'react-router-dom';
import { getAllAssetsAction, myAlgoWalletInfo, PoolAmountAction } from '../components/redux/actions';
import { rates } from '../components/UtilizationRate/UtilizationRateData';
import { API_URL } from '../url';
import { peraWallet } from "./WalletNew";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { toast } from "react-toastify"
import BigNumber from 'bignumber.js';

const borrowAppId = parseInt(process.env.REACT_APP_BORROW_APP_ID);
const borrowAppIdUSDC = parseInt(process.env.REACT_APP_USDC_POOL_ID);
const algoNodeApi = process.env.REACT_APP_ALGONODE_TESTNET_ADDRESS;

const animationVariants = {
    hidden: {
        opacity: 0,
        scale: 0,
        rotate: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
    exit: {
        opacity: 0,
        scale: 0,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
};

function Deposit(props) {
    const navigate = useNavigate();
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const appIdFromRedux = useSelector(state => state.token.borrow_app_id);
    const allAssets = useSelector(state => state.token.assets_from_account_2);
    const [option, setOption] = useState("algo");
    //const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const walletAddress = useSelector(state => state.token.walletConnectAddress);
    const deposit_app_id = useSelector(state => state.token.deposit_app_id);
    const walletAmount = useSelector(state => state.token.walletConnectBalance);
    const walletConnectStatus = useSelector(state => state.token.walletConnectStatus);
    const deposit_rate = useSelector(state => state.token.deposit_rate);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const textColor = useColorModeValue("gray.600", "whiteAlpha.900");
    const [allNfts, setAllNfts] = useState([]);
    const nftCollectionRef = collection(db, "nfts");
    const nftBorrowRef = collection(db, "borrow");
    const nftBorrowUsdcRef = collection(db, "borrow-usdc");
    const [checkbox, setCheckBox] = useState(true);
    const [checkBoxItem, setCheckBoxItem] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const modal1 = useDisclosure();
    const modal2 = useDisclosure();
    const modal3 = useDisclosure();
    const modal4 = useDisclosure();
    const [item1, setItem1] = useState(false);
    const [item2, setItem2] = useState(false);
    const [item3, setItem3] = useState(false);
    const [item4, setItem4] = useState(false);
    const [item5, setItem5] = useState(false);
    const [data, setData] = useState([]);
    const [borrowAmount, setAmount] = useState(0);
    const nftDepositRef = collection(db, "deposit");
    const interestDepositRef = collection(db, "interest_rate_index");
    const nftDepositUSDCRef = collection(db, "depositUSDC");
    const [totalDeposit, setDeposit] = useState();
    const [totalDepositUsdc, setDepositUsdc] = useState();
    const [totalBorrow, setBorrow] = useState();
    const [totalBorrowUsdc, setBorrowUsdc] = useState();
    const [poolAmountNew, setPoolAmountNew] = useState();
    const [poolAmountUSDC, setPoolAmountUSDC] = useState();
    const borrowInfoRef = collection(db, "borrow-info");
    const borrowInfoUsdcRef = collection(db, "borrow_info_usdc");
    const [borrowInfo, setBorrowInfo] = useState();
    const [deposit_interest_info, setDepositInterestInfo] = useState();
    const [borrowUsdcInfo, setBorrowUsdcInfo] = useState()
    const [allDeposit, setAllDeposit] = useState()
    const [checkAmount, setCheckAmount] = useState(false)
    //const [depositAccAmount, setDepositAccAmount] = useState();
    const dispatch = useDispatch();
    const poolAmount = useSelector(state => state.token.pool_amount);
    const depositSummaryRef = collection(db, "depositSummary");
    const [sliderValue, setSliderValue] = useState(0);
    const usdcAmount = allAssets.find((a) => a["asset-id"] == "10458941");
    const [depositSum, setAllDepositSum] = useState();
    const [depositCount, setDepositCount] = useState(0);
    //const depositSummaryRef = collection(db, "depositSummary");
    function isValidMicroAlgos(microalgos) {
        const maxMicroAlgos = new BigNumber(2).pow(253).minus(1);
        const microAlgosBN = new BigNumber(microalgos);
        return microAlgosBN.gte(0) && microAlgosBN.lt(maxMicroAlgos);
    }
    let totalBorrowAmount = 0;
    let totalDipositAmount = 0;
    let totalUsdcDipositAmount = 0;
    let totalUsdcBorrowAmount = 0;

    const marks = {

        0: <strong>0%</strong>,
        25: '25%',
        50: '50%',
        75: '75%',
        100: {
            style: {
                color: 'green',
            },
            label: <strong>100%</strong>,
        },
    };
    //console.log(allDeposit);
    const handleChange = (event) => {
        const userInputVal = option == "algo" ? algoBalance : algosdk.microalgosToAlgos(usdcAmount.amount)

        if (event.target.value >= 0 <= userInputVal) {
            const inputValue = event.target.value;
            if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= userInputVal) {
                setAmount(inputValue);
                const newSliderValue = (inputValue / userInputVal) * 100;
                setSliderValue(newSliderValue);
            } else if (inputValue < 0) {
                //console.log("erereoreor");
                toast.error("Invalid Amount")
            } else if (inputValue > userInputVal) {
                toast.error("Not enough balance")
            }
        }

    }

    function log(value) {
        const newAmount = option == "algo" ? algoBalance * value / 100 : (algosdk.microalgosToAlgos(usdcAmount.amount)) * value / 100;
        setAmount(newAmount.toFixed(2));
        setSliderValue(value);
    }

    const countUserDeposits = async (userId) => {
        const depositData = await getDocs(nftDepositRef);
        const userDeposits = depositData.docs.filter(doc => doc.data().account === userId);
        return userDeposits.length;
    };


    useEffect(() => {
        const getNfts = async () => {
            const data = await getDocs(nftCollectionRef);
            setAllNfts(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
        };

        getNfts();

        pool_amount_func();
        pool_amount_func_usdc();
        let amount = 0;
        let totalUsdcDipositAmount = 0;
        let totalUsdcBorrowAmount = 0;
        const utilisationRate = async () => {

            const depositData = await getDocs(nftDepositRef);
            await depositData.docs.map((doc) => {
                totalDipositAmount += parseFloat(doc.data().amount)
                setDeposit(totalDipositAmount);
            })


            const borrowData = await getDocs(nftBorrowRef);
            await borrowData.docs.map((doc) => {
                amount += parseFloat(doc.data().amount)
                setBorrow(amount);

            })

            let utilisationRateVar = totalBorrowAmount / totalDipositAmount;

        };

        utilisationRate();

        const getBorrowInfoFromFirebase = async () => {
            const data = await getDocs(borrowInfoRef);
            setBorrowInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getBorrowInfoFromFirebase();

        const getBorrowUsdcInfoFromFirebase = async () => {
            const data = await getDocs(borrowInfoUsdcRef);
            setBorrowUsdcInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getBorrowUsdcInfoFromFirebase();

        const getDepositData = async () => {
            const depositSum = await getDocs(depositSummaryRef);
            console.log(depositSum);
            setAllDeposit(depositSum.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        }
        getDepositData();

        const utilisationRateForUSDC = async () => {

            const depositData = await getDocs(nftDepositUSDCRef);
            await depositData.docs.map((doc) => {
                totalUsdcDipositAmount += parseFloat(doc.data().amount)
                setDepositUsdc(totalUsdcDipositAmount);
            })

            const borrowData = await getDocs(nftBorrowUsdcRef);
            await borrowData.docs.map((doc) => {
                totalUsdcBorrowAmount += parseFloat(doc.data().amount)
                setBorrowUsdc(totalUsdcBorrowAmount);

            })

            //let utilisationRateVar = totalBorrowAmount / totalDipositAmount;

        };

        utilisationRateForUSDC();

        const getDepositInterestInfoFromFirebase = async () => {
            const data = await getDocs(interestDepositRef);
            setDepositInterestInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getDepositInterestInfoFromFirebase();

        const getDepositSUmmary = async () => {
            const data = await getDocs(depositSummaryRef);
            setAllDepositSum(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getDepositSUmmary();

        const fetchDepositCountAndAdjustInterest = async () => {
            const userId = algoAdd; // Assuming algoAdd is the user's identifier
            const depositCount = await countUserDeposits(userId);
            setDepositCount(depositCount); // Update state with the deposit count

            // Here, you can adjust the interest rate based on the depositCount
            // For example, if depositCount < 3, apply a higher interest rate
        };

        fetchDepositCountAndAdjustInterest();

    }, [algoAdd]);

    //console.log(totalDepositUsdc);
    //console.log(totalBorrowUsdc);
    //console.log(deposit_interest_info);

    const deposit_info_from_firebase_for_algo = deposit_interest_info?.filter((item) => item.type == "algo")
    const deposit_info_from_firebase_for_usdc = deposit_interest_info?.filter((item) => item.type == "usdc")

    //console.log(deposit_info_from_firebase_for_algo);
    //console.log(deposit_info_from_firebase_for_usdc);
    //console.log("------------",depositCount);

    //finding deposit rate

    // })

    const borrow_rate_new = deposit_info_from_firebase_for_algo?.map((item) => {
        if ((totalBorrow / poolAmountNew) < item.Uoptimal) {
            return item.R0 + ((totalBorrow / poolAmountNew) / item.Uoptimal) * item.Rslope1
        } else {
            return item.R0 + item.Rslope1 + ((totalBorrow / poolAmountNew) - item.Uoptimal) / (1 - item.Uoptimal) * item.Rslope2
        }

    })

    // ================**************** borrow algo index *******************===========

    const borrowAlgoIndex = () => {
        console.log("hello from index");
        console.log(deposit_info_from_firebase_for_algo);
        console.log(borrow_rate_new[0]);
        const currentTime = Date.now();
        console.log(currentTime);
        console.log(deposit_info_from_firebase_for_algo[0].change_in_time);
        const change_time = (currentTime - deposit_info_from_firebase_for_algo[0].change_in_time);
        console.log(change_time);
        const alpha = borrow_rate_new[0] / (365 * 24 * 60 * 60);
        console.log(alpha);

        const index = deposit_info_from_firebase_for_algo[0].borrow_index_algo * (1 + deposit_info_from_firebase_for_algo[0].sigma * (alpha * change_time));
        console.log("borrow_index_value_in_deposit_function" + index);
        return index;
    }

    // =================== ****************** borrow algo index end ****************** ==========================


    const borrowUSDCIndex = () => {
        const borrow_rate_usdc = deposit_info_from_firebase_for_usdc?.map((item) => {
            if ((totalBorrowUsdc / totalDepositUsdc) < item.Uoptimal) {
                return item.R0 + ((totalBorrowUsdc / totalDepositUsdc) / item.Uoptimal) * item.Rslope1
            } else {
                return item.R0 + item.Rslope1 + ((totalBorrowUsdc / totalDepositUsdc) - item.Uoptimal) / (1 - item.Uoptimal) * item.Rslope2
            }

        })

        const currentTime = Date.now();
        console.log(currentTime);
        console.log(deposit_info_from_firebase_for_usdc[0].change_in_time);
        const change_time = (currentTime - deposit_info_from_firebase_for_usdc[0].change_in_time);
        console.log(change_time);
        console.log(borrow_rate_usdc);


        const alpha = borrow_rate_usdc[0] / (365 * 24 * 60 * 60);
        console.log(alpha);

        const index = deposit_info_from_firebase_for_usdc[0].borrow_index_usdc * (1 + deposit_info_from_firebase_for_usdc[0].sigma * (alpha * change_time));
        console.log("borrow_index_value_in_deposit_function" + index);
        return index;
    }


    const borrow_rate_usdc = deposit_info_from_firebase_for_usdc?.map((item) => {
        if ((totalBorrowUsdc / totalDepositUsdc) < item.Uoptimal) {
            return item.R0 + ((totalBorrowUsdc / totalDepositUsdc) / item.Uoptimal) * item.Rslope1
        } else {
            return item.R0 + item.Rslope1 + ((totalBorrowUsdc / totalDepositUsdc) - item.Uoptimal) / (1 - item.Uoptimal) * item.Rslope2
        }

    })
    // deposit rate usdc - ends
    //console.log(borrow_rate_usdc);
    //console.log(borrow_rate_new);
    const deposit_rate_new = (totalBorrow / poolAmountNew) * borrow_rate_new?.[0] * (1 - 0.3);
    const deposit_rate_usdc = (totalBorrowUsdc / totalDepositUsdc) * borrow_rate_usdc?.[0] * (1 - 0.3);

    const valueRate = rates.filter((data) => data.utilization === Math.round(totalBorrow / totalDeposit));
    const pool_amount_func = async () => {
        const algodClient = new algosdk.Algodv2("", algoNodeApi, '');

        const escrowAddress = await getApplicationAddress(borrowAppId);

        let accountInfo = await algodClient.accountInformation(escrowAddress).do();

        let accountAmount = algosdk.microalgosToAlgos(accountInfo.amount);
        console.log(accountAmount);
        console.log(accountInfo);
        setPoolAmountNew(accountAmount)
        //dispatch(PoolAmountAction(accountAmount));
    }

    const pool_amount_func_usdc = async () => {
        const algodClient = new algosdk.Algodv2("", algoNodeApi, '');

        const escrowAddress = await getApplicationAddress(borrowAppIdUSDC);

        let accountInfo = await algodClient.accountInformation(escrowAddress).do();

        let accountAmount = algosdk.microalgosToAlgos(accountInfo.assets[0].amount);
        //console.log(accountAmount);
        //console.log(accountInfo);
        setPoolAmountUSDC(accountAmount)
        //dispatch(PoolAmountAction(accountAmount));
    }

    //const userNFT = allNfts.filter((data) => data.owner_address === algoAdd);

    async function getUserBalance(address) {
        try {
            const response = await axios(`${API_URL}swap/checkOptin?address=` + address);

            if (response.status === 200) {
                const optinResult = response.data;

                dispatch(myAlgoWalletInfo({
                    algoAddress: address,
                    algoBalance: response.data.clientInfo.amount / 1000000
                }));

            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    }

    const checkOptin = async (accountAddr, assetId) => {
        const algodClient = new algosdk.Algodv2("", algoNodeApi, '');
        const suggestedParams = await algodClient.getTransactionParams().do();
        const accountInfo = await algodClient.accountInformation(accountAddr).do();
        const hasOptedIn = accountInfo.assets.some((asset) => asset['asset-id'] === assetId);
        return hasOptedIn;
    }
    const handleDeposit = async () => {

        const appId = appIdFromRedux;
        const assetID = Number(process.env.REACT_APP_PAGO_ID);
        //console.log("PAGO ID", assetID);
        const optinCheck = await checkOptin(algoAdd, assetID);
        //console.log ("====", optinCheck)
        //return

        if (Number(borrowAmount) > Number(algoBalance)) {
            setCheckAmount(true);
            setTimeout(() => {
                setCheckAmount(false);
                setAmount("")
            }, 3000);
        }
        else if (borrowAmount <= 0) {
            modal3.onOpen();
        } else {
            //onOpen();
            modal4.onOpen();
            setTimeout(() => {
                if (optinCheck) {
                    fund_escrow_for_deposit(appId, assetID)
                } else {
                    assetOptin(appId, assetID)
                }
            }, 3000);
        }

    }
    const assetOptin = async (appId, assetid) => {

        const algodClient = new algosdk.Algodv2("", algoNodeApi, '');
        const params = await algodClient.getTransactionParams().do();

        var note = new Uint8Array([10]);

        let sender = algoAdd;
        let recipient = sender;
        let revocationTarget = undefined;
        let closeRemainderTo = undefined;
        let amount = 0;
        let assetID = assetid;
        // signing and sending "txn" allows sender to begin accepting asset specified by creator and index
        let txn = algosdk.makeAssetTransferTxnWithSuggestedParams(
            sender,
            recipient,
            closeRemainderTo,
            revocationTarget,
            amount,
            note,
            assetID,
            params);

        const singleTxnGroups = [{ txn: txn, signers: [algoAdd] }];

        modal4.onClose();
        const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
        const response = await algodClient.sendRawTransaction(signedTxn).do()
        setItem3(true);
        setTimeout(() => {
            fund_escrow_for_deposit(appId, assetid)
        }, 2000);

    }

    const fund_escrow_for_deposit = async (appId, assetID) => {
        console.log(borrowAmount);
        console.log(appId);
        console.log(assetID);
        try {
            const algodClient = new algosdk.Algodv2("", algoNodeApi, '');
            const suggested_params = await algodClient.getTransactionParams().do();

            const escrowAddress = await getApplicationAddress(appId);

            const diposit = new Uint8Array(Buffer.from('diposit', 'utf8'));
            const fund = bigIntToBytes(borrowAmount, 8)


            const txn1 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                from: algoAdd,
                suggestedParams: suggested_params,
                to: escrowAddress,
                amount: (borrowAmount * 1000000)
            })

            const txns = [txn1];

            const groupID = algosdk.assignGroupID(txns);
            //onClose();
            modal4.onClose();

            //peraWallet connection
            const singleTxnGroups = [{ txn: txn1, signers: [algoAdd] }];

            const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);

            const response = await algodClient.sendRawTransaction([signedTxn[0]]).do();

            setItem1(true);
            //onOpen();
            modal4.onOpen();
            setTimeout(() => {
                //setPopUp(false);
                make_borrow_offer_for_deposit(appId, assetID);
            }, 4000);

        } catch (error) {
            console.log(error);
            //toast.error("ERROR, in Fund Escrow.")
        }
    }

    const escrow_lsg_address = async () => {

        let escrow_fund_program_compiled = undefined
        let escrow_address = undefined

        await axios(`${API_URL}swap/depositLogicSig`)
            .then(response => {

                if (response.status === 200) {

                    let data = response.data;

                    escrow_fund_program_compiled = data.escrow_fund_program_compiled
                    escrow_address = data.escrow_address

                }

            })
            .catch(error => {
                console.log(error);
                //toast.error("Error fetching data")

            })

        const algodClient = new algosdk.Algodv2("", algoNodeApi, '');

        const escrowlcompiledProgram = await algodClient.compile(escrow_fund_program_compiled).do();

        const escrowProgramBytes = new Uint8Array(
            Buffer.from(escrowlcompiledProgram.result, 'base64')
        );


        return escrowProgramBytes;

    }

    const make_borrow_offer_for_deposit = async (appId, assetID) => {
        console.log(appId, assetID);
        console.log(borrowInfo);
        let newAlgoIndex = borrowAlgoIndex();

        try {
            console.log("In the try block");
            const depositSummaryInfo = allDeposit?.find((item) => item.type === "deposit")
            console.log(depositSummaryInfo);
            const algodClient = new algosdk.Algodv2("", algoNodeApi, '');
            const suggested_params = await algodClient.getTransactionParams().do();

            //const escrowAddress = await getApplicationAddress(appId);
            const escrowAddress = await getApplicationAddress(255573490);
            console.log(escrowAddress);
            const diposit = new Uint8Array(Buffer.from('diposit', 'utf8'));
            let accountInfo = await algodClient.accountInformation(escrowAddress).do();


            let accountAmount = algosdk.microalgosToAlgos(accountInfo.amount)

            // dispatch(PoolAmountAction(accountAmount));

            accountAmount = parseInt(accountAmount)

            const totalAmount = bigIntToBytes(accountAmount, 8)

            const depositAmount = bigIntToBytes(borrowAmount, 8)
            const pool_apr = bigIntToBytes(10, 8);

            let address = getApplicationAddress(appId);

            let end_Time = new Date().getTime();

            end_Time = bigIntToBytes((end_Time), 8);
            const txn2 = algosdk.makeApplicationCallTxnFromObject({
                from: algoAdd,
                appIndex: appId,
                onComplete: 0,
                appArgs: [diposit, totalAmount, depositAmount, pool_apr, end_Time],
                suggestedParams: suggested_params,
                // foreignAssets:[92772865],
                foreignAssets: [assetID],
                accounts: [escrowAddress]


            })
            const logicSigInfo = await escrow_lsg_address();
            let lsig = new algosdk.LogicSigAccount(logicSigInfo)

            const txns = [txn2];

            const groupID = algosdk.assignGroupID(txns)

            const singleTxnGroups = [{ txn: txn2, signers: [algoAdd] }];

            modal4.onClose();
            const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);

            modal4.onOpen();
            const response = await algodClient.sendRawTransaction([signedTxn[0]]).do();

            let applicationInfoResponse = await algodClient.accountApplicationInformation("RRO7OHROIWSOA7YXJBACWXOTCG4BWX3XLNSJLTJUSPRI5KHP3JXUTDM63Q", appId).do();
            let depositIndex = 0


            /*
             ** Calculating index for deposit - algo
            */
            const depositSummaryInfoNew = depositSum?.find((item) => item.type === "deposit")
            const alpha = (depositSummaryInfoNew?.poolAPR / depositSummaryInfoNew?.timeVal) * 100;
            const time = depositSummaryInfoNew?.changeOfTime;
            const nowTime = Date.now()
            const nowTimeSecons = Math.round(nowTime / 1000);
            let timeInterval = nowTimeSecons - Number(time?.seconds);
            const index = depositSummaryInfoNew?.initialIndex * (1 + (timeInterval * alpha))
            console.log(index);
            /*
            ** Calculating index for deposit - algo - end
            */

            // depositIndex = depositIndex / 100000000
            // console.log(depositIndex);

            const boostFactor = depositCount < 2 ? 1.5 : 1;
            let pago_amount = ((borrowAmount) / index)
            // If this is one of the first 3 deposits, boost the pago_amount
            pago_amount *= boostFactor;
            console.log("pago amount" + pago_amount);
            let micro_pago_amount = pago_amount * 1000000
            console.log("micro_pago_amount" + micro_pago_amount);
            const txn3 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                from: lsig.address(),
                suggestedParams: {
                    ...suggested_params
                },
                to: algoAdd,
                amount: parseInt(micro_pago_amount),
                // assetIndex: 108232239,
                assetIndex: assetID,
                // revocationTarget: "XQUXRUK2XUNJDT22J5BKYUADZHRWE5K5R5M23SNYINHPUYXCE42V3OO27I",
                revocationTarget: "RRO7OHROIWSOA7YXJBACWXOTCG4BWX3XLNSJLTJUSPRI5KHP3JXUTDM63Q",
                // foreignAssets: [108232239],
                foreignAssets: [assetID]

                // const txn3 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                //     from: lsig.address(),
                //     suggestedParams: {
                //         ...suggested_params
                //     },
                //     to: algoAdd,
                //     amount: parseInt(micro_pago_amount),
                //     // assetIndex: 108232239,
                //     assetIndex: process.env.REACT_APP_USDC_ID,
                //     // revocationTarget: "XQUXRUK2XUNJDT22J5BKYUADZHRWE5K5R5M23SNYINHPUYXCE42V3OO27I",
                //     revocationTarget: "RRO7OHROIWSOA7YXJBACWXOTCG4BWX3XLNSJLTJUSPRI5KHP3JXUTDM63Q",
                //     // foreignAssets: [108232239],
                //     foreignAssets: [process.env.REACT_APP_USDC_ID]

            })
            console.log("before signedTxn1");
            let signedTxn1 = algosdk.signLogicSigTransactionObject(txn3, lsig);

            const response1 = await algodClient.sendRawTransaction(signedTxn1.blob).do();
            console.log(response1);

            setItem2(true);
            console.log(index);
            console.log(Date.now());
            await addDoc(nftDepositRef, { amount: borrowAmount, account: algoAdd, app_id: appId, time: Timestamp.now() });

            const userDoc = doc(db, "interest_rate_index", deposit_info_from_firebase_for_algo[0].id);
            await updateDoc(userDoc, { change_in_time: Date.now(), deposit_index_algo: index, borrow_index_algo: newAlgoIndex });

            const userDoc2 = doc(db, "depositSummary", depositSummaryInfo.id);
            await updateDoc(userDoc2, { poolAPR: deposit_rate_new, initialIndex: index });
            // After a successful deposit, update the user wallet balance
            await getUserBalance(algoAdd);

            // setTimeout(() => {
            //     onClose();
            // }, 12000);
            modal4.onClose();
            modal2.onOpen();
            setTimeout(() => {
                modal2.onClose();
            }, 5000);
            setTimeout(() => {
                navigate("/dashboard");
            }, 4000);

        } catch (error) {
            console.log(error);
            //toast.error("ERROR, in Make Deposit Offer.")
        }


    }

    console.log(option);

    /*
    *** Deposit function for USDC start from here
    */
    const nftDeposiUSDCtRef = collection(db, "depositUSDC");
    let total_Borrow_Amount = 0
    let total_Diposit_Amount = 0

    const deposit_function_for_usdc = async () => {
        const app_id = 254192582;
        let newUsdcBorrowIndex = borrowUSDCIndex();
        console.log(app_id);
        console.log("I am in deposit_function_for_usdc");
        const optinCheck = checkOptin(algoAdd, 10458941);
        console.log(optinCheck);
        console.log(deposit_interest_info);

        const findUSDCValue = deposit_interest_info?.filter((item) => item.type === "usdc")
        const borrow_rate_new = findUSDCValue?.map((item) => {
            if ((totalBorrowUsdc / totalDepositUsdc) < item.Uoptimal) {
                console.log("in if");
                return item?.R0 + ((totalBorrowUsdc / totalDepositUsdc) / item?.Uoptimal) * item?.Rslope1
            } else {
                console.log("in else");
                return item?.R0 + item?.Rslope1 + ((totalBorrowUsdc / totalDepositUsdc) - item?.Uoptimal) / (1 - item?.Uoptimal) * item?.Rslope2
            }
        })
        console.log(findUSDCValue);
        console.log(findUSDCValue);
        console.log(findUSDCValue[0].id);
        console.log(totalBorrowUsdc);
        console.log(totalDepositUsdc);
        console.log(borrow_rate_new);
        const pool_apr = totalBorrowUsdc == 0 || totalDepositUsdc == 0 ? 1 : (totalBorrowUsdc / totalDepositUsdc) * borrow_rate_new * (1 - 0.3)
        //const pool_apr = (2 / totalDeposit) * borrow_rate_new * (1 - 0.3)

        // const depositSum = await getDocs(depositSummaryRef);
        console.log(allDeposit);
        const depositSummaryInfo = allDeposit?.find((item) => item.type === "usdc")
        console.log(depositSummaryInfo);
        console.log(pool_apr);
        // const depositSummaryInfo = dataDeposit?.find((item) => item.type === "deposit")
        const alpha = (pool_apr / depositSummaryInfo?.timeVal) * 100;
        console.log(alpha);
        const time = depositSummaryInfo?.changeOfTime;
        const nowTime = Date.now()
        const nowTimeSecons = Math.round(nowTime / 1000);
        let timeInterval = nowTimeSecons - Number(time?.seconds);
        console.log(timeInterval);
        const depositIndex = depositSummaryInfo?.initialIndex * (1 + (timeInterval * alpha))
        console.log(depositIndex);
        let pago_amount = ((borrowAmount) / depositIndex) // deposit amount
        let micro_pago_amount = parseInt(pago_amount * 1000000)
        const algodClient = new algosdk.Algodv2("", algoNodeApi, '');
        const suggested_params = await algodClient.getTransactionParams().do();
        const escrowAddress = await getApplicationAddress(app_id);
        console.log(escrowAddress);
        const diposit = new Uint8Array(Buffer.from('diposit', 'utf8'));
        let accountInfo = await algodClient.accountInformation(escrowAddress).do();
        let accountAmount = algosdk.microalgosToAlgos(accountInfo.amount)
        accountAmount = parseInt(accountAmount)
        const totalAmount = bigIntToBytes(accountAmount, 8) // accountAmount
        const depositAmount = bigIntToBytes(borrowAmount, 8) // deposit amount
        let depositIndex2 = depositIndex * 1000000
        //alert(depositIndex2)

        depositIndex2 = bigIntToBytes(parseInt(depositIndex2), 8); // index er value
        let end_Time = new Date().getTime();
        end_Time = bigIntToBytes((end_Time), 8);

        let revocationTarget = undefined;
        let closeRemainderTo = undefined;
        var note = new Uint8Array([0]);
        let txn = algosdk.makeAssetTransferTxnWithSuggestedParams(
            algoAdd,
            algoAdd,
            undefined,
            undefined,
            0,
            note,
            //228816363,
            deposit_app_id,
            suggested_params);

        const txn2 = algosdk.makeApplicationCallTxnFromObject({
            //from: "RRO7OHROIWSOA7YXJBACWXOTCG4BWX3XLNSJLTJUSPRI5KHP3JXUTDM63Q",
            from: algoAdd,
            appIndex: app_id,
            onComplete: 0,
            appArgs: [diposit, totalAmount, depositIndex2, bigIntToBytes(micro_pago_amount), end_Time],
            suggestedParams: suggested_params,
            // foreignAssets:[92772865],
            //foreignAssets: [228816363],
            foreignAssets: [deposit_app_id],
            accounts: [escrowAddress]
        })

        let txn3 = algosdk.makeAssetTransferTxnWithSuggestedParams(
            algoAdd,
            escrowAddress,
            closeRemainderTo,
            revocationTarget,
            algosdk.algosToMicroalgos(borrowAmount),
            note,
            //10458941,
            parseInt(process.env.REACT_APP_USDC_ID),
            suggested_params);
        // const myAlgoConnect = new MyAlgoConnect();
        // const signedTxn = await myAlgoConnect.signTransaction(txn2.toByte());
        // const response = await algodClient.sendRawTransaction(signedTxn.blob).do();

        console.log("I am here");
        // const singleTxnGroups = [{ txn: txn2, signers: [algoAdd] }];
        // console.log();
        // const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
        // console.log(signedTxn);
        // const response = await algodClient.sendRawTransaction([signedTxn[0]]).do();
        let singleTxnGroups = undefined;
        let txns = undefined;
        if (optinCheck) {
            console.log(" ============In if===========");
            singleTxnGroups = [{ txn: txn2, signers: [algoAdd] }, { txn: txn3, signers: [algoAdd] }];
            txns = [txn2, txn3];
        } else {
            console.log(" ============In else===========");
            singleTxnGroups = [{ txn: txn, signers: [algoAdd] }, { txn: txn2, signers: [algoAdd] }, { txn: txn3, signers: [algoAdd] }];
            txns = [txn, txn2, txn3];
        }

        //const txns = [txn, txn2, txn3];
        const groupID = algosdk.assignGroupID(txns);

        //const singleTxnGroups = [{ txn: txn, signers: [algoAdd] }, { txn: txn2, signers: [algoAdd] }, { txn: txn3, signers: [algoAdd] }];
        console.log(singleTxnGroups);
        //const signedTxn = await peraWallet.signTransaction([singleTxnGroups])
        const signedTxn = await peraWallet.signTransaction([singleTxnGroups])
        //console.log(signedTxn); return 0;
        const response = await algodClient.sendRawTransaction(signedTxn).do();


        console.log(response)
        console.log(Date.now());
        await addDoc(nftDeposiUSDCtRef, { amount: borrowAmount, account: algoAdd, app_id: app_id, time: Timestamp.now() });

        const userDoc = doc(db, "interest_rate_index", deposit_info_from_firebase_for_usdc[0].id);
        //await updateDoc(userDoc, { change_in_time: Timestamp.now(), deposit_index_usdc: depositIndex });
        await updateDoc(userDoc, { change_in_time: Date.now(), deposit_index_usdc: depositIndex, borrow_index_usdc: newUsdcBorrowIndex });

        const userDoc2 = doc(db, "depositSummary", depositSummaryInfo.id);
        await updateDoc(userDoc2, { poolAPR: pool_apr });
        toast.success("USDC deposit successfully");
        setTimeout(() => {
            navigate("/dashboard");
        }, 2000);

        // let signedTxn1 = algosdk.signLogicSigTransactionObject(txn3, lsig);

        // const response1 = await algodClient.sendRawTransaction(signedTxn1.blob).do();
        // console.log(response1);
    }
    const boostFactor = depositCount < 2 ? 1.5 : 1;
    const isEligibleForBonus = depositCount < 3;

    return (
        <>
            <Box zIndex="100" top="0" position="fixed" width="100%">{walletConnectStatus ? <DesktopNav name={walletAddress} amount={walletAmount} /> : <DesktopNav name={algoAdd} amount={algoBalance} />}</Box>

            <Box minH="100vh" py="50px">
                {/* first component start from here  */}

                <Box w="100%" mt="70px">
                    <Container maxW={'3xl'} borderRadius="20px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" pb="20px">
                        <HStack alignItems="center" justifyContent="space-between" justifyItems="center" px="20px" py="30px">
                            <VStack align="left">
                                <Text fontSize="30px" fontWeight="600" mb="-5px">Deposit</Text>
                                <HStack>
                                    <Text fontSize="18px" color={textColor}>Available in wallet :
                                    </Text>
                                    <Image src={pago} h="20px" w="20px" />
                                    <Text fontSize="18px" color={textColor} fontWeight="700">{option == "algo" ? algoBalance.toFixed(2) : usdcAmount && isValidMicroAlgos(usdcAmount.amount) ? algosdk.microalgosToAlgos(usdcAmount.amount).toFixed(2) : 'You dont have any USDC!'}
                                    </Text>
                                </HStack>


                            </VStack>

                            <Image src={pagoCoin} h="50px" w="50px" />
                        </HStack>
                        <HStack w="50%" ml="10px">
                            <Box>
                                <Box w="100%" textAlign="left" mb="5px">
                                    <Text fontWeight="500">Select Token</Text>
                                </Box>
                                <Box w="100%">
                                    <Select onChange={(e) => setOption(e.target.value)}>
                                        <option value='algo'>Algo</option>
                                        <option value='usdc'>USDC</option>
                                    </Select>
                                </Box>
                            </Box>
                        </HStack>

                        <HStack py="20px" width="100%" bgColor={bgColor} px="20px" my="20px" borderRadius="15px" justifyItems="center" justifyContent="space-between">
                            <VStack>
                                <Text fontSize="12px" color={textColor}>Utilization rate</Text>
                                <Text fontSize="18px" color={textColor} fontWeight="700">{option == "algo" ? totalBorrow && poolAmountNew ? ((totalBorrow / poolAmountNew) * 100).toFixed(2) : "0.00" : totalBorrowUsdc && totalDepositUsdc ? ((totalBorrowUsdc / totalDepositUsdc) * 100).toFixed(2) : "0.00"}
                                    %</Text>

                            </VStack>

                            <VStack>
                                <Text fontSize="12px" color={textColor}>Pool balance</Text>
                                <Text fontSize="18px" color={textColor} fontWeight="700" >{option == "algo" ? poolAmountNew ? Number(poolAmountNew).toFixed(2) : "0.00" : poolAmountUSDC ? Number(poolAmountUSDC).toFixed(2) : "0.00"}</Text>
                            </VStack>

                            <VStack>
                                <Text fontSize="12px" color={textColor}>Deposit APR</Text>
                                <Text fontSize="18px" color={textColor} fontWeight="700">
                                    {option == "algo" ?
                                        depositCount < 2 && deposit_rate_new ?
                                            ((boostFactor - 1) * 100).toFixed(2) : // Apply booster if eligible and rate exists
                                            deposit_rate_new ?
                                                (deposit_rate_new * 100).toFixed(2) : "0.00"
                                        :
                                        depositCount < 2 && deposit_rate_usdc ?
                                            ((boostFactor - 1) * 100).toFixed(2) : // Apply booster if eligible and rate exists for USDC
                                            deposit_rate_usdc ?
                                                (deposit_rate_usdc * 100).toFixed(2) : "0.00"
                                    }%
                                </Text>
                                {isEligibleForBonus && (
                                    <Text fontSize="14px" color={textColor} mt="2">
                                        Bonus rate for your first 2 deposits!
                                    </Text>
                                )}

                            </VStack>
                        </HStack>


                        <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                            <VStack w="100%" align="left">
                                <Text fontSize="16px" fontWeight="600">Amount</Text>
                                <Input
                                    type="number"
                                    name='Amount'
                                    //value={borrowAmount.toFixed(2)}
                                    value={borrowAmount}
                                    required={true}
                                    border="none"
                                    color="gray.500"
                                    bgColor={'gray.200'}
                                    py="30px"
                                    placeholder='Please enter the deposit amount'
                                    onChange={handleChange}
                                />
                            </VStack>
                            {/* <VStack w="40%" align="center">
                                <Text fontSize="16px" fontWeight="600">Total available borrow</Text>
                                <HStack>
                                    <Image src={pago} w="25px" h="25px" />
                                    <Text>{algoBalance}</Text>
                                </HStack>
                            </VStack> */}

                        </HStack>

                        <HStack justifyContent="space-between" justifyItems="center" px="20px" py="30px" width="100%" bgColor={bgColor} m="10px 0px 40px 0px" borderRadius="15px" pb="20px">
                            <Slider min={0} marks={marks} step={1} max={100} onChange={log} value={sliderValue} />
                        </HStack>

                        <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">
                            <Text fontSize="16px" fontWeight="400" textAlign="center">Enter the amount of tokens you'd like to deposit. </Text>

                        </HStack>

                        <Button width="100%" bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} style={{ marginBottom: "10px" }} onClick={option == "algo" ? handleDeposit : deposit_function_for_usdc}>Deposit</Button>

                        {checkAmount ? <Alert status='warning'>
                            <AlertIcon />
                            Not enough funds in wallet
                        </Alert> : ""}


                    </Container>
                </Box>
            </Box>

            {/* ===================Modal for deposit functionality====================== */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Deposit Action</ModalHeader>
                    <ModalBody py="30px">
                        <VStack>
                            {item1 ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Fund Escrow
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Fund Escrow
                                </Alert>
                            }
                            <Divider />
                            {item2 ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Make Deposit
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Make Deposit
                                </Alert>
                            }

                        </VStack>
                    </ModalBody>
                    <VStack>

                    </VStack>

                </ModalContent>
            </Modal>
            {/*========================== Modal for borrow functionality ends==================*/}

            {/* ==================successful Deposit modal start=================== */}
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose} isCentered>
                <ModalOverlay bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent width="400px" height="350px">
                    <ModalCloseButton />
                    <AnimatePresence>
                        {modal2.isOpen && (
                            <motion.ModalBody
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={animationVariants}
                                borderRadius="15px"
                                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                            >
                                <Center>
                                    <VStack alignItems="center" justifyContent="space-between" py="20%">
                                        <Text color={textColor} textAlign="center" fontSize="30px"
                                            fontWeight="bold">Congratulations!!</Text>
                                        <Text color={textColor} textAlign="center" fontSize="25px" fontWeight="bold">You
                                            successfully made DEPOSIT.</Text>
                                        <Text textAlign="center" fontSize="25px" fontWeight="bold" color={textColor}>Have fun.</Text>
                                    </VStack>
                                </Center>
                            </motion.ModalBody>
                        )}
                    </AnimatePresence>
                </ModalContent>
            </Modal>
            {/* ==================successful Deposit modal ends=================== */}

            <Modal isOpen={modal3.isOpen} onClose={modal3.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Sorry!!</Text>
                                <Text color={textColor}>Please enter amount</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* ==================Loader Modal====================== */}
            <Modal isOpen={modal4.isOpen} onClose={modal4.onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>In progress.....</ModalHeader>
                    <ModalBody alignItems="center" py="20px">
                        <HStack alignItems="center" justifyContent="space-evenly">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                h="120px"
                                w="120px"
                            />
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* ==================Loader Modal ends================= */}
        </>
    )
}

export default Deposit
