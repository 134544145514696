import { Flex, VStack, FormControl, FormLabel, NumberInput, NumberInputField, Box, Button, Collapse, Container, HStack, Image, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Tooltip, Select, ModalFooter, Img } from '@chakra-ui/react';
import React from 'react'
import { useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import { IoIosMedkit } from "react-icons/io";
import dummyImage from '../components/images/pago-head.png';
import { useState, useEffect, useMemo } from 'react';
import { db } from '../components/firebase/FirebaseConfig';
import { Link, useNavigate } from 'react-router-dom';
import { Search2Icon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import Header from '../components/header/Header';
import img from "../components/images/coin.png";
import img1 from "../components/images/algoCoin.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { setMinutes, setHours } from 'date-fns';
import MyAlgoConnect from "@randlabs/myalgo-connect";
import axios from 'axios';
import algosdk, { bigIntToBytes, decodeAddress, getApplicationAddress } from 'algosdk';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where,
    update
} from "firebase/firestore";
import moment from 'moment';
import { API_URL } from '../url';
import { peraWallet } from "./WalletNew";
//import { PeraWalletConnect } from "@perawallet/connect";
import { ReactComponent as UsdcCoinImg } from '../components/images/Icons/usd-coin-usdc-logo.svg';
import { ReactComponent as NFTCoinImg } from '../components/images/Icons/nft-gold-coin.svg';
import { ReactComponent as NFTCoinImg1 } from '../components/images/Icons/algo.svg';

//const peraWallet = new PeraWalletConnect();


function HealthFactor() {
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const nftBorrowRef = collection(db, "borrow");
    const nftBorrowUSDCRef = collection(db, "borrow-usdc");
    const [borrowNFT, setBorrowNFT] = useState([]);
    const [borrowNFT_USDC, setBorrowNFT_USDC] = useState([]);
    const navigate = useNavigate();
    const [searchText, setSearchTest] = useState('');
    const [allAssets, setsetAllAssets] = useState();
    const [summaryTable, setSummaryTable] = useState();
    const summaryTableRef = collection(db, "summaryTable");
    const [borrowInfo, setBorrowInfo] = useState();
    const borrowInfoRef = collection(db, "borrow-info");
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const [healthFilter, setHealthFilter] = useState(1.5);



    let arr = [];

    const handleRedirect = (item) => {
        //console.log(item);
        navigate("/single", { state: { data: item } })
    }

    //================== health factor ===================
    //console.log(allAssets);
    //console.log(borrowInfo);
    const algoAssetInfo = allAssets?.find((data) => data.asset === "Algo");
    //console.log(algoAssetInfo);
    const FlemishAssetInfo = allAssets?.find((data) => data.asset === "Flemish");
    const UsdcAssetInfo = allAssets?.find((data) => data.asset === "USDC");
    const MingoAssetInfo = allAssets?.find((data) => data.asset === "Mingo");
    const AiGonnaAssetInfo = allAssets?.find((data) => data.asset === "AI_Gonna");
    //console.log(UsdcAssetInfo);
    // health factor starts from here
    const getItemForInterest = borrowInfo?.find((item) => item.type === "algo")
    console.log(getItemForInterest);
    const [exchange_Rate, setExchangeRate] = useState();
    //console.log(algoAssetInfo?.LT);

    // health factor ends here
    //console.log(borrowNFT);

    useEffect(() => {

        const exchangeRate = () => {

            // await axios('http://192.168.0.135:12000/swap/checkOptin?address=' + address)
            axios(`${API_URL}swap/assetPrice`)
                .then(response => {
                    if (response.status === 200) {

                        setExchangeRate(response?.data)
                        console.log(response.data);
                    }

                })
                .catch(error => {


                })
        }
        exchangeRate();

        const BorrowNFT = async () => {
            const data = await getDocs(nftBorrowRef);
            setBorrowNFT(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        BorrowNFT();

        const BorrowNFT_USDC = async () => {
            const data = await getDocs(nftBorrowUSDCRef);
            setBorrowNFT_USDC(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        BorrowNFT_USDC();

        const getAllInfoFromFirebaseSummaryTable = async () => {
            //console.log("============= Ia m in the summary table =================");
            const data = await getDocs(summaryTableRef);
            //console.log(data);
            setsetAllAssets(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getAllInfoFromFirebaseSummaryTable();

        const getBorrowInfoFromFirebase = async () => {
            const data = await getDocs(borrowInfoRef);
            setBorrowInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getBorrowInfoFromFirebase();
    }, []);
    //console.log(borrowNFT);
    const nft = borrowNFT.filter((data) => data.health_factor <= 1);
    //console.log(nft)

    const handleHealthFilterChange = (valueAsString, valueAsNumber) => {
        setHealthFilter(valueAsNumber);
    };

    const calculateHealthFactor = (data, algoAssetInfo, UsdcAssetInfo, getItemForInterest) => {
        if (!data || !algoAssetInfo || !UsdcAssetInfo || !getItemForInterest) {
            return 0;
        }

        return (
            (((Number(data.nft_price) * algoAssetInfo.LT) +
                (Number(data.supplyA_amount_interest) * UsdcAssetInfo.LT)) /
                (data.amount + getItemForInterest.initial_borrow_index)
            ).toFixed(2)
        );
    };

    const algoAndUsdcBorrowItem = borrowNFT.concat(borrowNFT_USDC);
    console.log(algoAndUsdcBorrowItem);
    const filteredData = useMemo(() => {
        return algoAndUsdcBorrowItem
            .map((data) => ({
                ...data,
                health_factor: calculateHealthFactor(data, algoAssetInfo, UsdcAssetInfo, getItemForInterest),
            }))
            //.filter((data) => data.name.toLowerCase().includes(searchText.toLowerCase()))
            .filter((data) => data.name?.includes(searchText))
            .filter((data) => data.health_factor <= healthFilter);
    }, [algoAndUsdcBorrowItem, searchText, healthFilter, algoAssetInfo, UsdcAssetInfo, getItemForInterest]);

    //const [projectData, setProjectData] = useState(nft);
    const handleChange = (e) => {
        e.preventDefault();
        setSearchTest(e.target.value);
    }

    const [show, setShow] = React.useState(false);
    console.log(filteredData);
    //const handleToggle = () => setShow(!show);
    //const [status, setStatus] = useState(false);
    const handleCollap = (index) => {
        setSelectedRowIndex((prevIndex) => (prevIndex === index ? -1 : index));
    }
    //console.log(show);
    // auction functionality start from here

    const { isOpen, onOpen, onClose } = useDisclosure();
    const modal2 = useDisclosure();
    const modal3 = useDisclosure();
    const [bidOption, setBidOption] = useState();
    const [coin, setCoin] = useState();
    const [amount, setAmount] = useState(1);
    const [appIdForAuction, setAppIDForAuction] = useState();
    const [completeAppSetup, setCompleteApp] = useState(false);
    const handleAsset = (id) => {
        //console.log(id);
        modal3.onOpen()
    }
    //============= handle minimum auction value start========================
    //============= Auction date & Time selection ==========================
    const [selected, setSelected] = useState(new Date());
    const [selected2, setSelected2] = useState(new Date());
    const [start_Time, setStartTime] = useState();
    const [end_Time, setEndTime] = useState();
    const [allAssetID, setAllAssets] = useState();
    const [numberOfAsset, setNumberOfAsset] = useState();
    const [rowData, setRowData] = useState();
    const [individualAssetID, setAssetID] = useState();
    console.log(borrowNFT_USDC);

    const convert = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2),
            hour = date.getHours();
        var m_date = [date.getFullYear(), mnth, day].join("-");
        var m_time = [hour, "00", "00"].join(":");
        var td = m_date + " " + m_time;
        return td;
    };
    const [now, setNow] = useState(new Date());
    const [future, setFuture] = useState(new Date("2030-12-31"));
    const dateChange = (e) => {
        var date = convert(e);
        setStartTime(date);

        setSelected(e);
        //setData({ ...data, date: date });
    };

    const dateChange1 = (e) => {
        var date = convert(e);
        setEndTime(date);

        setSelected2(e);
        //setData({ ...data, date: date });
    };

    //============= Auction date & Time selection end ==========================
    const [condition, setCondition] = useState(false);
    const handleMinimumValue = (e) => {

        if (e.target.value > 1) {
            setAmount(e.target.value);
            setCondition(false)

        } else {
            setAmount(e.target.value);
            setCondition(true);
        }
    }
    //============= handle minimum auction value ends here========================
    const handleAuctionModal = (data) => {
        //onClose();
        //modal2.onOpen();
        //console.log(data);
        setRowData(data);
        //console.log(data.assetID);
        modal3.onOpen();
        let val = data.assetID.filter(function (value) {
            return value != 10458941;
        });
        //console.log(val);
        setNumberOfAsset(val?.length);
        setAllAssets(val);

    }
    // auction functionality ends here
    const handleAuction = (item) => {
        console.log("=========== ha ha aha========");
        console.log(item);
        console.log(rowData);
        { rowData.borrowType == "algo" ? navigate("/auction", { state: { data: rowData, assetID: item, numberOfAsset: numberOfAsset } }) : navigate("/auction-usdc", { state: { data: rowData, assetID: item, numberOfAsset: numberOfAsset } }) }


    }
    //console.log(allAssetID);
    //console.log(rowData);
    //console.log(rowData?.id);

    const handleFinalAuction = () => {

        setTimeout(() => {
            createAuctionApp(individualAssetID);
        }, 2000);
    }

    const createAuctionApp = async (asset_id) => {
        //console.log(asset_id);

        //=======auction time for health factor below 1=============
        const start_time_for_healthFactor_below_1 = new Date().getTime();

        function addHours(numOfHours, date = new Date()) {
            // date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
            date.setTime(date.getTime() + numOfHours * 60 * 1000);
            return date;
        }

        const end_time_for_healthFactor_below_1 = addHours(2).getTime();

        //=======auction time for health factor below 1 ENDS=============

        let approval_program_compiled = undefined
        let clear_program_compiled = undefined
        let numLocalByteSlices = undefined
        let numGlobalInts = undefined
        let numGlobalByteSlices = undefined
        let numLocalInts = undefined
        const creator = algoAdd;

        //console.log("User is creating an auction that lasts 30 seconds to auction off the NFT...")

        await axios(`${API_URL}swap/auctionProgram`)
            .then(response => {

                if (response.status === 200) {

                    let data = response.data;
                    approval_program_compiled = data.approval_program_compiled
                    clear_program_compiled = data.clear_program_compiled
                    numLocalInts = data.local_schema.num_uints
                    numLocalByteSlices = data.local_schema.num_byte_slices
                    numGlobalInts = data.global_schema.num_byte_slices
                    numGlobalByteSlices = data.global_schema.num_uints


                    // //console.log(data)
                }


            })
            .catch(error => {
                console.error("Error fetching data", error);

            })

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();


        const approvalcompiledProgram = await algodClient.compile(approval_program_compiled).do();
        const clearcompiledProgram = await algodClient.compile(clear_program_compiled).do();



        const approvalProgramBytes = new Uint8Array(
            Buffer.from(approvalcompiledProgram.result, 'base64')
        );

        const clearlProgramBytes = new Uint8Array(
            Buffer.from(clearcompiledProgram.result, 'base64')
        );
        let start = Math.round((new Date(start_Time)).getTime() / 1000);
        let end = Math.round((new Date(end_Time)).getTime() / 1000);
        //console.log("=========start time=========")
        let auction_start_time = start_Time;
        let auction_end_time = end_Time;
        //console.log(auction_start_time, auction_end_time);
        //let end = start + 300;


        ////console.log(end);
        let startTime = bigIntToBytes(1655178702, 8)
        let endTime = bigIntToBytes(1655178822, 8)
        let reserve = bigIntToBytes(1000000, 8)
        let increment = bigIntToBytes(100000, 8)
        let nft = bigIntToBytes(asset_id, 8)

        let sellerAddress = decodeAddress(creator)
        let txn = algosdk.makeApplicationCreateTxnFromObject({
            from: creator,
            suggestedParams: suggested_params,
            approvalProgram: approvalProgramBytes,
            clearProgram: clearlProgramBytes,
            numLocalInts: numLocalInts,
            numLocalByteSlices: numLocalByteSlices,
            numGlobalInts: numGlobalInts,
            numGlobalByteSlices: numGlobalByteSlices,
            appArgs: [sellerAddress.publicKey, nft, startTime, endTime, reserve, increment],

            onComplete: 0
        })

        //===== pera wallet======
        const singleTxnGroups = [{ txn: txn, signers: [algoAdd] }];

        const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);

        const response = await algodClient.sendRawTransaction(signedTxn).do();

        //console.log("==================initializing setupAuctionApp==============");
        const ptx = await algosdk.waitForConfirmation(algodClient, response.txId, 4);
        // //console.log(ptx);
        let appID = ptx["application-index"];
        //console.log("=====AppID===");
        //console.log(appID);
        setAppIDForAuction(appID);
        ////console.log(nftDetails.nftId);

        //================================= update appId to firebase ======================================
        const userDoc = doc(db, "nfts", rowData.id);
        await updateDoc(userDoc, { appID: appID, type: "auction", auction_start_time: auction_start_time, auction_end_time: auction_end_time, minimumBiddingAmount: amount });
        //================================= update appId to firebase ends here ======================================

        setTimeout(() => {
            setupAuctionApp(appID, asset_id);
        }, 2000);

        //console.log(response);
        //console.log("Done. The auction app has been created")

    }

    const setupAuctionApp = async (app_id, asset_id) => {
        const creator = algoAdd;

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();
        const setup = new Uint8Array(Buffer.from('setup', 'utf8'));

        const appAddr = getApplicationAddress(app_id);

        const min_bid_amount = algosdk.algosToMicroalgos(amount);
        //console.log(min_bid_amount);

        //console.log("User is setting up and funding NFT auction...")

        let fundAppTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            from: creator,
            to: appAddr,
            amount: (min_bid_amount + 100_000 + 3 * 1_000),
            //amount :1000000,
            suggestedParams: suggested_params

        })

        let setupTxn = algosdk.makeApplicationCallTxnFromObject({
            from: creator,

            appIndex: app_id,
            appArgs: [setup],
            foreignAssets: [asset_id],
            suggestedParams: suggested_params,
            onComplete: 0
        })

        let fundNftTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
            from: creator,
            to: appAddr,
            assetIndex: asset_id,
            amount: 1,
            suggestedParams: suggested_params
        })


        const changeCred = algosdk.makeAssetConfigTxnWithSuggestedParamsFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            assetIndex: asset_id,
            manager: appAddr,
            reserve: undefined,
            freeze: undefined,

            clawback: appAddr,
            strictEmptyAddressChecking: false
        })

        const txns = [fundAppTxn, setupTxn, fundNftTxn, changeCred];

        const groupID = algosdk.assignGroupID(txns)

        //==== pera wallet============
        let multipleTxnGroups;
        multipleTxnGroups = [
            { txn: fundAppTxn, signers: [algoAdd] },
            { txn: setupTxn, signers: [algoAdd] },
            { txn: fundNftTxn, signers: [algoAdd] },
            { txn: changeCred, signers: [creator] },

        ];

        const signedTxn = await peraWallet.signTransaction([multipleTxnGroups]);
        //console.log(signedTxn);
        const response = await algodClient.sendRawTransaction([signedTxn[0], signedTxn[1], signedTxn[2], signedTxn[3]]).do();

        //=========pera wallet end========

        setCompleteApp(true);
        setTimeout(() => {
            navigate("/marketplace");
        }, 2000);

        //console.log(response);

        let accInfo = algodClient.accountInformation(creator);
        //console.log("Users Balance is :")

        //console.log(accInfo);


    }
    return (
        <>
            <Header />
            <Box py="50px">

                <Box w="100%" minH="100vh">
                    <Container maxW={'7xl'} my="80px">
                        <HStack justify="center" my="30px">
                            <IoIosMedkit size="40px" color='#6faa6b' />
                            <Text fontSize={{ base: "20px", md: "30px" }} fontWeight="600" >Health Factor Alert List</Text>
                        </HStack>

                        <Flex alignItems="center" justifyContent="space-between" w="100%" flexWrap="wrap">
                            <HStack w={["100%", "50%"]} position="relative" py="15px" mb={["20px", "0px"]}>
                                <Input
                                    type="text"
                                    value={searchText}
                                    placeholder='NFT name...'
                                    onChange={handleChange}
                                />
                                <Search2Icon position="absolute" right="10px" />
                            </HStack>
                            <Box w={["100%", "25%"]} py="15px">
                                <Box>
                                    <FormLabel mb="0" mt="-25px">Health Factor</FormLabel>
                                    <NumberInput
                                        min={0}
                                        step={0.1}
                                        value={healthFilter}
                                        onChange={handleHealthFilterChange}
                                    >
                                        <NumberInputField />
                                    </NumberInput>
                                </Box>
                            </Box>
                        </Flex>


                        <TableContainer>
                            <Table variant='simple'>

                                <Thead >
                                    <Tr bgColor={bgColor} borderRadius="50%">
                                        <Th>Loan</Th>
                                        <Th>NFT Name</Th>
                                        <Th>Floor Price</Th>
                                        <Th>Debt</Th>
                                        <Th>Health factor</Th>
                                        <Th>Liquidation</Th>
                                    </Tr>
                                </Thead>
                                <Tbody overflow="auto">

                                    {filteredData.map((data, index) => (
                                        <>
                                            <Tr onClick={() => handleCollap(index)}>
                                                <Td><HStack>
                                                    <Image src={data.image} w="50px" h="50px" />
                                                    <Text fontSize="18px"
                                                        fontWeight="600">{data.account.substring(0, 8) + "..." + data.account.substr(data.account.length - 8)}</Text>

                                                </HStack>
                                                </Td>
                                                <Td>
                                                    <HStack>
                                                        {index === selectedRowIndex ? (
                                                            <ChevronUpIcon boxSize={6}
                                                            />
                                                        ) : (
                                                            <ChevronDownIcon boxSize={6}
                                                            />
                                                        )}
                                                        <Text fontSize="18px" fontWeight="600" onClick={() => handleCollap(index === selectedRowIndex ? -1 : index)}>{data?.name}</Text>
                                                    </HStack>
                                                </Td>

                                                <Td>
                                                    <Text fontSize="18px" fontWeight="600">{data.borrowType == "usdc" ? (data.nft_price * exchange_Rate?.algo).toFixed(2) : data.nft_price}</Text>
                                                    {/* <Text fontSize="18px" fontWeight="600">{data.nft_price}</Text> */}
                                                </Td>

                                                <Td>
                                                    {/* <Text fontSize="18px" fontWeight="600">{data.borrowType == "usdc" ? ((Number(data?.amount)) * exchange_Rate?.algo).toFixed(2) : Number(data?.amount).toFixed(2)}</Text> */}


                                                    <Text fontSize="18px" fontWeight="600">{data.borrowType == "usdc" ? ((Number(data?.amount))).toFixed(2) : Number(data?.amount).toFixed(2)}</Text>


                                                    {/* <Text fontSize="18px" fontWeight="600">{data.borrowType == "usdc" ? ((Number(data?.amount) * exchange_Rate?.algo)).toFixed(2) : data.amount}</Text> */}
                                                </Td>

                                                {data?.supply_amount_by_user ?
                                                    <Td>
                                                        <Text fontSize="18px" fontWeight="600"
                                                            color={(((Number(data?.nft_price) * algoAssetInfo?.LT) + (Number(data.supplyA_amount_interest)) * UsdcAssetInfo?.LT) / (data.amount + getItemForInterest?.initial_borrow_index)).toFixed(2) > 1.5 ? "green" : "red"}>
                                                            {(((Number(data?.nft_price) * algoAssetInfo?.LT) + (Number(data.supplyA_amount_interest)) * UsdcAssetInfo?.LT) / (data.amount + getItemForInterest?.initial_borrow_index)).toFixed(2)}
                                                        </Text>
                                                    </Td>
                                                    :
                                                    <Td>
                                                        <Text fontSize="18px" fontWeight="600"
                                                            color={(((Number(data?.nft_price) * algoAssetInfo?.LT)) / (data.amount + getItemForInterest?.initial_borrow_index)).toFixed(2) > 1.5 ? "green" : "red"}>
                                                            {(((Number(data?.nft_price) * algoAssetInfo?.LT)) / (data.amount + getItemForInterest?.initial_borrow_index)).toFixed(2)}
                                                        </Text>
                                                    </Td>
                                                }

                                                <Td>
                                                    <HStack>
                                                         {
                                                            (((((Number(data?.nft_price) * algoAssetInfo?.LT)) / (data.amount + getItemForInterest?.initial_borrow_index)).toFixed(2) < 1) && (data.account != algoAdd)) ?
                                                                <Button onClick={() => handleAuctionModal(data)} bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}>Trigger</Button> :
                                                                <Button disabled={true} >Trigger</Button>
                                                        }

                                                        {/*
                                                        <Button onClick={() => handleAuctionModal(data)}
                                                            bgGradient='linear(to-r, #85BF47, #38B455)'
                                                            color="whiteAlpha.900"
                                                            _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}>Trigger</Button>
                                                        */}

                                                    </HStack>
                                                </Td>
                                            </Tr>
                                            {index === selectedRowIndex && (
                                                <Tr>
                                                    <Td colSpan="7">
                                                        <Collapse in={index === selectedRowIndex}>
                                                            <Table variant="simple" size="sm">
                                                                <Thead>
                                                                    <Tr bgColor={bgColor} borderRadius="50%">
                                                                        <Th>Asset ID</Th>
                                                                        <Th>Asset Type</Th>
                                                                        <Th>Image</Th>
                                                                        {/* Add more Th elements for additional columns here */}
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {data.assetID?.length > 0
                                                                        ? data.assetID.map((item, index) => (
                                                                            <Tr>
                                                                                <Td>{item == 0 ? "Algo" : item}</Td>
                                                                                <Td>
                                                                                    {item == "10458941" ? (
                                                                                        <UsdcCoinImg style={{
                                                                                            height: "25px",
                                                                                            width: "25px",
                                                                                            marginTop: "5px"
                                                                                        }} />
                                                                                    ) : item == 0 ? (
                                                                                        <NFTCoinImg1 style={{
                                                                                            height: "25px",
                                                                                            width: "25px",
                                                                                            marginTop: "5px"
                                                                                        }} />
                                                                                    ) : <NFTCoinImg style={{
                                                                                        height: "25px",
                                                                                        width: "25px",
                                                                                        marginTop: "5px"
                                                                                    }} />}
                                                                                </Td>
                                                                                {/* {data.borrowItemImages ? data.borrowItemImages?.map((item) => (
                                                                                    <Td><Img src={item} h="30px" w="30px" /></Td>
                                                                                )) : <Td><Img src={data.image} h="30px" w="30px" /></Td>} */}

                                                                                <Td>

                                                                                    {item == 0 ? "" : <Image src={data.borrowItemImages} w="30px" h="30px" borderRadius="50%" />}

                                                                                </Td>
                                                                                {/* Add more Td elements for additional columns here */}
                                                                            </Tr>
                                                                        ))
                                                                        : ""}
                                                                </Tbody>
                                                            </Table>
                                                        </Collapse>
                                                    </Td>
                                                </Tr>
                                            )}
                                        </>
                                    ))}
                                </Tbody>

                            </Table>
                        </TableContainer>
                    </Container>
                </Box>
            </Box>

            {/* auction property modal starts from here */}
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose}>

                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Auction for the highest bidder</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack gap={2}>
                            <HStack alignItems="center" justifyContent="space-between">
                                <Text fontSize="18px" fontWeight="600">Method</Text>
                                <Tooltip label='Sell to the highest bidder or sell with a declining price, which allows the listing to reduce in price until a buyer is found' fontSize='md'>
                                    <WarningIcon />
                                </Tooltip>
                            </HStack>
                            <Select value={bidOption ?? ""}
                                onChange={(e) => setBidOption(e.target.value)}
                            >
                                <option value='highest' py="20px">  Sell to highest bidder</option>
                                {/* <option value='decline'>Sell with declining price</option> */}

                            </Select>
                            <Text>Minimum Bid Amount</Text>
                            <HStack>
                                <Box w="35%">
                                    <Select value={coin ?? ""}
                                        onChange={(e) => setCoin(e.target.value)}
                                    >
                                        <option value='algo'> Algo</option>

                                    </Select>
                                </Box>
                                <Box w="65%">
                                    <Input type='number'
                                        name='amount'
                                        value={amount ?? ""}
                                        // onChange={(e) => setAmount(e.target.value)}
                                        onChange={(e) => handleMinimumValue(e)}
                                    />
                                </Box>

                            </HStack>
                            {condition ? <span style={{ color: 'red' }}><Text>Value must be greater then 1</Text></span> : null}
                            {/* {location.state ? location.state.data.health_factor < 1 ? "" : */}
                            <>
                                <HStack>
                                    <Text>Duration</Text>
                                </HStack>

                                <HStack>
                                    <Box border="1px solid gray" p="10px" borderRadius="8px">
                                        <DatePicker
                                            selected={selected}
                                            onChange={(date) => dateChange(date)}
                                            minDate={now}
                                            maxDate={future}
                                            showTimeSelect
                                            timeIntervals={60}
                                            minTime={setHours(setMinutes(new Date(), 0), 8)}
                                            maxTime={setHours(setMinutes(future, 45), 23)}
                                            dateFormat="MMMM d, yyyy hhaa"

                                        />
                                    </Box>

                                    <Box border="1px solid gray" p="10px" borderRadius="8px">
                                        <DatePicker
                                            selected={selected2}
                                            onChange={(date) => dateChange1(date)}
                                            minDate={now}
                                            maxDate={future}
                                            showTimeSelect
                                            timeIntervals={60}
                                            minTime={setHours(setMinutes(new Date(), 0), 8)}
                                            maxTime={setHours(setMinutes(future, 45), 23)}
                                            dateFormat="MMMM d, yyyy hhaa"
                                        />
                                    </Box>
                                </HStack>
                            </>
                            {/* : null
                            } */}




                        </Stack>
                    </ModalBody>

                    <ModalFooter>

                        <Button colorScheme='blue' mr={3} onClick={handleFinalAuction}>
                            Auction
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* auction property modal starts from here */}

            <Modal isOpen={modal3.isOpen} onClose={modal3.onClose}>

                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>List of Assets</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th>Asset ID</Th>
                                        <Th>Action</Th>

                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {allAssetID?.map((item) => (
                                        <Tr>
                                            <Td>{item == 0 ? "Algo" : item}</Td>
                                            {item == 0 ? "" :
                                                <Td><Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} mr={3} onClick={() => handleAuction(item)}>
                                                    Go to Auction
                                                </Button></Td>
                                            }


                                        </Tr>
                                    ))}


                                </Tbody>

                            </Table>
                        </TableContainer>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </>
    )
}

export default HealthFactor
