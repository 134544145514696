import {
    Button,
    ButtonGroup,
    Container,
    Divider,
    IconButton,
    Input,
    Stack,
    Box,
    Icon,
    Text,
    Switch,
    HStack,
    Image,
    Heading,
    VStack,
    border,
} from '@chakra-ui/react';
import * as React from 'react';
import { FaGithub, FaLinkedin, FaTwitter, FaTelegram, FaDiscord } from 'react-icons/fa';
import { Md3DRotation, MdLanguage } from 'react-icons/md';
// import { ReactComponent as Logo } from '../../logo.svg';
import dummyImage from '../components/images/Turtle-with-Scooter.png';
import { ArrowRightIcon, PhoneIcon, SunIcon, ArrowForwardIcon, AtSignIcon, EmailIcon, LinkIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (


        <Box bg="gray.800" color="white" zIndex="100" bottom="0">
            <Container maxW='1234' mx='auto' w='91%'>
                <Stack
                    spacing={8}
                    direction={{ base: 'column', md: 'row' }}
                    justify='space-between'
                    py={{ base: '12', md: '8' }}
                    alignItems={{ base: 'center' }}
                >
                    <HStack>
                        <Image h="120px" src={dummyImage} alt='cartoon' />
                        <Heading>Galapago</Heading>
                    </HStack>
                    <HStack>
                        <ButtonGroup variant='outline' spacing={{ base: '1', md: '6' }}>
                            {/* <Link to={{ pathname: "https://docs.galapago.app/" }} target="_blank"> */}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href='https://docs.galapago.app'>
                                <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} border="none">
                                    User Docs
                                </Button>
                            </a>
                            {/* </Link> */}
                            <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} border="none">
                                Technical Docs
                            </Button>
                            <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} border="none">
                                Audit
                            </Button>
                        </ButtonGroup>
                    </HStack>
                    <HStack spacing={6}>
                        <a target="_blank" href="mailto:info@galapago.app">
                            <IconButton
                                bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                                aria-label='Call Segun'
                                size='lg'
                                icon={<EmailIcon />}
                            />
                        </a>
                        <a target="_blank" href="https://twitter.com/ProjectGalapago">
                            <IconButton
                                bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                                aria-label='Call Segun'
                                size='lg'
                                icon={<FaTwitter />}
                            />
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/company/project-galapago/">
                            <IconButton
                                bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                                aria-label='Call Segun'
                                size='lg'

                                icon={<FaLinkedin />}
                            />
                        </a>

                        <a target="_blank" href="https://discord.gg/AzhH7wm5KK">
                        <IconButton
                            bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                            aria-label='Call Segun'
                            size='lg'
                            icon={<FaDiscord />}
                        />
                        </a>
                    </HStack>
                </Stack>
                <Stack pb="20px">
                    <Divider orientation='horizontal' color="white" />
                    <Text fontSize="12px" align="right" >©2023 Project Galapago. All Rights Reserved.</Text>
                </Stack>
            </Container>

        </Box>
    );
}
