import { createSlice } from "@reduxjs/toolkit";

export const tokenSlice = createSlice({
    name: "Token",
    initialState: {
        token1: "ALGO",
        token1_ID: 0,
        token1_image: 'assets/images/algoCoin.png',
        input1: 0,
        token2: "USDC",
        token2_ID: "10458941",
        token2_image: 'assets/images/usdc.png',
        input2: 0,
        //storing info for myAlgo & connectWallet
        myAlgoAddress: "",
        myAlgoBalance: 0,
        walletConnectAddress: "",
        walletConnectBalance: 0,
        walletConnectStatus: false,
        slippageTolerance: 1,
        liquidity_input1: 0,
        liquidity_input2: 0,
        serverName: "Testnet",
        nft_app_id: "",
        nft_asset_id: "",
        all_nfts: [],
        image_url: "",
        nft_checkbox: false,
        nft_borrow_amount: 0,
        borrow_item: undefined,
        //borrow_app_id: 167769441,
        //borrow_app_id: 181704439,
        //borrow_app_id: 185128534,
        // borrow_app_id: 191913264,
        borrow_app_id: 212483829,
        total_deposit_amount: 0,
        total_borrow_amount: 0,
        pool_amount: 0,
        repay_checkbox: false,
        repay_item: [],
        repay_amount: 0,
        repay_remaining_amount: 0,
        borrow_apr: 0,
        deposit_rate: 0,
        //==========
        borrow_rate_usdc: 0,
        deposit_rate_usdc: 0,
        //===========//
        perawallet_address: "",
        perawallet_amount: "",
        debtWithInterest: "",
        assets_from_account: [],
        assets_from_account_2: [],
        supply_amount: 0,
        supply_amount_from_user: 0,
        supply_Asset: "",
        supply_amount_algo: 0,
        supply_amount_from_user_algo: 0,
        supply_Asset_algo: "",
        available_to_borrow: undefined,
        deposit_app_id: 228816363,
    },
    reducers: {
        setToken: (state, action) => {
            state.token1 = action.payload.token1;
            state.token1_ID = action.payload.token1_ID;
            state.token1_image = action.payload.token1_image;
        },

        setInput: (state, action) => {
            state.input1 = action.payload;
        },
        setToken2: (state, action) => {
            state.token2 = action.payload.token2;
            state.token2_ID = action.payload.token2_ID;
            state.token2_image = action.payload.token2_image;
        },
        setInput2: (state, action) => {
            state.input2 = action.payload;
        },
        setMyAlgoInfo: (state, action) => {
            state.myAlgoAddress = action.payload.algoAddress;
            state.myAlgoBalance = action.payload.algoBalance;
        },
        setPeraWalletInfo: (state, action) => {
            state.perawallet_address = action.payload.perawallet_address;
            state.perawallet_amount = action.payload.perawallet_amount;
        },
        setAmountWithInterest: (state, action) => {
            state.debtWithInterest = action.payload.debtWithInterest;
        },

        setSupplyAmount: (state, action) => {
            state.supply_amount = action.payload.supply_amount;
            state.supply_amount_from_user = action.payload.supply_amount_from_user;
            state.supply_Asset = action.payload.supply_Asset;
        },

        setSupplyAmountALgo: (state, action) => {
            state.supply_amount_algo = action.payload.supply_amount;
            state.supply_amount_from_user_algo = action.payload.supply_amount_from_user;
            state.supply_Asset_algo = action.payload.supply_Asset;
        },

        setAvailableToBorrowAmount: (state, action) => {
            console.log(action.payload);
            state.available_to_borrow = action.payload;
        },

        setAssetsFromAccount: (state, action) => {
            state.assets_from_account = action.payload.assets_from_account;
            state.assets_from_account_2 = action.payload.assets_from_account_2;
        },
        setWalletConnectInfo: (state, action) => {
            state.walletConnectAddress = action.payload.walletConnectAddress;
            state.walletConnectBalance = action.payload.walletConnectBalance;
            state.walletConnectStatus = action.payload.walletConnectStatus;

        },
        setSlippageTolerance: (state, action) => {
            state.slippageTolerance = action.payload;

        },
        setLiquidity: (state, action) => {
            state.liquidity_input1 = action.payload;


        },
        setLiquidityInput2: (state, action) => {
            state.liquidity_input2 = action.payload;

        },
        setServerName: (state, action) => {
            state.serverName = action.payload;

        },
        setNftCredential: (state, action) => {
            state.nft_app_id = action.payload.nft_app_id;
            state.nft_asset_id = action.payload.nft_asset_id;
        },
        setAllNfts: (state, action) => {
            state.all_nfts = action.payload;
        },
        setImage_URL: (state, action) => {
            state.image_url = action.payload;
        },

        setNFTCheckbox: (state, action) => {
            state.nft_checkbox = action.payload;
        },

        setNFTborrowAmount: (state, action) => {
            state.nft_borrow_amount = action.payload;
        },
        setBorrowItem: (state, action) => {
            state.borrow_item = action.payload
        },

        setBorrowAppId: (state, action) => {
            state.borrow_app_id = action.payload;
        },

        setDepositAmount: (state, action) => {
            state.total_deposit_amount = action.payload;
        },
        setBorrowAmount: (state, action) => {
            state.total_borrow_amount = action.payload;
        },
        setPoolAmount: (state, action) => {
            state.pool_amount = action.payload;
        },
        setRepayCheckbox: (state, action) => {
            state.repay_checkbox = action.payload;
        },
        setRepayItem: (state, action) => {
            state.repay_item = action.payload;
        },
        setRepayAmount: (state, action) => {
            state.repay_amount = action.payload;
        },
        setRepayRemainingAmount: (state, action) => {
            state.repay_remaining_amount = action.payload;
        },
        setBorrowAPR: (state, action) => {
            state.borrow_apr = action.payload;
        },
        setDepositRate: (state, action) => {
            state.deposit_rate = action.payload;
        },
        setBorrowAPR_Usdc: (state, action) => {
            state.borrow_rate_usdc = action.payload;
        },
        setDepositRate_usdc: (state, action) => {
            state.deposit_rate_usdc = action.payload;
        },
        resetState: (state) => {
            return {
                ...state,
                //nft_app_id: "",
                //nft_asset_id: "",
                //all_nfts: [],
                image_url: "",
                nft_checkbox: false,
                //nft_borrow_amount: 0,
                //borrow_item: undefined,
                //borrow_app_id: 191913264,
                //total_deposit_amount: 0,
                //total_borrow_amount: 0,
                //pool_amount: 0,
                repay_checkbox: false,
                //repay_item: [],
                //repay_amount: 0,
                //repay_remaining_amount: 0,
                //borrow_apr: 0,
                //deposit_rate: 0,
                //perawallet_address: "",
                //perawallet_amount: "",
                //debtWithInterest: "",
                //assets_from_account: [],
                //assets_from_account_2: [],
                supply_amount: 0,
                supply_amount_from_user: 0,
                //supply_Asset: "",
                supply_amount_algo: 0,
                supply_amount_from_user_algo: 0,
            };
        },
        walletDisconnect: (state) => {
            state.myAlgoAddress = '';
            state.myAlgoBalance = 0;
            state.walletConnectStatus = false;
        },
    }
})
//console.log(tokenSlice);
