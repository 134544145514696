import { Alert, AlertIcon, Box, Button, Center, Container, Divider, Flex, Heading, HStack, IconButton, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, Select, Spinner, Stack, Switch, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useColorModeValue, useDisclosure, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import { IoIosMenu, IoIosHeartEmpty, IoIosShareAlt, IoMdRefresh, IoIosEye, IoIosWallet, IoIosSend } from "react-icons/io";
import image1 from '../components/images/Series1.jpg';
import algoCoin from '../components/images/algoCoin.png';
import usdcCoin from '../components/images/usdc.png';
import { IoIosPulse, IoMdArrowDropdown, IoMdPricetag, IoIosList, IoIosStats, IoIosCube, IoIosCreate, IoIosExpand } from "react-icons/io";
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import axios from 'axios';
import algosdk, { bigIntToBytes, decodeAddress, getApplicationAddress } from 'algosdk';
import { useNavigate } from 'react-router-dom';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where,
    update
} from "firebase/firestore";
import moment from 'moment';

import { db } from '../components/firebase/FirebaseConfig';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import DatePicker from "react-datepicker";
import { setMinutes, setHours } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { connectStorageEmulator } from 'firebase/storage';
import { API_URL } from '../url';
import { peraWallet } from "./WalletNew";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";

const animationVariants = {
    hidden: {
        opacity: 0,
        scale: 0,
        rotate: 0,
    },
    visible: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
    exit: {
        opacity: 0,
        scale: 0,
        rotate: 0,
        transition: {
            duration: 0.8,
            ease: 'backInOut',
        },
    },
};
function AuctionUSDC(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [exchange_Rate, setExchangeRate] = useState();
    const [completeAppSetup, setCompleteApp] = useState(false);
    const [bidderVal, setBidderValue] = useState();
    const [AppId, setAppId] = useState();
    const [openBox, setOpenBox] = useState(false);
    const [listing, setListing] = useState(false);
    const [offer, setOffer] = useState(false);
    const [Properties, setProperties] = useState(false);
    const [about, setAbout] = useState(false);
    const [details, setDetails] = useState(false);
    const [ItemActivity, setItemActivity] = useState(false);
    const [MoreInfo, setMoreInfo] = useState(false);
    const textColor = useColorModeValue("gray.600", "whiteAlpha.900");
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const walletAddress = useSelector(state => state.token.walletConnectAddress);
    const walletAmount = useSelector(state => state.token.walletConnectBalance);
    const walletConnectStatus = useSelector(state => state.token.walletConnectStatus);
    const [appIdForAuction, setAppIDForAuction] = useState();
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const nftCollectionRef = collection(db, "nfts");
    const [buyError, setBuyError] = useState(false);
    const [priceHistoey, setPriceHistory] = useState(false);
    const [Listing, setListingSIngle] = useState(false);
    const [offers, setOffers] = useState(false);
    const [propertiesItem, setPropertiesItems] = useState(false);
    const [aboutUsItem, setAboutUsItem] = useState(false);
    const [detailsItem, setDetailItem] = useState(false);
    const [moreItem, setMoreItem] = useState(false);
    const [biddingCoin, setBiddingCoin] = useState();
    const [biddingAmount, setBiddingAmount] = useState();
    const [borrowAmount, setBorrowAmount] = useState();
    const [item1, setItem1] = useState(false);
    const [item2, setItem2] = useState(false);
    const [item3, setItem3] = useState(false);
    const [item4, setItem4] = useState(false);
    const [allBorrow, setBorrow] = useState();
    const [allBorrowUsdc, setBorrowUsdc] = useState();
    const [status, setStatus] = useState(true);
    const [checkBidVal, setBidval] = useState(true);
    const [maxVal, setMaxVal] = useState("");
    const [minVal, setMinVal] = useState("");
    //console.log(location.state);
    // animation

    const animationVariants = {
        hidden: {
            opacity: 0,
            scale: 0,
            rotate: 0,
        },
        visible: {
            opacity: 1,
            scale: 1,
            rotate: 0,
            transition: {
                duration: 0.8,
                ease: 'backInOut',
            },
        },
        exit: {
            opacity: 0,
            scale: 0,
            rotate: 0,
            transition: {
                duration: 0.8,
                ease: 'backInOut',
            },
        },
    };
    //const nftAuctionRef = collection(db, "auctionInfo");
    //========= get Auction address=============
    const [auction_Address, setAuctionAddress] = useState([]);
    const nftAuctionAddressRef = collection(db, "auctions");
    const nftBorrowRef = collection(db, "borrow");
    const nftBorrowUSDCRef = collection(db, "borrow-usdc");
    const [nftDetails, setNftDetails] = useState();

    //========= get Auction address end=============

    //======== get close bidd info =================
    const [closeAuctionInfo, setCloseAuctionInfo] = useState([]);
    const [checkOwner, setCheckOwner] = useState(false);
    const [gotOwner, setGotOwner] = useState(false);
    const nftCloseAuctionRef = collection(db, "closeAuction");

    const nftAssetID = location.state?.assetID ? location.state.assetID : location.state?.data?.assetID;
    const nftAuction = closeAuctionInfo.filter((data) => data.assetID === nftAssetID);
    let allAuctionData = [];
    nftAuction.forEach(function (add) {
        allAuctionData.push(add);
    })

    //======== get close bidd info end =============
    console.log(process.env.REACT_APP_ADMIN_GALAPAGO_ADDRESS);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const modal2 = useDisclosure();
    const modal3 = useDisclosure();
    const modal4 = useDisclosure();
    const modal5 = useDisclosure();
    const modal6 = useDisclosure();
    const modal7 = useDisclosure();
    const modal8 = useDisclosure();
    const modal9 = useDisclosure();
    const modal10 = useDisclosure();
    const modal11 = useDisclosure();
    const modal12 = useDisclosure();
    const modal13 = useDisclosure();
    const modal14 = useDisclosure();
    const modal15 = useDisclosure();
    const modal16 = useDisclosure();
    const modal17 = useDisclosure();
    //const [successfullBuyMessage, setSuccessfullBuyMessage] = useState(false);
    const [bidOption, setBidOption] = useState();
    const [coin, setCoin] = useState();
    const [reserveCoin, setReserveCoin] = useState();
    const [amount, setAmount] = useState(1);
    const [reserveAmount, setReserveAmount] = useState();
    const [auctionClose, setAuctionClose] = useState(false);
    const [closeSuccessful, setCloseSuccessful] = useState(false);
    const handleBox = () => {
        setOpenBox(!openBox);
    }
    const handleListing = () => {
        setListing(!listing);
    }
    const handleOffer = () => {
        setOffer(!offer);
    }
    const handleProperties = () => {
        setProperties(!Properties);
    }
    const handleAbout = () => {
        setAbout(!about);
    }
    const handleDetail = () => {
        setDetails(!details);
    }
    const handleItemActivity = () => {
        setItemActivity(!ItemActivity);
    }
    const handleMoreInfo = () => {
        setMoreInfo(!MoreInfo);
    }
    const handleAuctionModal = () => {
        onClose();
        modal2.onOpen();
    }
    // ===================== get NFT from firebase===================

    const [nfts, setNfts] = useState([]);
    console.log(exchange_Rate);
    console.log(location.state.data);
    console.log(biddingAmount);
    console.log(nftDetails?.nftDetails?.minimumBidValue);
    useEffect(() => {

        const getNfts = async () => {

            const q = query(collection(db, "nfts"));
            const data = await getDocs(q);

            const q2 = query(collection(db, "nfts"), where("assetID", "==", nftAssetID));

            const data2 = await getDocs(q2);

            data2.forEach(async (doc) => {
                // doc.data() is never undefined for query doc snapshots
                let docData = doc.data();


                //   console.log(doc.id, " => ", doc.data());
                await setNftDetails({ ...nftDetails, nftDetails: docData, nftId: doc.id })




            });

            setNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));


        };

        getNfts();

        const auctionAddress = async () => {
            const data = await getDocs(nftAuctionAddressRef);
            setAuctionAddress(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        auctionAddress();


        const closeAuctionDetail = async () => {
            const data = await getDocs(nftCloseAuctionRef);
            setCloseAuctionInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        closeAuctionDetail();

        const borrow_nft = async () => {
            const data = await getDocs(nftBorrowRef);
            setBorrow(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        borrow_nft();

        //nftBorrowUSDCRef

        const borrow_nft_usdc = async () => {
            const data = await getDocs(nftBorrowUSDCRef);
            setBorrowUsdc(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        borrow_nft_usdc();

        const exchangeRate = () => {

            // await axios('http://192.168.0.135:12000/swap/checkOptin?address=' + address)
            axios(`${API_URL}swap/assetPrice`)
                .then(response => {
                    if (response.status === 200) {

                        setExchangeRate(response?.data)
                        console.log(response.data);
                    }

                })
                .catch(error => {


                })
        }
        exchangeRate();

    }, [completeAppSetup, biddingAmount]);


    console.log(allBorrowUsdc);
    console.log(nfts);
    //let asset_id = location.state.nft ? location.state.assetID : location.state.data.assetID;
    let asset_id = location.state ? location.state.assetID : null;;
    const borrowItem = allBorrow ? allBorrow.filter((data) => data.assetID === asset_id) : null;
    const target_borrow_nft = borrowItem ? borrowItem.find(user => user.assetID === asset_id) : null;
    //console.log(target_borrow_nft.health_factor);

    const handleDownloadAsset = (e) => {
        e.preventDefault();
        if (nftDetails.nftDetails.other_file != null) {
            window.open(nftDetails.nftDetails.other_file, "_blank", "sample")

        }
        else {
            window.open(nftDetails.nftDetails.url, "_blank", "sample")

        }

        // window.location.href = nftDetails.other_file;

    }

    // ==============================get NFT from firebase ends here===============


    /*
    **finding highest bidding amount for a perticuler NFT
    */
    const allBiddingAmount = auction_Address.filter((data) => data.assetID === asset_id)

    const amounts = allBiddingAmount.map(object => {
        return (object.bidderAmount);
    });

    // amounts = amounts/1000000

    console.log(nftDetails?.nftDetails);


    // const max = Math.max(...amounts) / 1000000;
    const max = Math.max(...amounts);
    console.log(max);
    //============= check for duplicate buying ===================
    const checkForCloseAuction = closeAuctionInfo.filter((data) => data.buyer_address === algoAdd && data.auctionType === "buy");

    const checkUserforBuyingNFTAgain = checkForCloseAuction.find(user => user.buyer_address === algoAdd);

    //============= check for duplicate buying ends here===========

    // //===== checking if the user bought this NFT or not
    let buyingUser = auction_Address.filter((data) => data.assetID === nftAssetID && data.bidder_address === algoAdd);
    const getResult = buyingUser.find(data => data.bidder_address === algoAdd);

    //========= check for close NFT functionality===========
    const checkAuction = nfts.find((data) => data.assetID === nftAssetID);

    console.log(checkAuction);
    const checkCloseAuction = nfts.filter((data) => data.assetID === nftAssetID && data.type === "bidded");

    const checkForCloseAuctionFunctionality = checkCloseAuction.find(userAuction => userAuction.type === "bidded");

    //========= check for close NFT functionality ends here====
    //=========== checking "Close Auction" for health_factor below 1===========
    let today = moment(new Date()).format("DD-MM-YYYY h:mm:ss");
    let clost_auction_time_healthFactor_below_1 = checkForCloseAuctionFunctionality ? checkForCloseAuctionFunctionality.auction_end_time : null;
    let close_time = moment(clost_auction_time_healthFactor_below_1).format("DD-MM-YYYY h:mm:ss");

    //=========== checking "Close Auction" for health_factor below 1 ENDSS===========
    // ===============check if the user made sell offer of this NFT ===================
    const checkSaleOffer = nfts.filter((data) => data.assetID === nftAssetID && data.type === "sale");
    const saleOfferForUser = checkSaleOffer.find(userType => userType.type === "sale");

    // ===============check if the user made sell offer of this NFT ends===================
    // pop up for sell & buy offer
    const [popup, setPopUp] = useState(false);
    const [init_escrow, setInit_escrow] = useState(false);
    const [escrow, setFund_scrow] = useState(false);
    const [sellOffer, setSellOffer] = useState(false);
    const [info, setInfo] = useState(false);
    const [opt, setOpt] = useState(false);
    const [buyOffer, setBuyOffer] = useState(false);
    const [complete, setComplete] = useState(false);
    const [auctionState, setAuctionState] = useState(false);

    //pop up for auction
    const [popupForAuction, setPopupAuction] = useState(false);
    const [auctionApp, setAuctionApp] = useState(false);

    const [bidOffer, setBidOffer] = useState(false);
    const [placeAuctionBid, setPlaceAuctionBid] = useState(false);
    const [successfulBid, setSuccessfulBid] = useState(false);
    const [switchbtn, setSwitchBtn] = useState(false);

    const handleSwich = (e) => {

        setSwitchBtn(!switchbtn);
    }
    //============= Auction date & Time selection ==========================
    const [selected, setSelected] = useState(new Date());
    const [selected2, setSelected2] = useState(new Date());
    const [start_Time, setStartTime] = useState();
    const [end_Time, setEndTime] = useState();
    const convert = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2),
            hour = date.getHours();
        var m_date = [date.getFullYear(), mnth, day].join("-");
        var m_time = [hour, "00", "00"].join(":");
        var td = m_date + " " + m_time;
        return td;
    };
    const [now, setNow] = useState(new Date());
    const [future, setFuture] = useState(new Date("2030-12-31"));
    const dateChange = (e) => {
        var date = convert(e);
        setStartTime(date);

        setSelected(e);
        //setData({ ...data, date: date });
    };

    const dateChange1 = (e) => {
        var date = convert(e);
        setEndTime(date);

        setSelected2(e);
        //setData({ ...data, date: date });
    };
    //============= Auction date & Time selection end ==========================
    //====================================functions for auction starts from here===============================
    const handleAuction = () => {

        onClose();
        modal2.onClose();

        const firebaseId = location.state.nft ? location.state.id : location.state.data.id || location.state.data.item.id;


        const asset_id = location.state ? location.state.assetID : null;


        modal7.onOpen();
        setTimeout(() => {
            createAuctionApp(asset_id, firebaseId);
        }, 2000);


    }
    const createAuctionApp = async (asset_id, firebaseId) => {

        //=======auction time for health factor below 1=============
        const start_time_for_healthFactor_below_1 = new Date().getTime();

        function addHours(numOfHours, date = new Date()) {
            // date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
            date.setTime(date.getTime() + numOfHours * 60 * 1000);
            return date;
        }

        const end_time_for_healthFactor_below_1 = addHours(24).getTime();

        //=======auction time for health factor below 1 ENDS=============

        let approval_program_compiled = undefined
        let clear_program_compiled = undefined
        let numLocalByteSlices = undefined
        let numGlobalInts = undefined
        let numGlobalByteSlices = undefined
        let numLocalInts = undefined
        const creator = algoAdd;

        await axios(`${API_URL}swap/auctionProgram`)
            .then(response => {

                if (response.status === 200) {

                    let data = response.data;
                    approval_program_compiled = data.approval_program_compiled
                    clear_program_compiled = data.clear_program_compiled
                    numLocalInts = data.local_schema.num_uints
                    numLocalByteSlices = data.local_schema.num_byte_slices
                    numGlobalInts = data.global_schema.num_byte_slices
                    numGlobalByteSlices = data.global_schema.num_uints

                    // console.log(data)
                }

            })
            .catch(error => {
                console.error("Error fetching data", error);

            })

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();

        const approvalcompiledProgram = await algodClient.compile(approval_program_compiled).do();
        const clearcompiledProgram = await algodClient.compile(clear_program_compiled).do();

        const approvalProgramBytes = new Uint8Array(
            Buffer.from(approvalcompiledProgram.result, 'base64')
        );

        const clearlProgramBytes = new Uint8Array(
            Buffer.from(clearcompiledProgram.result, 'base64')
        );
        let start = Math.round((new Date(start_Time)).getTime() / 1000);
        let end = Math.round((new Date(end_Time)).getTime() / 1000);

        let auction_start_time = start_time_for_healthFactor_below_1;
        let auction_end_time = end_time_for_healthFactor_below_1;
        let startTime = bigIntToBytes(1655178702, 8)
        let endTime = bigIntToBytes(1655178822, 8)
        let reserve = bigIntToBytes(1000000, 8)
        let increment = bigIntToBytes(100000, 8)
        let nft = bigIntToBytes(asset_id, 8)

        let sellerAddress = decodeAddress(creator)
        let txn = algosdk.makeApplicationCreateTxnFromObject({
            from: creator,
            suggestedParams: suggested_params,
            approvalProgram: approvalProgramBytes,
            clearProgram: clearlProgramBytes,
            numLocalInts: numLocalInts,
            numLocalByteSlices: numLocalByteSlices,
            numGlobalInts: numGlobalInts,
            numGlobalByteSlices: numGlobalByteSlices,
            appArgs: [sellerAddress.publicKey, nft, startTime, endTime, reserve, increment],

            onComplete: 0
        })

        //===== pera wallet======
        const singleTxnGroups = [{ txn: txn, signers: [algoAdd] }];

        const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);

        const response = await algodClient.sendRawTransaction(signedTxn).do();


        const ptx = await algosdk.waitForConfirmation(algodClient, response.txId, 4);

        let appID = ptx["application-index"];

        setAppIDForAuction(appID);

        //================================= update appId to firebase ======================================
        const userDoc = doc(db, "nfts", nftDetails?.nftId);
        await updateDoc(userDoc, { appID: appID, type: "auction", auction_start_time: auction_start_time, auction_end_time: auction_end_time, minimumBiddingAmount: amount });
        //================================= update appId to firebase ends here ======================================
        setAuctionApp(true);
        // modal7.onOpen();
        // setTimeout(() => {
        //     modal7.onClose();
        // }, 3000);

        setTimeout(() => {
            setupAuctionApp(appID, asset_id);
        }, 2000);

        console.log(
            "Done. The auction app has been created"
        )


    }

    // rounded decimal value
    const decimal_value = Number(nftDetails?.nftDetails?.minimumBidValue)?.toFixed(2);
    //const decimal_value = 0.23;
    const lastDigit1Str = String(decimal_value).slice(-1);
    let final_value_decimal = null;
    if (lastDigit1Str >= 5) {
        console.log("=============I am in if============");
        final_value_decimal = Number(decimal_value);
        console.log(final_value_decimal);
    } else {
        console.log("=============I am in else============");
        const valDecimal = Number(decimal_value)
        let itemVal = (Number(valDecimal) + Number(0.01)).toFixed(2);
        final_value_decimal = itemVal;
        console.log(final_value_decimal);
    }

    const setupAuctionApp = async (app_id, asset_id) => {
        const creator = algoAdd;

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();
        const setup = new Uint8Array(Buffer.from('setup', 'utf8'));

        const appAddr = getApplicationAddress(app_id);

        const min_bid_amount = algosdk.algosToMicroalgos(amount);

        let fundAppTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            from: creator,
            to: appAddr,
            amount: (min_bid_amount + 100_000 + 3 * 1_000),
            //amount :1000000,
            suggestedParams: suggested_params

        })

        let setupTxn = algosdk.makeApplicationCallTxnFromObject({
            from: creator,

            appIndex: app_id,
            appArgs: [setup],
            foreignAssets: [asset_id],
            suggestedParams: suggested_params,
            onComplete: 0
        })

        let fundNftTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
            from: creator,
            to: appAddr,
            assetIndex: asset_id,
            amount: 1,
            suggestedParams: suggested_params
        })


        const changeCred = algosdk.makeAssetConfigTxnWithSuggestedParamsFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            assetIndex: asset_id,
            manager: appAddr,
            reserve: undefined,
            freeze: undefined,

            clawback: appAddr,
            strictEmptyAddressChecking: false
        })

        const txns = [fundAppTxn, setupTxn, fundNftTxn, changeCred];



        const groupID = algosdk.assignGroupID(txns)
        //const myAlgoConnect = new MyAlgoConnect();


        //const signedTxn = await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()));

        //==== pera wallet============
        let multipleTxnGroups;
        multipleTxnGroups = [
            { txn: fundAppTxn, signers: [algoAdd] },
            { txn: setupTxn, signers: [algoAdd] },
            { txn: fundNftTxn, signers: [algoAdd] },
            { txn: changeCred, signers: [creator] },

        ];

        const signedTxn = await peraWallet.signTransaction([multipleTxnGroups]);

        const response = await algodClient.sendRawTransaction([signedTxn[0], signedTxn[1], signedTxn[2], signedTxn[3]]).do();

        //=========pera wallet end========

        // console.log(signedTxn[0])
        // console.log(signedTxn[1])
        // console.log(signedTxn[2])

        // console.log("-------Sending tx from frontend to network-------");
        //let response = (await algodClient.sendRawTransaction([signedTxn[0].blob, signedTxn[1].blob, signedTxn[2].blob, signedTxn[3].blob]).do());
        setCompleteApp(true);
        //modal7.onOpen();
        setTimeout(() => {
            modal7.onClose();
        }, 3000);


        modal14.onOpen();
        setTimeout(() => {
            modal14.onClose();
        }, 3000);

        // setTimeout(() => {
        //     navigate("/marketplace");
        // }, 2000);




        // console.log("-------Sending logisig tx to network-------");



        let accInfo = algodClient.accountInformation(creator);
        //placeBid(app_id, asset_id);


    }
    //====================================functions for auction ends here======================================

    // =============================================placeBid function start from here==========================
    const handleBiddingProcedure = (e) => {
        e.preventDefault();
        if (bidderVal > max) {
            if (location.state.data.minimumBidValue != undefined && biddingAmount > location.state.data.minimumBidValue) {
                modal9.onClose();

                handlePlaceBid();
            } else if (location.state.data.minimumBidValue == undefined) {
                modal9.onClose();

                handlePlaceBid();
            }
        }
        else {
            return
        }



    }

    const handlePlaceBid = async () => {

        const asset_id = location.state?.assetID ? location.state.assetID : location.state.data?.assetID;
        const appID = nftDetails?.nftDetails?.appID;
        const buyer_address = location.state.data.address;
        modal17.onOpen();
        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const accountInfo = await algodClient.accountInformation(algoAdd).do();
        const hasOptedIn = accountInfo.assets.some((asset) => asset['asset-id'] === asset_id);
        console.log(hasOptedIn);
        if (hasOptedIn) {
            //placeBid(appId, assetID, buyer_address);
            placeBid(appID, asset_id, buyer_address);
        } else {
            setTimeout(() => {
                assetOptinForAuction(appID, asset_id, buyer_address);
            }, 2000);

        }
        //assetOptinForAuction(appID, asset_id, buyer_address);

    }



    //asset optin for auction
    const assetOptinForAuction = async (appId, assetId, buyer_address) => {

        try {
            const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
            const params = await algodClient.getTransactionParams().do();
            var note = new Uint8Array([10]);


            let sender = algoAdd;
            let recipient = sender;
            // We set revocationTarget to undefined as
            // This is not a clawback operation
            let revocationTarget = undefined;
            // CloseReaminerTo is set to undefined as
            // we are not closing out an asset
            let closeRemainderTo = undefined;
            // We are sending 0 assets
            let amount = 0;
            let assetID = parseInt(assetId);
            // signing and sending "txn" allows sender to begin accepting asset specified by creator and index
            let txn = algosdk.makeAssetTransferTxnWithSuggestedParams(
                sender,
                recipient,
                closeRemainderTo,
                revocationTarget,
                amount,
                note,
                assetID,
                params);



            //const myAlgoConnect = new MyAlgoConnect();
            //const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());

            //====== pera wallet =======
            modal17.onClose();
            const singleTxnGroups = [{ txn: txn, signers: [algoAdd] }];

            const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
            const response = await algodClient.sendRawTransaction(signedTxn).do();


            setPlaceAuctionBid(true);
            // modal6.onOpen();
            // setTimeout(() => {
            //     modal6.onClose();
            // }, 3000);
            modal17.onOpen();

            setTimeout(() => {
                placeBid(appId, assetID, buyer_address);
            }, 2000);

        } catch (error) {
            toast.error("ERROR, in Asset Optin.")
        }
    }
    const placeBid = async (app_id, asset_id, seller_address) => {

        try {

            const creator = algoAdd;
            const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
            const suggested_params = await algodClient.getTransactionParams().do();
            const bid = new Uint8Array(Buffer.from('bid', 'utf8'));

            const appAddr = getApplicationAddress(app_id);
            const prevBidLeader = [];

            // alert("I am here")

            const bidAmount = algosdk.algosToMicroalgos(biddingAmount);
            // const bidAmount = 1_000_000
            // let payTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            //     from: algoAdd,
            //     to: appAddr,
            //     amount: bidAmount,
            //     suggestedParams: suggested_params
            // })

            let payTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                from: algoAdd,
                to: appAddr,
                assetIndex: 10458941,
                amount: bidAmount,
                suggestedParams: suggested_params
            })



            const allBidders = auction_Address.filter((item) => item.app_id === app_id);
            let address = [];
            address.push(creator);
            allBidders.forEach(function (add) {
                address.push(add.bidder_address);
            })
            let bidAmount2 = bigIntToBytes(4000000);
            let appCallTxn = algosdk.makeApplicationCallTxnFromObject({
                from: algoAdd,
                appIndex: app_id,
                onComplete: 0,
                appArgs: [bid, bidAmount2],
                foreignAssets: [asset_id],
                accounts: address,
                suggestedParams: suggested_params

            })


            const txns = [payTxn, appCallTxn];



            const groupID = algosdk.assignGroupID(txns)






            //const myAlgoConnect = new MyAlgoConnect();

            modal17.onClose();
            //const signedTxn = await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()));

            //===== pera wallet ==========
            let multipleTxnGroups;
            multipleTxnGroups = [
                { txn: payTxn, signers: [algoAdd] },
                { txn: appCallTxn, signers: [algoAdd] },

            ];

            const signedTxn = await peraWallet.signTransaction([multipleTxnGroups]);


            const response = await algodClient.sendRawTransaction([signedTxn[0], signedTxn[1]]).do();

            //let response = (await algodClient.sendRawTransaction([signedTxn[0].blob, signedTxn[1].blob]).do());


            //// ===============storing address of bidder working here===================
            await addDoc(nftAuctionAddressRef, { app_id: app_id, assetID: asset_id, bidder_address: algoAdd, bidderAmount: bidAmount / 1000000 });
            //auctionAddress();

            const userDoc = doc(db, "nfts", nftDetails?.nftId);
            await updateDoc(userDoc, { bidType: "bidded" });

            setSuccessfulBid(true);
            //modal6.onOpen();
            setTimeout(() => {
                modal6.onClose();
            }, 3000);

            modal10.onOpen();
            setTimeout(() => {
                modal10.onClose();
            }, 4000);

            setTimeout(() => {
                navigate("/dashboard");
            }, 2000);

            //assetOptin(app_id, asset_id, algoAdd);

        } catch (error) {
            toast.error("ERROR, in Place Bid.")
        }

    }
    // =============================================placeBid function ends here===============================

    //==================== function for close Auction starts from here===============================

    const handleCloseAuction = () => {
        console.log(location.state.data);
        const asset_id = location.state.data.assetID;
        const price = location.state.data.amount;
        //const appID = appIdForAuction;
        //const appID = location.state.data.appID;
        const appID = nftDetails?.nftDetails?.appID;
        //const firebaseID = location.state.data.id;
        const firebaseID = nftDetails?.nftId;
        console.log(asset_id);
        console.log(price);
        console.log(appID);
        console.log(firebaseID);
        modal5.onOpen();
        // setTimeout(() => {
        //     modal5.onClose();
        // }, 3000);

        setTimeout(() => {
            closeAuction(appID, asset_id, firebaseID, price);
        }, 2000);

    }
    // const closeAuction = async (app_id, asset_id, firebaseID, price) => {
    //     console.log(app_id, asset_id, firebaseID, price);
    //     // try {
    //     const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
    //     const suggested_params = await algodClient.getTransactionParams().do();

    //     const creator = algoAdd;
    //     const allBidders = auction_Address.filter((item) => item.app_id === app_id);
    //     let address = [];
    //     address.push(creator);
    //     allBidders.forEach(function (add) {
    //         address.push(add.bidder_address);
    //     })

    //     const deleteTxn = algosdk.makeApplicationDeleteTxnFromObject({
    //         from: creator,
    //         appIndex: app_id,
    //         accounts: address,
    //         foreignAssets: [asset_id[0], 10458941],
    //         suggestedParams: suggested_params
    //     })

    //     const myAlgoConnect = new MyAlgoConnect();
    //     // const signedTxn = await myAlgoConnect.signTransaction(deleteTxn.toByte());
    //     const singleTxnGroups = [{ txn: deleteTxn, signers: [algoAdd] }];

    //     const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);

    //     const buyer_address = allBidders[0].bidder_address;

    //     const response = await algodClient.sendRawTransaction(signedTxn).do();


    //     await address.forEach(async (account) => {
    //         let accountInfo = await algodClient.accountInformation(account).do();

    //         for (let idx = 0; idx < accountInfo['assets'].length; idx++) {

    //             let scrutinizedAsset = accountInfo['assets'][idx];

    //             if (scrutinizedAsset['asset-id'] == asset_id) {
    //                 // let myassetholding = JSON.stringify(scrutinizedAsset, undefined, 2);
    //                 // console.log("assetholdinginfo = " + scrutinizedAsset.amount);
    //                 //if (scrutinizedAsset.amount > 0) {


    //                 // ====== sending close-auction info to firebase ====================
    //                 addDoc(nftCloseAuctionRef, { owner_address: account, appID: app_id, assetID: asset_id, buyer_address: account, auctionType: 'sold', price: price });

    //                 // const userDoc = doc(db, "nfts", nftDetails.nftId);
    //                 // updateDoc(userDoc, { sell_status: false, owner_address: account, address: account, type: "" });
    //                 break;
    //                 // }

    //             }
    //         }

    //     });






    //     setCloseSuccessful(true);
    //     modal5.onOpen();
    //     setTimeout(() => {
    //         modal5.onClose();
    //     }, 3000);

    //     modal16.onOpen();
    //     setTimeout(() => {
    //         modal16.onClose();
    //     }, 7000);

    //     setTimeout(() => {
    //         navigate("/marketplace");
    //     }, 2000);

    //     // } catch (error) {
    //     //     toast.error("ERROR, in Close Auction.")
    //     // }
    // }

    //==================== function for close Auction ends here===============================
    //=======================functions for auction ends here=================================


    //====================================functions for buy NFT starts from here=============



    const closeAuction = async (app_id, asset_id, firebaseID, price) => {

        console.log(price);
        console.log(asset_id);
        console.log(allBorrowUsdc);
        // try {
        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();

        //===get borrow adddress start=======
        const borrowItemUsdc = allBorrowUsdc ? allBorrowUsdc.filter((data) => data.assetID[0] === asset_id[0]) : null;
        console.log(borrowItemUsdc);
        console.log(borrowItemUsdc[0].account);
        //===get borrow adddress end=======

        const creator = algoAdd;
        //const creator = "RGESZVJS7TYGUJ5AVKV5RZBG3VUNK5F3CRAD5W3MW25SPBVGLYG44DYGFA";
        const allBidders = auction_Address.filter((item) => item.app_id === app_id);
        let address = [];
        address.push(creator);
        address.push("RGESZVJS7TYGUJ5AVKV5RZBG3VUNK5F3CRAD5W3MW25SPBVGLYG44DYGFA");
        address.push(borrowItemUsdc[0].account);
        //address.push("GBEALP2LM6HP5SDTZGSJYUUHXBPLY5ID6W4VHHST36GE466BFU43THZTMU");
        allBidders.forEach(function (add) {
            address.push(add.bidder_address);
        })
        console.log(address);
        const deleteTxn = algosdk.makeApplicationDeleteTxnFromObject({
            from: creator,
            appIndex: app_id,
            accounts: address,
            foreignAssets: [parseInt(asset_id), parseInt(10458941)],
            suggestedParams: suggested_params
        })

        modal5.onClose();

        const myAlgoConnect = new MyAlgoConnect();
        // const signedTxn = await myAlgoConnect.signTransaction(deleteTxn.toByte());
        const singleTxnGroups = [{ txn: deleteTxn, signers: [algoAdd] }];

        const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);

        const buyer_address = allBidders[0].bidder_address;

        const response = await algodClient.sendRawTransaction(signedTxn).do();

        modal5.onOpen();
        await address.forEach(async (account) => {
            console.log("yo yoyooyooyoyoyoyoyo");
            let accountInfo = await algodClient.accountInformation(account).do();

            for (let idx = 0; idx < accountInfo['assets'].length; idx++) {

                let scrutinizedAsset = accountInfo['assets'][idx];

                if (scrutinizedAsset['asset-id'] == asset_id) {
                    // let myassetholding = JSON.stringify(scrutinizedAsset, undefined, 2);
                    // console.log("assetholdinginfo = " + scrutinizedAsset.amount);
                    //if (scrutinizedAsset.amount > 0) {


                    // ====== sending close-auction info to firebase ====================
                    addDoc(nftCloseAuctionRef, { owner_address: account, appID: app_id, assetID: asset_id, buyer_address: account, auctionType: 'sold', price: price });

                    // const userDoc = doc(db, "nfts", nftDetails.nftId);
                    // updateDoc(userDoc, { sell_status: false, owner_address: account, address: account, type: "" });
                    break;
                    // }

                }
            }

        });






        setCloseSuccessful(true);
        modal5.onOpen();
        setTimeout(() => {
            modal5.onClose();
        }, 3000);

        modal16.onOpen();
        setTimeout(() => {
            modal16.onClose();
        }, 7000);

        setTimeout(() => {
            navigate("/marketplace");
        }, 2000);

        // } catch (error) {
        //     toast.error("ERROR, in Close Auction.")
        // }
    }


    const handleBuyOption = () => {
        const app_id = location.state.data.appID;
        const asset_id = location.state.data.assetID;
        const buyer_address = location.state.data.address;
        const price = location.state.data.price;
        const name = location.state.data.name;
        const image = location.state.data.url;
        //setOpt(true);
        modal4.onOpen();
        // setTimeout(() => {
        //     //setPopUp(false);
        //     modal4.onClose();
        // }, 3000);


        setTimeout(() => {
            assetOptin(app_id, asset_id, buyer_address, price, name, image);
        }, 2000);

    }
    //asset optin for buying
    const assetOptin = async (appId, assetId, buyer_address, price, name, image) => {

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const params = await algodClient.getTransactionParams().do();
        var note = new Uint8Array([10]);

        let sender = algoAdd;
        let recipient = sender;
        // We set revocationTarget to undefined as
        // This is not a clawback operation
        let revocationTarget = undefined;
        // CloseReaminerTo is set to undefined as
        // we are not closing out an asset
        let closeRemainderTo = undefined;
        // We are sending 0 assets
        let amount = 0;
        let assetID = assetId;
        // signing and sending "txn" allows sender to begin accepting asset specified by creator and index
        let txn = algosdk.makeAssetTransferTxnWithSuggestedParams(
            sender,
            recipient,
            closeRemainderTo,
            revocationTarget,
            amount,
            note,
            assetID,
            params);



        // const myAlgoConnect = new MyAlgoConnect();
        // const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());

        //========= pera wallet ==============
        const singleTxnGroups = [{ txn: txn, signers: [algoAdd] }];

        const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
        const response = await algodClient.sendRawTransaction(signedTxn).do();
        setBuyOffer(true);
        // modal4.onOpen();
        // setTimeout(() => {
        //     modal4.onClose();
        // }, 3000);

        setTimeout(() => {
            buy_nft(appId, assetId, buyer_address, price, name, image);
        }, 2000);


        // // Must be signed by the account wishing to opt in to the asset
        // let rawSignedTxn = opttxn.signTxn(note);
        // let opttx = (await algodclient.sendRawTransaction(rawSignedTxn).do());
        // // Wait for confirmation
        // confirmedTxn = await algosdk.waitForConfirmation(algodclient, opttx.txId, 4);
        // //Get the completed Transaction
        // console.log("Transaction " + opttx.txId + " confirmed in round " + confirmedTxn["confirmed-round"]);

        // //You should now see the new asset listed in the account information
        // console.log("Account 3 = " + recoveredAccount3.addr);
        // await printAssetHolding(algodclient, recoveredAccount3.addr, assetID);

    }



    // buying function
    const buy_nft = async (appId, assetId, buyer_address, price, name, image) => {
        try {
            const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
            const suggested_params = await algodClient.getTransactionParams().do();
            const sender = algoAdd;
            const buy = new Uint8Array(Buffer.from('buy', 'utf8'));
            const escrowAddress = await escrow_address(appId, assetId);
            const nftPrice = algosdk.algosToMicroalgos(price);


            let lsig = new algosdk.LogicSigAccount(escrowAddress)


            const txn1 = algosdk.makeApplicationCallTxnFromObject({
                from: sender,
                suggestedParams: suggested_params,
                appIndex: appId,
                appArgs: [buy],
                foreignAssets: undefined,
                onComplete: 0
            })

            const txn2 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
                from: sender,
                suggestedParams: suggested_params,
                to: buyer_address,
                amount: nftPrice
            })



            const txn3 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                from: lsig.address(),
                suggestedParams: suggested_params,
                to: sender,
                amount: 1,
                // revocationTarget: "XQUXRUK2XUNJDT22J5BKYUADZHRWE5K5R5M23SNYINHPUYXCE42V3OO27I",
                revocationTarget: buyer_address,
                assetIndex: assetId,

            });

            const txn4 = algosdk.makeAssetConfigTxnWithSuggestedParamsFromObject({
                from: lsig.address(),
                suggestedParams: suggested_params,
                assetIndex: assetId,
                manager: sender,
                reserve: undefined,
                freeze: undefined,

                clawback: sender,
                strictEmptyAddressChecking: false
            })





            const txns = [txn1, txn2, txn3, txn4];



            const groupID = algosdk.assignGroupID(txns)

            let sign2 = algosdk.signLogicSigTransactionObject(txn3, lsig);
            let sign3 = algosdk.signLogicSigTransactionObject(txn4, lsig);

            //====peraWallet connection ==========
            const singleTxnGroups = [{ txn: txn3, signers: [algoAdd] }];

            const signedTxn3 = await peraWallet.signTransaction([singleTxnGroups]);

            const singleTxnGroups4 = [{ txn: txn4, signers: [algoAdd] }];

            const signedTxn4 = await peraWallet.signTransaction([singleTxnGroups4]);






            //const myAlgoConnect = new MyAlgoConnect();
            let txns2 = [txn1, txn2]


            //const signedTxn = await myAlgoConnect.signTransaction(txns2.map(txn => txn.toByte()));

            //===== pera wallet ============
            let multipleTxnGroups;
            multipleTxnGroups = [
                { txn: txn1, signers: [algoAdd] },
                { txn: txn2, signers: [algoAdd] },

            ];

            const signedTxn = await peraWallet.signTransaction([multipleTxnGroups]);

            //const response = await algodClient.sendRawTransaction([signedTxn[0], signedTxn[1]]).do();

            //let response = (await algodClient.sendRawTransaction([signedTxn[0], signedTxn[1], sign2.blob, sign3.blob]).do());
            let response = (await algodClient.sendRawTransaction([signedTxn[0], signedTxn[1], signedTxn3, signedTxn4]).do());
            // ============ sending buy NFT information to firebase ===========================
            await addDoc(nftCloseAuctionRef, { owner_address: buyer_address, appID: appId, assetID: assetId, buyer_address: algoAdd, auctionType: 'buy', price: price, name: name, image: image });
            // ============ sending buy NFT information to firebase ends here=================
            const userDoc = doc(db, "nfts", nftDetails?.nftId);
            updateDoc(userDoc, { sell_status: false, owner_address: algoAdd, address: algoAdd });

            setComplete(true);
            // modal4.onOpen();
            setTimeout(() => {
                modal4.onClose();
            }, 5000);
            modal8.onOpen();
            setTimeout(() => {
                modal8.onClose();
            }, 4000);
            setTimeout(() => {
                navigate("/dashboard");
            }, 2000);

        }
        catch (err) {
            setBuyError(true);
            setTimeout(() => {
                setBuyError(false);
            }, 3000);
        }

    }

    //====================================functions for buy NFT ends here===================

    // =============================================Sell NFT functions start from here==========================
    const handleSaleOption = () => {
        //call change_nft_credentials_txn function
        onClose();
        modal3.onOpen();

        const this_nft = nfts.filter((data) => data.assetID === location.state.assetID);

        const asset_id = location.state.nft ? location.state.assetID : location.state.data.assetID;
        const firebaseId = location.state.nft ? this_nft[0].id : location.state.data.id;


        setTimeout(() => {
            app_initialization(asset_id, firebaseId);

            //change_nft_credentials_txn(app_id, asset_id);


        }, 2000);


    }
    const app_initialization = async (asset_id, firebaseId) => {
        let approval_program_compiled = undefined
        let clear_program_compiled = undefined
        let numLocalByteSlices = undefined
        let numGlobalInts = undefined
        let numGlobalByteSlices = undefined
        let numLocalInts = undefined
        const creator = algoAdd;


        await axios(`${API_URL}swap/marketplace`)
            .then(response => {


                if (response.status === 200) {

                    let data = response.data;
                    approval_program_compiled = data.approval_program_compiled
                    clear_program_compiled = data.clear_program_compiled
                    numLocalInts = data.local_schema.num_uints
                    numLocalByteSlices = data.local_schema.num_byte_slices
                    numGlobalInts = data.global_schema.num_byte_slices
                    numGlobalByteSlices = data.global_schema.num_uints



                }


            })
            .catch(error => {
                console.error("Error fatching data", error);

            })

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();


        const approvalcompiledProgram = await algodClient.compile(approval_program_compiled).do();
        const clearcompiledProgram = await algodClient.compile(clear_program_compiled).do();



        const approvalProgramBytes = new Uint8Array(
            Buffer.from(approvalcompiledProgram.result, 'base64')
        );

        const clearlProgramBytes = new Uint8Array(
            Buffer.from(clearcompiledProgram.result, 'base64')
        );

        // let ownerAddresss = new Uint8Array(Buffer.from(creator, 'base64'))
        // let adminAddress  = new Uint8Array(Buffer.from(creator, 'base64'))
        let ownerAddresss = decodeAddress(creator)


        let txn = algosdk.makeApplicationCreateTxnFromObject({
            from: creator,
            suggestedParams: suggested_params,
            approvalProgram: approvalProgramBytes,
            clearProgram: clearlProgramBytes,
            numLocalInts: numLocalInts,
            numLocalByteSlices: numLocalByteSlices,
            numGlobalInts: numGlobalInts,
            numGlobalByteSlices: numGlobalByteSlices,
            appArgs: [ownerAddresss.publicKey, ownerAddresss.publicKey],
            foreignAssets: [asset_id],
            onComplete: 0
        })


        //const myAlgoConnect = new MyAlgoConnect();
        //const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());

        // pera wallet============
        const singleTxnGroups = [{ txn: txn, signers: [algoAdd] }];

        const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);

        const response = await algodClient.sendRawTransaction(signedTxn).do();


        let appID = null;
        const ptx = await algosdk.waitForConfirmation(algodClient, response.txId, 4);

        appID = ptx["application-index"];
        setAppId(appID);
        //escrow address function
        const userDoc = doc(db, "nfts", firebaseId);
        await updateDoc(userDoc, { appID: appID, type: "sale" });

        //////////////////////////////////////////////////////////////
        setPopUp(true);
        // modal3.onOpen();
        // setTimeout(() => {
        //     //setPopUp(false);
        //     modal3.onClose();
        // }, 3000);
        setTimeout(() => {
            //setPopUp(false);
            //  change_nft_credentials_txn(appID, asset_id);

            groupedTransaction(appID, asset_id);
        }, 2000);


    }

    const change_nft_credentials_txn = async (appID, assetID) => {

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();

        const creator = algoAdd;

        const escrowAddress = await escrow_address(appID, assetID);

        let lsig = new algosdk.LogicSigAccount(escrowAddress)



        const txn = algosdk.makeAssetConfigTxnWithSuggestedParamsFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            assetIndex: assetID,
            manager: lsig.address(),
            reserve: undefined,
            freeze: undefined,

            clawback: lsig.address(),
            strictEmptyAddressChecking: false
        })



        const myAlgoConnect = new MyAlgoConnect();
        const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());

        const response = await algodClient.sendRawTransaction(signedTxn.blob).do();


        setInit_escrow(true);

        setTimeout(() => {
            initialize_escrow(appID, assetID);
        }, 2000);


    }
    const initialize_escrow = async (appID, assetId) => {

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();

        const creator = algoAdd;

        const escrowAddress = await escrow_address(appID, assetId);

        let lsig = new algosdk.LogicSigAccount(escrowAddress)
        let finalAddress = decodeAddress(lsig.address())




        const initializeEscrow = new Uint8Array(Buffer.from('1', 'utf8'));

        const txn = algosdk.makeApplicationCallTxnFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            appIndex: appID,
            appArgs: [initializeEscrow, finalAddress.publicKey],
            foreignAssets: [assetId],
            onComplete: 0


        })

        const myAlgoConnect = new MyAlgoConnect();
        const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());

        const response = await algodClient.sendRawTransaction(signedTxn.blob).do();


        setFund_scrow(true);
        // modal3.onOpen()
        // setTimeout(() => {
        //     modal3.onClose();
        // }, 3000);

        setTimeout(() => {
            fund_escrow(appID, assetId);
        }, 2000);

    }

    //calling fund_escrow function
    const fund_escrow = async (appID, assetId) => {

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();
        const escrowAddress = await escrow_address(appID, assetId);

        let lsig = new algosdk.LogicSigAccount(escrowAddress)


        const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            to: lsig.address(),
            amount: 4000000
        })

        const myAlgoConnect = new MyAlgoConnect();
        const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());

        const response = await algodClient.sendRawTransaction(signedTxn.blob).do();


        setSellOffer(true);
        // modal3.onOpen()
        // setTimeout(() => {
        //     modal3.onClose();
        // }, 3000);


        setTimeout(() => {
            sell_offer(appID, assetId);
        }, 2000);



    }

    //calling sell_offer function
    const sell_offer = async (appID, assetId) => {

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();

        const creator = algoAdd;

        // const escrowAddress = await escrow_address(94243116);
        const makeSellOffer = new Uint8Array(Buffer.from('makeSellOffer', 'utf8'));
        const sell_price = new Uint8Array(1);
        sell_price[0] = 10

        const txn = algosdk.makeApplicationCallTxnFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            appIndex: appID,
            appArgs: [makeSellOffer, sell_price],
            foreignAssets: [assetId]

        })


        const myAlgoConnect = new MyAlgoConnect();
        const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());

        const response = await algodClient.sendRawTransaction(signedTxn.blob).do();
        setInfo(true);

        //modal3.onOpen();
        setTimeout(() => {
            modal3.onClose();
        }, 3000);

        const userDoc = doc(db, "nfts", nftDetails?.nftId);
        updateDoc(userDoc, { sell_status: true });


        setTimeout(() => {
            navigate("/marketplace");
        }, 2000);


        // txn = algo_txn.ApplicationCallTxn(sender=caller_address,
        //     sp=suggested_params,
        //     index=app_id,
        //     app_args=app_args,
        //     foreign_assets=foreign_assets,
        //     on_complete=on_complete)

    }
    // =============================================Sell NFT functions end here==========================


    const escrow_address = async (appID, assetId) => {

        //  const escrow_address = getApplicationAddress(app_id)
        //  return escrow_address

        // let app_id = 93977532
        // let nft_id = nft_id
        let escrow_fund_program_compiled = undefined
        let escrow_address = undefined
        await axios(`${API_URL}swap/escrow_program?app_id=` + appID + `&nft_id=` + assetId)
            .then(response => {


                if (response.status === 200) {

                    let data = response.data;

                    escrow_fund_program_compiled = data.escrow_fund_program_compiled
                    escrow_address = data.escrow_address
                    console.log(escrow_address);

                }


            })
            .catch(error => {
                console.error("Error fatching data", error);

            })

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');

        const escrowlcompiledProgram = await algodClient.compile(escrow_fund_program_compiled).do();



        const escrowProgramBytes = new Uint8Array(
            Buffer.from(escrowlcompiledProgram.result, 'base64')
        );

        return escrowProgramBytes;
        //declare fund_escrow function

    }

    const escrow_address_test = async (appID, assetId) => {

        //  const escrow_address = getApplicationAddress(app_id)
        //  return escrow_address

        // let app_id = 93977532
        // let nft_id = nft_id
        let escrow_fund_program_compiled = undefined
        let escrow_address = undefined
        await axios(`${API_URL}swap/escrow_program?app_id=` + appID + `&nft_id=` + assetId)
            .then(response => {


                if (response.status === 200) {

                    let data = response.data;

                    escrow_fund_program_compiled = data.escrow_fund_program_compiled
                    escrow_address = data.escrow_address
                    console.log(escrow_address);

                }


            })
            .catch(error => {
                console.error("Error fatching data", error);

            })

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');

        const escrowlcompiledProgram = await algodClient.compile(escrow_fund_program_compiled).do();



        const escrowProgramBytes = new Uint8Array(
            Buffer.from(escrowlcompiledProgram.result, 'base64')
        );

        //return escrowProgramBytes;
        return escrow_address;
        //declare fund_escrow function

    }

    const res = algoAdd.substring(0, 8);
    const lastFive = algoAdd.substr(algoAdd.length - 8);


    const printAssetHolding = async (accounts, assetid) => {
        // note: if you have an indexer instance available it is easier to just search accounts for an asset

        const algodClient = await new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();
        // const accounts = ["XQUXRUK2XUNJDT22J5BKYUADZHRWE5K5R5M23SNYINHPUYXCE42V3OO27I","RGESZVJS7TYGUJ5AVKV5RZBG3VUNK5F3CRAD5W3MW25SPBVGLYG44DYGFA","ZUHGIQOWQYBOUPRLFSYY6CBKN7GUFR4JIYHR3GMKZXZRUIKJGA5ZZOVHSY"];
        // const assetid = 99569009;


        await accounts.forEach(async (account) => {

            let accountInfo = await algodClient.accountInformation(account).do();

            for (let idx = 0; idx < accountInfo['assets'].length; idx++) {
                let scrutinizedAsset = accountInfo['assets'][idx];
                if (scrutinizedAsset['asset-id'] == assetid) {
                    // let myassetholding = JSON.stringify(scrutinizedAsset, undefined, 2);
                    // console.log("assetholdinginfo = " + scrutinizedAsset.amount);
                    if (scrutinizedAsset.amount > 0) {
                        return account;
                        break;
                    }

                }
            }

        });

    };



    const groupedTransaction = async (app_id, asset_id) => {

        let appID = app_id;
        let assetID = asset_id;
        const creator = algoAdd;

        const algodClient = await new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();



        const escrowAddress = await escrow_address(appID, assetID);

        let lsig = new algosdk.LogicSigAccount(escrowAddress)



        const txn2 = algosdk.makeAssetConfigTxnWithSuggestedParamsFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            assetIndex: assetID,
            manager: lsig.address(),
            reserve: undefined,
            freeze: undefined,

            clawback: lsig.address(),
            strictEmptyAddressChecking: false
        })

        let finalAddress = decodeAddress(lsig.address())




        const initializeEscrow = new Uint8Array(Buffer.from('1', 'utf8'));

        const txn3 = algosdk.makeApplicationCallTxnFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            appIndex: appID,
            appArgs: [initializeEscrow, finalAddress.publicKey],
            foreignAssets: [assetID],
            onComplete: 0


        })


        const txn4 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            to: lsig.address(),
            amount: 4000000
        })


        // const escrowAddress = await escrow_address(94243116);
        const makeSellOffer = new Uint8Array(Buffer.from('makeSellOffer', 'utf8'));
        const sell_price = new Uint8Array(1);
        sell_price[0] = 10

        const txn5 = algosdk.makeApplicationCallTxnFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            appIndex: appID,
            appArgs: [makeSellOffer, sell_price],
            foreignAssets: [assetID]

        })

        const txns = [txn2, txn3, txn4, txn5];

        const groupID = algosdk.assignGroupID(txns);

        //const myAlgoConnect = new MyAlgoConnect();
        //const signedTxn = await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()));

        //======== pera wallet ==============

        let multipleTxnGroups;
        multipleTxnGroups = [
            { txn: txn2, signers: [algoAdd] },
            { txn: txn3, signers: [algoAdd] },
            { txn: txn4, signers: [algoAdd] },
            { txn: txn5, signers: [creator] },

        ];

        const signedTxn = await peraWallet.signTransaction([multipleTxnGroups]);

        const response = await algodClient.sendRawTransaction([signedTxn[0], signedTxn[1], signedTxn[2], signedTxn[3]]).do();


        await setInit_escrow(true);
        await setFund_scrow(true);
        await setSellOffer(true);
        await setInfo(true);

        //modal3.onOpen();

        const userDoc = doc(db, "nfts", nftDetails?.nftId);
        updateDoc(userDoc, { sell_status: true });

        setTimeout(() => {
            modal3.onClose();
        }, 3000);
        modal15.onOpen();
        setTimeout(() => {
            modal15.onClose();
        }, 7000);
        setTimeout(() => {
            navigate("/marketplace");
        }, 2000);

        // setInfo(true);

        // //modal3.onOpen();
        // setTimeout(() => {
        //     modal3.onClose();
        // }, 3000);

        // const userDoc = doc(db, "nfts", nftDetails.nftId);
        // updateDoc(userDoc, { sell_status: true });


        // setTimeout(() => {
        //     navigate("/marketplace");
        // }, 2000);


    }



    // ==================borrow offer functionality start from here ======================

    const handleBorrowOffer = (e) => {
        e.preventDefault();
        modal11.onClose();
        const asset_id = location.state.nft ? location.state.assetID : location.state.data.assetID;
        const price = location.state.nft ? location.state.price : location.state.data.price;
        const name = location.state.nft ? location.state.name : location.state.data.name;
        const image = location.state.nft ? location.state.url : location.state.data.url;
        modal12.onOpen();
        app_initialization_for_borrow(asset_id, name, price, image);
    }
    const app_initialization_for_borrow = async (asset_id, name, price, image) => {
        //console.log(asset_id, appId);
        let approval_program_compiled = undefined
        let clear_program_compiled = undefined
        let numLocalByteSlices = undefined
        let numGlobalInts = undefined
        let numGlobalByteSlices = undefined
        let numLocalInts = undefined
        const creator = algoAdd;


        await axios(`${API_URL}swap/lendingProgram`)
            .then(response => {


                if (response.status === 200) {

                    let data = response.data;
                    approval_program_compiled = data.approval_program_compiled
                    clear_program_compiled = data.clear_program_compiled
                    numLocalInts = data.local_schema.num_uints
                    numLocalByteSlices = data.local_schema.num_byte_slices
                    numGlobalInts = data.global_schema.num_byte_slices
                    numGlobalByteSlices = data.global_schema.num_uints


                    // console.log(data)
                }


            })
            .catch(error => {
                console.error("Error fatching data", error);

            })

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();


        const approvalcompiledProgram = await algodClient.compile(approval_program_compiled).do();
        const clearcompiledProgram = await algodClient.compile(clear_program_compiled).do();



        const approvalProgramBytes = new Uint8Array(
            Buffer.from(approvalcompiledProgram.result, 'base64')
        );

        const clearlProgramBytes = new Uint8Array(
            Buffer.from(clearcompiledProgram.result, 'base64')
        );

        // let ownerAddresss = new Uint8Array(Buffer.from(creator, 'base64'))
        // let adminAddress  = new Uint8Array(Buffer.from(creator, 'base64'))
        let ownerAddresss = decodeAddress(creator)
        // console.log(ownerAddresss)
        let division = bigIntToBytes(1, 8);

        let txn = algosdk.makeApplicationCreateTxnFromObject({
            from: creator,
            suggestedParams: suggested_params,
            approvalProgram: approvalProgramBytes,
            clearProgram: clearlProgramBytes,
            numLocalInts: numLocalInts,
            numLocalByteSlices: numLocalByteSlices,
            numGlobalInts: numGlobalInts,
            numGlobalByteSlices: numGlobalByteSlices,
            appArgs: [ownerAddresss.publicKey, ownerAddresss.publicKey, division],
            foreignAssets: [asset_id],

            onComplete: 0
        })


        const myAlgoConnect = new MyAlgoConnect();
        const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());

        const response = await algodClient.sendRawTransaction(signedTxn.blob).do();

        let appID = null;
        const ptx = await algosdk.waitForConfirmation(algodClient, response.txId, 4);

        appID = ptx["application-index"];

        await addDoc(nftBorrowRef, { app_id: appID, assetID: asset_id, name: name, price: price, url: image, ownerAddresss: algoAdd });
        setItem1(true);
        setTimeout(() => {
            //setPopUp(false);
            initialize_escrow_for_borrow(asset_id, appID);
        }, 2000);


    }
    const initialize_escrow_for_borrow = async (asset_id, appId) => {

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();

        const creator = algoAdd;

        // const escrowAddress    = await escrow_address();

        const escrowAddress = await getApplicationAddress(appId);
        //console.log(escrowAddress);

        // const escrowProgramBytes = new Uint8Array(
        //     Buffer.from(escrowAddress, 'base64')
        // );

        // let lsig = new algosdk.LogicSigAccount(escrowProgramBytes)



        let finalAddress = decodeAddress(escrowAddress)


        const initializeEscrow = new Uint8Array(Buffer.from('initializeEscrow', 'utf8'));


        const txn = algosdk.makeApplicationCallTxnFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            appIndex: appId,
            appArgs: [initializeEscrow, finalAddress.publicKey],
            // foreignAssets:[nft_id],
            onComplete: 0


        })

        const myAlgoConnect = new MyAlgoConnect();
        const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());

        const response = await algodClient.sendRawTransaction(signedTxn.blob).do();
        setItem2(true);
        setTimeout(() => {
            //setPopUp(false);
            fund_escrow_for_borrow(asset_id, appId);
        }, 2000);

    }

    const fund_escrow_for_borrow = async (asset_id, appId) => {
        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();



        const escrowAddress = await getApplicationAddress(appId);

        const diposit = new Uint8Array(Buffer.from('diposit', 'utf8'));
        const fund = bigIntToBytes(4000000, 8)



        // const escrowProgramBytes = new Uint8Array(
        //     Buffer.from(escrowAddress, 'base64')
        // );

        // let lsig = new algosdk.LogicSigAccount(escrowProgramBytes)





        const txn1 = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            to: escrowAddress,
            amount: 4000000
        })



        const txn2 = algosdk.makeApplicationCallTxnFromObject({
            from: algoAdd,
            appIndex: appId,
            onComplete: 0,
            appArgs: [diposit, fund],
            suggestedParams: suggested_params,
            accounts: [escrowAddress]

        })

        const txns = [txn1, txn2];

        const groupID = algosdk.assignGroupID(txns)


        const myAlgoConnect = new MyAlgoConnect();

        const signedTxn = await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()));

        const response = await algodClient.sendRawTransaction([signedTxn[0].blob, signedTxn[1].blob]).do();
        setItem3(true);
        setTimeout(() => {
            //setPopUp(false);
            make_borrow_offer(asset_id, appId);
        }, 2000);




    }
    const make_borrow_offer = async (asset_id, appId) => {
        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();

        const creator = algoAdd;

        const escrowAddress = await getApplicationAddress(appId);


        // const escrowAddress = await escrow_address(94243116);
        const makeSellOffer = new Uint8Array(Buffer.from('makeBorrowOffer', 'utf8'));
        // const sell_price = bigIntToBytes(100000, 8)
        const sell_price = bigIntToBytes(borrowAmount, 8)


        //Check your balance
        let accountInfo = await algodClient.accountInformation(escrowAddress).do();



        const deposit = bigIntToBytes(accountInfo.amount, 8)




        const txn = algosdk.makeApplicationCallTxnFromObject({
            from: algoAdd,
            suggestedParams: suggested_params,
            appIndex: appId,
            appArgs: [makeSellOffer, sell_price, deposit],
            foreignAssets: [asset_id]

        })


        const myAlgoConnect = new MyAlgoConnect();
        const signedTxn = await myAlgoConnect.signTransaction(txn.toByte());

        const response = await algodClient.sendRawTransaction(signedTxn.blob).do();

        ///////////////////////
        setItem4(true);
        setTimeout(() => {
            onClose();
        }, 2000);
        modal13.onOpen();
        setTimeout(() => {
            modal13.onClose();
        }, 4000);
        setTimeout(() => {
            navigate("/borrow");
        }, 2000);


        // txn = algo_txn.ApplicationCallTxn(sender=caller_address,
        //     sp=suggested_params,
        //     index=app_id,
        //     app_args=app_args,
        //     foreign_assets=foreign_assets,
        //     on_complete=on_complete)

    }
    // ==================borrow offer functionality ends here ======================

    //================= auction for health factor below 1 start===========================
    const handleAuctionForHealthFactorBelowOne = () => {

        onClose();
        modal2.onClose();
        const firebaseId = location.state?.data?.id;

        const asset_id = location.state?.assetID;
        const escrow_app_id = location.state?.data?.escrow_app_id;
        const amount = location.state?.data?.amount;
        console.log(amount);
        modal17.onOpen();

        createAuctionAppHealthFactorBelowOne(asset_id, firebaseId, escrow_app_id, amount);
        // setTimeout(() => {

        // }, 2000);
    }

    const createAuctionAppHealthFactorBelowOne = async (asset_id, firebaseId, escrow_app_id, amount) => {
        console.log(location.state.data);
        console.log(amount);
        console.log(location.state.data.amount);
        console.log(location.state.numberOfAsset);

        const borrowItemUsdc = allBorrowUsdc ? allBorrowUsdc.filter((data) => data.assetID[0] === asset_id) : null;
        console.log(borrowItemUsdc[0].account);


        // const findAuctionAddress = nfts ? nfts.filter((data) => data.assetID === asset_id) : null;
        // console.log(findAuctionAddress);
        // console.log(findAuctionAddress?.auctionOwner);
        // try {

        // const minimumBidValue = location.state ? location.state.data ? (Number(location.state.data.repay_remaining_amount + (location.state.data.repay_remaining_amount * 0.5)) + Number(amount)) : null : null;
        const minimumBidValue = location.state.data ? ((location.state.data.amount) / (location.state.numberOfAsset)) : null

        console.log(minimumBidValue);
        //=======auction time for health factor below 1=============
        const start_time_for_healthFactor_below_1 = new Date().getTime();

        function addHours(numOfHours, date = new Date()) {
            // date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
            date.setTime(date.getTime() + numOfHours * 60 * 1000);
            return date;
        }

        //const end_time_for_healthFactor_below_1 = addHours(24).getTime();
        const end_time_for_healthFactor_below_1 = addHours(24).getTime();


        // get escrowAddress
        const escrowAddress = await escrow_address_test(process.env.REACT_APP_USDC_POOL_ID, 10458941);
        console.log(escrowAddress);


        //const escrowAddress = await escrow_address(appId, assetId);


        //let lsig = new algosdk.LogicSigAccount(escrowAddress)
        // let lsig = new algosdk.LogicSigAccount(escrowAddress)
        // let finalAddress = decodeAddress(lsig.address())
        // console.log(finalAddress);

        //=======auction time for health factor below 1 ENDS=============

        let approval_program_compiled = undefined
        let clear_program_compiled = undefined
        let numLocalByteSlices = undefined
        let numGlobalInts = undefined
        let numGlobalByteSlices = undefined
        let numLocalInts = undefined
        const creator = algoAdd;

        await axios(`${API_URL}swap/auctionUSDCProgram`)
            .then(response => {


                if (response.status === 200) {

                    let data = response.data;
                    approval_program_compiled = data.approval_program_compiled
                    clear_program_compiled = data.clear_program_compiled
                    numLocalInts = data.local_schema.num_uints
                    numLocalByteSlices = data.local_schema.num_byte_slices
                    numGlobalInts = data.global_schema.num_byte_slices
                    numGlobalByteSlices = data.global_schema.num_uints

                    // console.log(data)
                }

            })
            .catch(error => {
                console.error("Error fetching data", error);

            })

        const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
        const suggested_params = await algodClient.getTransactionParams().do();
        const approvalcompiledProgram = await algodClient.compile(approval_program_compiled).do();
        const clearcompiledProgram = await algodClient.compile(clear_program_compiled).do();
        const approvalProgramBytes = new Uint8Array(
            Buffer.from(approvalcompiledProgram.result, 'base64')
        );

        const clearlProgramBytes = new Uint8Array(
            Buffer.from(clearcompiledProgram.result, 'base64')
        );


        let start = Math.round((new Date(start_Time)).getTime() / 1000);
        let end = Math.round((new Date(end_Time)).getTime() / 1000);
        let auction_start_time = start_time_for_healthFactor_below_1;
        let auction_end_time = end_time_for_healthFactor_below_1;
        //let auction_end_time = 0;
        let startTime = bigIntToBytes(auction_start_time, 8)
        let endTime = bigIntToBytes(auction_end_time, 8)
        let reserve = bigIntToBytes(1000000, 8)
        let increment = bigIntToBytes(100000, 8)
        let debt_amount = algosdk.algosToMicroalgos(amount);
        let baseamount = bigIntToBytes(debt_amount, 8);
        console.log(baseamount);
        console.log(escrowAddress);
        //const pooladdress = "5QWQ3DPBFLTOT64LVXBRL2SDL7ESJD2WTRURRGXK5GHPIOOJQCENC3AOUA";

        let pooladdress = decodeAddress("RGESZVJS7TYGUJ5AVKV5RZBG3VUNK5F3CRAD5W3MW25SPBVGLYG44DYGFA");
        let borrowerAddress = decodeAddress(borrowItemUsdc[0]?.account);
        //let borrowerAddress = decodeAddress(findAuctionAddress?.auctionOwner);



        let nft = bigIntToBytes(asset_id, 8)

        let sellerAddress = decodeAddress(creator)
        let txn = algosdk.makeApplicationCreateTxnFromObject({
            from: creator,
            suggestedParams: suggested_params,
            approvalProgram: approvalProgramBytes,
            clearProgram: clearlProgramBytes,
            numLocalInts: numLocalInts,
            numLocalByteSlices: numLocalByteSlices,
            numGlobalInts: numGlobalInts,
            numGlobalByteSlices: numGlobalByteSlices,
            appArgs: [sellerAddress.publicKey, nft, startTime, endTime, reserve, increment, baseamount, pooladdress.publicKey, borrowerAddress.publicKey],
            foreignAssets: [asset_id, 10458941],


            onComplete: 0
        })
        console.log("=========hello ===============");
        //modal7.onClose();
        modal17.onClose();
        const singleTxnGroups = [{ txn: txn, signers: [algoAdd] }];

        const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);

        const response = await algodClient.sendRawTransaction(signedTxn).do();

        modal17.onOpen();
        const ptx = await algosdk.waitForConfirmation(algodClient, response.txId, 4);

        let appID = ptx["application-index"];

        setAppIDForAuction(appID);
        const appAddr = await getApplicationAddress(appID);
        console.log("=========hello 22222===============");
        let fundAppTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            from: creator,
            to: appAddr,
            amount: (1000000),
            //amount: (amount * 1000000),
            suggestedParams: suggested_params

        })

        const txns = [fundAppTxn];

        const groupID = algosdk.assignGroupID(txns)
        console.log("=========hello 33333===============");
        // ========= pera wallet start ==============
        let multipleTxnGroups;
        multipleTxnGroups = [

            { txn: fundAppTxn, signers: [algoAdd] }

        ];

        modal17.onClose();
        const signedTxnPay = await peraWallet.signTransaction([multipleTxnGroups]);

        const responsePay = await algodClient.sendRawTransaction(signedTxnPay).do();

        console.log("=========hello 4444 ===============");

        //================================= update appId to firebase ======================================
        const userDoc = doc(db, "nfts", nftDetails?.nftId);
        await updateDoc(userDoc, { appID: appID, type: "auction", auction_start_time: auction_start_time, auction_end_time: auction_end_time, minimumBidValue: minimumBidValue, auctionOwner: algoAdd, auctionType: "usdc" });
        //================================= update appId to firebase ends here ======================================
        setAuctionApp(true);

        setTimeout(() => {
            setupAuctionAppHealthFactorBelowOne(appID, asset_id, escrow_app_id, amount);
        }, 2000);

        // } catch (error) {
        //     toast.error("ERROR, in Create Auction App.")
        // }


    }

    const setupAuctionAppHealthFactorBelowOne = async (app_id, asset_id, escrow_app_id, amount) => {

        try {
            const creator = algoAdd;

            const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');
            const suggested_params = await algodClient.getTransactionParams().do();
            const setup = new Uint8Array(Buffer.from('setup', 'utf8'));

            const appAddr = getApplicationAddress(app_id);

            const min_bid_amount = algosdk.algosToMicroalgos(amount);
            console.log(min_bid_amount);

            //////////////////////////////////////////////////////////////////

            let appId2 = escrow_app_id
            const escrowAddress = await getApplicationAddress(appId2);

            const token = bigIntToBytes(asset_id, 8)
            const account = getApplicationAddress(app_id)

            const transfer_token_auction = new Uint8Array(Buffer.from('transfer_token_auction', 'utf8'));
            const accounts = new Uint8Array(Buffer.from(account, 'utf8'));

            //alert(account);

            const fapp_id = bigIntToBytes(app_id, 8)


            // let fundAppTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            //     from: creator,
            //     to: appAddr,
            //     amount: (min_bid_amount + 100_000 + 3 * 1_000),
            //     suggestedParams: suggested_params

            // })

            let fundAppTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
                from: creator,
                to: appAddr,
                amount: min_bid_amount,
                //amount: (min_bid_amount + 100_000 + 3 * 1_000),
                //amount: (amount + 100_000 + 3 * 1_000),
                suggestedParams: suggested_params,
                assetIndex: 10458941,
                revocationTarget: undefined,
                foreignAssets: [10458941]


            })

            let setupTxn = algosdk.makeApplicationCallTxnFromObject({
                from: creator,

                appIndex: app_id,
                appArgs: [setup],
                foreignAssets: [asset_id, 10458941],
                suggestedParams: suggested_params,
                onComplete: 0
            })


            const txn2 = algosdk.makeApplicationCallTxnFromObject({
                from: algoAdd,
                suggestedParams: suggested_params,
                appIndex: appId2,
                accounts: [account],
                appArgs: [transfer_token_auction, token, accounts],
                foreignAssets: [asset_id],

            })




            // let fundNftTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
            //     from: creator,
            //     to: appAddr,
            //     assetIndex: asset_id,
            //     amount: 1,
            //     suggestedParams: suggested_params
            // })

            // const changeCred = algosdk.makeAssetConfigTxnWithSuggestedParamsFromObject({
            //     from: algoAdd,
            //     suggestedParams: suggested_params,
            //     assetIndex: asset_id,
            //     manager: appAddr,
            //     reserve: undefined,
            //     freeze: undefined,

            //     clawback: appAddr,
            //     strictEmptyAddressChecking: false
            // })

            const txns = [setupTxn, fundAppTxn, txn2];
            // const txns = [txn2,fundAppTxn, setupTxn, fundNftTxn, changeCred];
            // const txns = [txn2,fundAppTxn, setupTxn, fundNftTxn];
            // const txns = [txn2,fundAppTxn];
            const groupID = algosdk.assignGroupID(txns)

            // ========= pera wallet start ==============
            let multipleTxnGroups;
            multipleTxnGroups = [

                { txn: setupTxn, signers: [algoAdd] },
                { txn: fundAppTxn, signers: [algoAdd] },

                { txn: txn2, signers: [algoAdd] },


                // { txn: fundNftTxn, signers: [algoAdd] },
                // { txn: changeCred, signers: [creator] },

            ];

            modal17.onClose();
            const signedTxn = await peraWallet.signTransaction([multipleTxnGroups]);

            const response = await algodClient.sendRawTransaction(signedTxn).do();

            setCompleteApp(true);

            modal14.onOpen();
            setTimeout(() => {
                modal14.onClose();
            }, 3000);
            //modal7.onOpen();
            // modal17.onOpen();
            // setTimeout(() => {
            //     modal17.onClose();
            // }, 3000);






            let accInfo = algodClient.accountInformation(creator);

        } catch (error) {
            toast.error("ERROR, in Setup Auction.")
        }

    }
    //================= auction for health factor below 1 end===========================
    //============= handle minimum auction value start========================
    const [condition, setCondition] = useState(false);
    const handleMinimumValue = (e) => {

        if (e.target.value > 1) {
            setAmount(e.target.value);
            setCondition(false)

        } else {
            setAmount(e.target.value);
            setCondition(true);
        }
    }
    //============= handle minimum auction value ends here========================
    let bidValCheck = undefined;
    const handleBidAmount = (e) => {
        const bidValue = parseFloat(e.target.value);
        setBidderValue(bidValue);
        setBiddingAmount(bidValue);

        if (max) { // subsequent bids
            if (bidValue <= max) {
                setMaxVal(false);
                setMinVal(true);
            } else {
                setMaxVal(true);
                setMinVal(true);
            }
        } else { // first bid
            if (bidValue < nftDetails?.nftDetails?.minimumBidValue) {
                setMinVal(false);
                setMaxVal(true);
            } else {
                setMinVal(true);
                setMaxVal(true);
            }
        }
    }


    return (
        <>
            {walletConnectStatus ? <DesktopNav name={walletAddress} amount={walletAmount} /> : <DesktopNav name={algoAdd} amount={algoBalance} />}
            <Box minH="100vh" bgColor={bgColor}>

                <Container maxW={'7xl'} paddingTop="100px">
                    <Stack gap={5} justifyContent="space-between" direction={{ base: 'column', md: 'row' }} >
                        <VStack w={{ base: "100%", md: "40%" }} borderRadius="12px">
                            <HStack justifyContent="space-between" w="100%" p="20px" bgColor={bgColor} marginBottom="-10px" borderTopLeftRadius="12px" borderTopRightRadius="12px" border="1px solid gray" borderColor="gray.300">
                                <IoIosMenu />
                                <IoIosHeartEmpty />
                            </HStack>

                            <Image src={location.state.nft ? location.state.url : location.state.data.url || location.state.data.image} w="100%" h="520px" paddingTop="0px" borderBottomRightRadius="12px" borderBottomLeftRadius="12px" objectFit="cover" />
                            <Box w="100%" border="1px solid gray" borderRadius="8px" borderColor="gray.300" p="20px">
                                <HStack gap={3}>
                                    <IoIosStats size="32px" />
                                    <Text fontSize={{ base: "14px", md: "21px" }} fontWeight="bold">Description</Text>
                                </HStack>


                                <TableContainer width="100%" >
                                    <Table variant='simple' size="lg" >

                                        <Thead>
                                            <Tr>


                                                <Th>Owner</Th>
                                                <Th>Buyer</Th>

                                            </Tr>
                                        </Thead>
                                        <Tbody>

                                            {allAuctionData.length != 0 ? allAuctionData.map((data) => (

                                                <Tr key={data.id}>


                                                    <Td >{data.owner_address.substring(0, 8) + "..." + data.owner_address.substr(data.owner_address.length - 8)}</Td>
                                                    <Td >{data.buyer_address.substring(0, 8) + "..." + data.buyer_address.substr(data.buyer_address.length - 8)}</Td>

                                                </Tr>

                                            )) :
                                                <Tr >


                                                    <Td >Null</Td>
                                                    <Td >Null</Td>

                                                </Tr>
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                                <Text fontSize="18px">
                                    {location.state.nft ? location.state.description : location.state.data.description}
                                </Text>
                                <Text fontSize="18px">
                                    {location.state.nft ? location.state.link : location.state.data.link}
                                </Text>

                                {/* Properties component starts*/}
                                <VStack borderRadius="8px" border="1px solid gray" borderColor="gray.300" p="15px" marginBottom="20px" marginTop="20px">
                                    <Flex justifyContent="space-between" width="100%" >
                                        <HStack>
                                            <IoIosCube size="25px" />
                                            <Text onClick={handleProperties} cursor="pointer" fontSize="18px" fontWeight="500">Properties</Text>
                                        </HStack>
                                        <HStack><IoMdArrowDropdown onClick={handleProperties} cursor="pointer" size="25px" /></HStack>

                                    </Flex>
                                    {Properties ? <>
                                        <HStack w="100%" alignItems="left" paddingTop="15px" justifyContent="space-between">
                                            {propertiesItem ? <>
                                                <Box w="33%" h="90px" border="1px solid gray" borderRadius="8px" textAlign="center" p="10px" bgColor="blue.200" m="auto">
                                                    <Text fontSize="12px">Background</Text>
                                                    <Text fontSize="12px">Pink</Text>
                                                    <Text fontSize="12px">6% have this trade</Text>
                                                </Box>
                                                <Box w="33%" h="90px" border="1px solid gray" borderRadius="8px" textAlign="center" p="10px" bgColor="blue.200" m="auto">
                                                    <Text fontSize="12px">Background</Text>
                                                    <Text fontSize="12px">Pink</Text>
                                                    <Text fontSize="12px">6% have this trade</Text>
                                                </Box>
                                                <Box w="33%" h="90px" border="1px solid gray" borderRadius="8px" textAlign="center" p="10px" bgColor="blue.200" m="auto">
                                                    <Text fontSize="12px">Background</Text>
                                                    <Text fontSize="12px">Pink</Text>
                                                    <Text fontSize="12px">6% have this trade</Text>
                                                </Box>
                                            </> : <><Text>There are currently no details.</Text></>}

                                        </HStack>
                                    </> : <></>}

                                </VStack>
                                {/* Properties component ends here*/}
                                {/* about us component starts*/}
                                <VStack borderRadius="8px" border="1px solid gray" borderColor="gray.300" p="15px" marginBottom="20px">
                                    <Flex justifyContent="space-between" width="100%" >
                                        <HStack>
                                            <IoIosCreate size="25px" />
                                            <Text onClick={handleAbout} cursor="pointer" fontSize="18px" fontWeight="500">About Us</Text>
                                        </HStack>
                                        <HStack><IoMdArrowDropdown onClick={handleAbout} cursor="pointer" size="25px" /></HStack>

                                    </Flex>
                                    {about ? <>
                                        <HStack w="100%" alignItems="left" paddingTop="15px" justifyContent="space-between">
                                            {aboutUsItem ? <>
                                                <Box w="50%" _focus={{ boxShadow: "lg" }} >
                                                    <Select >
                                                        <option value='option1' >Last 7 Days</option>
                                                        <option value='option2'>Last 14 Days</option>
                                                        <option value='option3'>Last 30 Days</option>
                                                    </Select>
                                                </Box>
                                                <Box w="50%" border="1px solid gray" borderColor="gray.300" borderRadius="8px">
                                                    <Text p="10px" >Chart</Text>
                                                </Box>
                                            </> : <><Text>There are currently no details.</Text></>}

                                        </HStack>
                                    </> : <></>}

                                </VStack>
                                {/* about us component ends here*/}
                                {/* details component starts*/}
                                <VStack borderRadius="8px" border="1px solid gray" borderColor="gray.300" p="15px" marginBottom="20px">
                                    <Flex justifyContent="space-between" width="100%" >
                                        <HStack>
                                            <IoIosExpand size="25px" />
                                            <Text onClick={handleDetail} cursor="pointer" fontSize="18px" fontWeight="500">Details</Text>
                                        </HStack>
                                        <HStack><IoMdArrowDropdown onClick={handleDetail} cursor="pointer" size="25px" /></HStack>

                                    </Flex>
                                    {details ? <>
                                        <HStack w="100%" alignItems="left" paddingTop="15px" justifyContent="space-between">
                                            {detailsItem ? <>
                                                <Box w="50%" _focus={{ boxShadow: "lg" }} >
                                                    <Select >
                                                        <option value='option1' >Last 7 Days</option>
                                                        <option value='option2'>Last 14 Days</option>
                                                        <option value='option3'>Last 30 Days</option>
                                                    </Select>
                                                </Box>
                                                <Box w="50%" border="1px solid gray" borderColor="gray.300" borderRadius="8px">
                                                    <Text p="10px" >Chart</Text>
                                                </Box>
                                            </> : <><Text>There are currently no details.</Text></>}

                                        </HStack>
                                    </> : <></>}

                                </VStack>
                                {/* details component ends here*/}
                            </Box>
                        </VStack>
                        <VStack w={{ base: "100%", md: "60%" }} alignItems="left">
                            <HStack justifyContent="space-between" w="100%" p="20px">
                                <HStack>
                                    <Text fontSize="22px" fontWeight="700">{location.state.nft ? location.state.name : location.state.data.name}</Text>

                                    {/* <Text fontSize="22px" fontWeight="700">{location.state.nft ? location.state.type : location.state.data.type}</Text> */}
                                </HStack>
                                <Box>
                                    <IconButton icon={<IoMdRefresh />} marginRight="10px" />
                                    <IconButton icon={<IoIosShareAlt />} />
                                </Box>
                            </HStack>
                            <Text fontSize="30px" fontWeight="bold" textAlign="left" paddingLeft="20px">{location.state.nft ? location.state.unit_name : location.state.data.unit_name}</Text>
                            <HStack p="20px">
                                <Text fontSize={{ base: "12px", md: "16px" }}>Owned by {location.state.nft ? location.state.owner : location.state.data.owner}</Text>
                                <IoIosEye size="25px" />
                                <Text fontSize={{ base: "12px", md: "16px" }}>22 views</Text>
                                <IoIosHeartEmpty size="25px" />
                                <Text fontSize={{ base: "12px", md: "16px" }}>1 favourite</Text>
                            </HStack>
                            <VStack alignItems="left" p="20px" border="1px solid gray" borderRadius="12px" bgColor={bgColor} borderColor="whiteAlpha.500">
                                <Text>Total Debt</Text>

                                <HStack>
                                    <Image src={usdcCoin} w="32px" h="32px" />
                                    {/* <Text fontSize={{ base: "21px", md: "25px" }} fontWeight="bold">{location.state.data.price}</Text> */}
                                    <Text fontSize={{ base: "21px", md: "25px" }} fontWeight="bold">{location.state.data.amount ? Number(location.state.data.amount).toFixed(2) : Number(location.state.data.minimumBidValue).toFixed(2)}</Text>
                                    {/* <Text fontSize={{ base: "21px", md: "25px" }} fontWeight="bold">{location.state.data.borrowType == "usdc" ? ((Number(location.state.data?.amount)) * exchange_Rate?.algo).toFixed(2) : Number(location.state.data?.amount).toFixed(2)}</Text> */}
                                </HStack>
                                <Stack direction={['column', 'row']}>



                                    <>

                                        {/* <Button leftIcon={<IoIosWallet />} size="lg" colorScheme='teal' variant='solid' w="150px" onClick={onOpen}>
                                            List for Sale
                                        </Button> */}
                                        {checkAuction ? checkAuction.type == "auction" ?
                                            <Button leftIcon={<IoIosSend />} size="lg" colorScheme='teal' variant='outline' w="150px" onClick={handleAuctionModal} isDisabled={true} fontSize={{ base: "14px", md: "18px" }}>
                                                Make Auction   </Button>
                                            :

                                            <Button leftIcon={<IoIosSend />} size="lg" colorScheme='teal' variant='outline' w="150px" onClick={handleAuctionModal} fontSize={{ base: "14px", md: "18px" }}>
                                                Make Auction
                                            </Button> : ""

                                        }


                                        {checkAuction && new Date().getTime() > checkAuction?.auction_end_time && algoAdd === process.env.REACT_APP_ADMIN_GALAPAGO_ADDRESS ?
                                            <Button leftIcon={<IoIosWallet />} size="lg" colorScheme='teal' variant='solid' w="150px" onClick={handleCloseAuction} fontSize={{ base: "14px", md: "18px" }}>
                                                Close Auction
                                            </Button>
                                            :
                                            // <Button leftIcon={<IoIosWallet />} size="lg" colorScheme='teal' variant='solid' w="150px" onClick={handleCloseAuction} isDisabled={true} fontSize={{ base: "14px", md: "18px" }} >
                                            //     Close Auction
                                            // </Button>

                                            <Button leftIcon={<IoIosWallet />} size="lg" colorScheme='teal' variant='solid' w="150px" onClick={handleCloseAuction} fontSize={{ base: "14px", md: "18px" }} >
                                                Close Auction
                                            </Button>
                                        }





                                    </>
                                    :

                                    <>



                                        {checkAuction ? checkAuction.type == "auction" && checkAuction.address != algoAdd ?

                                            <Button leftIcon={<IoIosSend />} size="lg" colorScheme='teal' variant='outline' w="150px" onClick={modal9.onOpen} fontSize={{ base: "14px", md: "18px" }}>
                                                Place Bid
                                            </Button>
                                            :


                                            // <Button leftIcon={<IoIosSend />} size="lg" colorScheme='teal' variant='outline' w="150px" onClick={handlePlaceBid} >
                                            //     Place Bid
                                            // </Button>

                                            <Button leftIcon={<IoIosSend />} size="lg" colorScheme='teal' variant='outline' w="150px" onClick={modal9.onOpen} isDisabled={true} fontSize={{ base: "14px", md: "18px" }}>
                                                Place Bid
                                            </Button>
                                            : ""

                                            // <Button leftIcon={<IoIosSend />} size="lg" colorScheme='teal' variant='outline' w="150px" onClick={modal9.onOpen} fontSize={{ base: "14px", md: "18px" }}>
                                            //     Place Bid
                                            // </Button>
                                            // : ""

                                        }

                                    </>






                                </Stack>
                                {buyError ?
                                    <Alert status='error'>
                                        <AlertIcon />
                                        Not enough fund.
                                    </Alert> : null
                                }

                            </VStack>
                            {/* Price history component starts*/}
                            <VStack borderRadius="8px" border="1px solid gray" borderColor="gray.300" p="15px">
                                <Flex justifyContent="space-between" width="100%" >
                                    <HStack>
                                        <IoIosPulse size="25px" />
                                        <Text onClick={handleBox} cursor="pointer" fontSize="18px" fontWeight="700">Price History</Text>
                                    </HStack>
                                    <HStack><IoMdArrowDropdown onClick={handleBox} cursor="pointer" size="25px" /></HStack>

                                </Flex>
                                {openBox ? <>
                                    <HStack w="100%" alignItems="left" paddingTop="15px" justifyContent="space-between">
                                        <Box w="50%" _focus={{ boxShadow: "lg" }} >
                                            {priceHistoey ? <>
                                                <Select >
                                                    <option value='option1' >Last 7 Days</option>
                                                    <option value='option2'>Last 14 Days</option>
                                                    <option value='option3'>Last 30 Days</option>
                                                </Select>
                                            </> : <><Text>There are currently no details.</Text></>}

                                        </Box>
                                        {/* <Box w="50%" border="1px solid gray" borderColor="gray.300" borderRadius="8px">
                                            <Text p="10px" >Chart</Text>
                                        </Box> */}
                                    </HStack>
                                </> : <></>}

                            </VStack>
                            {/* Price history component ends here*/}
                            {/* Listing component starts*/}
                            <VStack borderRadius="8px" border="1px solid gray" borderColor="gray.300" p="15px" mt="30px">
                                <Flex justifyContent="space-between" width="100%" >
                                    <HStack>
                                        <IoMdPricetag size="25px" />
                                        <Text onClick={handleListing} cursor="pointer" fontSize="18px" fontWeight="700">Listings</Text>
                                    </HStack>
                                    <HStack><IoMdArrowDropdown onClick={handleListing} cursor="pointer" size="25px" /></HStack>

                                </Flex>
                                {listing ? <>
                                    <HStack w="100%" alignItems="left" paddingTop="15px" justifyContent="space-between">
                                        <Box w="50%" _focus={{ boxShadow: "lg" }} >
                                            {Listing ? <>
                                                <Select >
                                                    <option value='option1' >Last 7 Days</option>
                                                    <option value='option2'>Last 14 Days</option>
                                                    <option value='option3'>Last 30 Days</option>
                                                </Select>
                                            </> : <><Text>There are currently no details.</Text></>}
                                        </Box>
                                        {/* <Box w="50%" border="1px solid gray" borderColor="gray.300" borderRadius="8px">
                                            <Text p="10px" >Chart</Text>
                                        </Box> */}
                                    </HStack>
                                </> : <></>}

                            </VStack>
                            {/* Listing component ends here*/}
                            {/* Offers component starts*/}
                            <VStack borderRadius="8px" border="1px solid gray" borderColor="gray.300" p="15px" mt="30px">
                                <Flex justifyContent="space-between" width="100%" >
                                    <HStack>
                                        <IoIosList size="25px" />
                                        <Text onClick={handleOffer} cursor="pointer" fontSize="18px" fontWeight="700">Offers</Text>
                                    </HStack>
                                    <HStack><IoMdArrowDropdown onClick={handleOffer} cursor="pointer" size="25px" /></HStack>

                                </Flex>
                                {offer ? <>
                                    <HStack w="100%" alignItems="left" paddingTop="15px" justifyContent="space-between">
                                        <Box w="50%" _focus={{ boxShadow: "lg" }} >
                                            {offers ? <>
                                                <Select >
                                                    <option value='option1' >Last 7 Days</option>
                                                    <option value='option2'>Last 14 Days</option>
                                                    <option value='option3'>Last 30 Days</option>
                                                </Select>
                                            </> : <><Text>There are currently no details.</Text></>}
                                        </Box>
                                        {/* <Box w="50%" border="1px solid gray" borderColor="gray.300" borderRadius="8px">
                                            <Text p="10px" >Chart</Text>
                                        </Box> */}
                                    </HStack>
                                </> : <></>}

                            </VStack>
                            {/* Offers component ends here*/}
                        </VStack>

                    </Stack>
                    <Stack gap={5} direction={{ base: 'column', md: 'row' }} w="100%" >
                        <VStack w="100%" alignItems="left" py="20px">
                            {/* Item Activity component starts*/}
                            <VStack borderRadius="8px" border="1px solid gray" borderColor="gray.300" p="15px" marginBottom="20px">
                                <Flex justifyContent="space-between" width="100%" >
                                    <HStack>
                                        <IoIosCube size="25px" />
                                        <Text onClick={handleItemActivity} cursor="pointer" fontSize="18px" fontWeight="500">Item Activity</Text>
                                    </HStack>
                                    <HStack><IoMdArrowDropdown onClick={handleItemActivity} cursor="pointer" size="25px" /></HStack>

                                </Flex>
                                {ItemActivity ? <>

                                    <TableContainer width="100%" >
                                        <Table variant='simple' size="lg" >

                                            <Thead>
                                                <Tr>
                                                    <Th>Event</Th>
                                                    <Th>Price</Th>
                                                    <Th>From</Th>
                                                    <Th>To</Th>
                                                    {/* <Th >Date</Th> */}
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {allAuctionData.map((data) => (
                                                    <Tr key={data.appID}>
                                                        <Td>{data.auctionType}</Td>
                                                        {/* <Td>{location.state.data.price}</Td> */}
                                                        <Td>{data.price}</Td>
                                                        <Td >{data.owner_address.substring(0, 8) + "..." + data.owner_address.substr(data.owner_address.length - 8)}</Td>
                                                        <Td >{data.buyer_address.substring(0, 8) + "..." + data.buyer_address.substr(data.buyer_address.length - 8)}</Td>
                                                        {/* <Td >{data.date}</Td> */}
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>


                                </> : <></>}

                            </VStack>
                            {/* Item Activity component ends here*/}

                            {/* More from this component starts*/}
                            <VStack borderRadius="8px" border="1px solid gray" borderColor="gray.300" p="15px" marginBottom="20px">
                                <Flex justifyContent="space-between" width="100%" >
                                    <HStack>
                                        <IoIosCube size="25px" />
                                        <Text onClick={handleMoreInfo} cursor="pointer" fontSize="18px" fontWeight="500">More From This Collection</Text>
                                    </HStack>
                                    <HStack><IoMdArrowDropdown onClick={handleMoreInfo} cursor="pointer" size="25px" /></HStack>

                                </Flex>
                                {MoreInfo ? <>
                                    <HStack w="100%" alignItems="left" paddingTop="15px" justifyContent="space-between">
                                        {moreItem ? <>
                                            <Box w="50%" _focus={{ boxShadow: "lg" }} >
                                                <Select >
                                                    <option value='option1' >Last 7 Days</option>
                                                    <option value='option2'>Last 14 Days</option>
                                                    <option value='option3'>Last 30 Days</option>
                                                </Select>
                                            </Box>
                                            <Box w="50%" border="1px solid gray" borderColor="gray.300" borderRadius="8px">
                                                <Text p="10px" >Chart</Text>
                                            </Box>
                                        </> : <><Text>Nothing to show</Text></>}

                                    </HStack>
                                </> : <></>}

                            </VStack>
                            {/* More from this component ends here*/}
                        </VStack>
                    </Stack>



                </Container>
            </Box>
            {/* sell offer & Auction modal starts from here */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent height="180px" shadow="md">
                    <ModalHeader>Choose your offer</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <HStack alignItems="center" justifyContent="space-around">

                            <Button leftIcon={<IoIosSend />} size="lg" colorScheme='teal' variant='outline' w="150px" onClick={handleAuctionModal}>
                                Make Auction
                            </Button>
                            :
                            <Button leftIcon={<IoIosSend />} size="lg" colorScheme='teal' variant='outline' w="150px" onClick={handleAuctionModal} disabled={true}>
                                Make Auction
                            </Button>


                        </HStack>
                    </ModalBody>


                </ModalContent>
            </Modal>

            {/* sell offer & Auction modal ends here */}

            {/* auction property modal starts from here */}
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose} isCentered>

                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Auction for the highest bidder</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack gap={2}>
                            <HStack>
                                <Text>Starting Bid Amount = </Text>
                                {/* <Text>{location.state.data ? (Number(location.state.data.amount) * exchange_Rate?.algo).toFixed(2) / Number(location.state.numberOfAsset).toFixed(2) : null}</Text> */}

                                <Text>{location.state.data ? (Number(location.state.data.amount)).toFixed(2) / Number(location.state.numberOfAsset).toFixed(2) : null}</Text>

                                {/* <Text>
                                {location.state.data.borrowType == "usdc" ? ((Number(location.state.data?.amount)) * exchange_Rate?.algo).toFixed(2) : Number(location.state.data?.amount).toFixed(2)}
                                </Text> */}
                            </HStack>


                            {condition ? <span style={{ color: 'red' }}><Text>Value must be greater then 1</Text></span> : null}
                            {/* {location.state ? location.state.data.health_factor < 1 ? "" : */}
                            <>
                                {/* <HStack>
                                    <Text>Duration</Text>
                                </HStack> */}

                                {/* <HStack>
                                    <Box border="1px solid gray" p="10px" borderRadius="8px">
                                        <DatePicker
                                            selected={selected}
                                            onChange={(date) => dateChange(date)}
                                            minDate={now}
                                            maxDate={future}
                                            showTimeSelect
                                            timeIntervals={60}
                                            minTime={setHours(setMinutes(new Date(), 0), 8)}
                                            maxTime={setHours(setMinutes(future, 45), 23)}
                                            dateFormat="MMMM d, yyyy hhaa"

                                        />
                                    </Box>

                                    <Box border="1px solid gray" p="10px" borderRadius="8px">
                                        <DatePicker
                                            selected={selected2}
                                            onChange={(date) => dateChange1(date)}
                                            minDate={now}
                                            maxDate={future}
                                            showTimeSelect
                                            timeIntervals={60}
                                            minTime={setHours(setMinutes(new Date(), 0), 8)}
                                            maxTime={setHours(setMinutes(future, 45), 23)}
                                            dateFormat="MMMM d, yyyy hhaa"
                                        />
                                    </Box>
                                </HStack> */}
                            </>
                            {/* : null
                            } */}

                        </Stack>
                    </ModalBody>

                    <ModalFooter>

                        {/* <Button colorScheme='blue' mr={3} onClick={handleAuction}>
                            Auction
                        </Button> */}
                        <Button colorScheme='blue' mr={3} onClick={handleAuctionForHealthFactorBelowOne}>
                            Auction
                        </Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* auction property modal starts from here */}

            {/* pop up Modal for Sell NFT*/}
            <Modal isOpen={modal3.isOpen} onClose={modal3.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>NFT Sell Action</ModalHeader>
                    <ModalBody py="30px">
                        <VStack>
                            {popup ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    App Initialize
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    App Initialize
                                </Alert>
                            }
                            <Divider />
                            {init_escrow ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Change NFT Credentials
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Change NFT Credentials
                                </Alert>
                            }
                            <Divider />
                            {escrow ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Initialize Escrow
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Initialize Escrow
                                </Alert>
                            }
                            <Divider />

                            {sellOffer ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Fund Escrow
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Fund Escrow
                                </Alert>
                            }

                            <Divider />

                            {info ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Sell Offer
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Sell Offer
                                </Alert>
                            }

                        </VStack>
                    </ModalBody>
                    <VStack>

                    </VStack>

                </ModalContent>
            </Modal>
            {/* pop up Modal ends */}

            {/* pop up modal buy NFT */}
            <Modal isOpen={modal4.isOpen} onClose={modal4.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>NFT Buy Action</ModalHeader>
                    <ModalBody py="30px">
                        <VStack>
                            {buyOffer ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Asset Optin
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Asset Optin
                                </Alert>
                            }
                            <Divider />
                            {complete ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Buy NFT
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Buy NFT
                                </Alert>
                            }

                        </VStack>
                    </ModalBody>
                    <VStack>

                    </VStack>

                </ModalContent>
            </Modal>
            {/* pop up modal end for buy NFT */}

            {/* pop up for CLOSE AUCTION */}
            <Modal isOpen={modal5.isOpen} onClose={modal5.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Close Action</ModalHeader>
                    <ModalBody py="30px">
                        <VStack>
                            {closeSuccessful ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Auction Closed
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Auction Closed
                                </Alert>
                            }
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* pop up modal end for CLOSE AUCTION */}

            {/* popup for Biding start */}
            <Modal isOpen={modal6.isOpen} onClose={modal6.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>NFT Bidding</ModalHeader>
                    <ModalBody py="30px">
                        <VStack>
                            {placeAuctionBid ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Asset opt-in
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Asset opt-in
                                </Alert>
                            }

                            {successfulBid ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Place Bid
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Place Bid
                                </Alert>
                            }
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* popup for Biding ends */}

            {/* popup for AUCTION start from here */}
            <Modal isOpen={modal7.isOpen} onClose={modal7.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Auction</ModalHeader>
                    <ModalBody py="30px">
                        <VStack>
                            {auctionApp ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Create Auction App
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Create Auction App
                                </Alert>
                            }

                            {completeAppSetup ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Setup Auction APP
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Setup Auction APP
                                </Alert>
                            }
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* popup for AUCTION end here */}

            {/* buy successfull Modal */}
            <Modal isOpen={modal8.isOpen} onClose={modal8.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="400px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Congratulations!!</Text>
                                <Text color={textColor}>You successfully bought the NFT. Have fun.</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* buy successfull modal ends here */}

            {/* Modal for Bidder Amount */}
            <Modal isOpen={modal9.isOpen} onClose={modal9.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Bidding Amount</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleBiddingProcedure}>
                            <Box w="100%">
                                {nftDetails?.nftDetails?.minimumBidValue && (
                                    <Text>
                                        Starting Bid Value is{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {nftDetails.nftDetails.minimumBidValue.toFixed(2)}
                                            {/* {nftDetails.nftDetails.minimumBidValue} */}
                                        </span>
                                    </Text>
                                )}
                            </Box>
                            <HStack py="30px">

                                <Box w="35%">
                                    <Select placeholder='Select option' value={biddingCoin ?? ""}
                                        onChange={(e) => setBiddingCoin(e.target.value)} required={true}
                                    >
                                        <option value='algo'> USDC</option>

                                    </Select>
                                </Box>
                                <Box w="65%">
                                    <Input type='number'
                                        name='amount'
                                        placeholder='Insert bid amount'
                                        value={biddingAmount ?? ""}
                                        required={true}
                                        // onChange={(e) => setBiddingAmount(e.target.value)}
                                        onChange={(e) => handleBidAmount(e)}
                                    />
                                </Box>


                            </HStack>

                            {/* new condition check */}
                            {/* {max > 0 ? biddingAmount > max ? "" : <Text>You have to insert more than {max}</Text> : <Text>You have to insert equal or more than {Number(nftDetails?.nftDetails?.minimumBidValue)?.toFixed(2) + Number(0.01)}</Text>} */}

                            {max > 0 ? biddingAmount > max ? "" : <Text>You have to insert more than {max}</Text> : <Text>You have to insert more than {final_value_decimal}</Text>}

                            {/* {max > 0 ? biddingAmount >= nftDetails?.nftDetails?.minimumBidValue?.toFixed(2) ? <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px">Proceed</Button> : <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px" isDisabled={true}>Proceed</Button> : biddingAmount >= nftDetails?.nftDetails?.minimumBidValue ? <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px">Proceed</Button> : <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px" isDisabled={true}>Proceed</Button>} */}

                            {/* {max > 0 ? biddingAmount > max ? <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px" >Proceed</Button> : <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px" isDisabled={true}>Proceed</Button> : <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px">Proceed</Button>} */}


                            {max < 0 ? biddingAmount > final_value_decimal ? <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px" >Proceed</Button> : <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px" isDisabled={true}>Proceed</Button> : biddingAmount > max ? <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px" >Proceed</Button> : <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px" isDisabled={true}>Proceed</Button>}



                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* Modal for Bidder Amount ends */}

            {/* =================Modal for successfull bid =============== */}
            <Modal isOpen={modal10.isOpen} onClose={modal10.onClose} isCentered>
                <ModalOverlay bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />
                    <AnimatePresence>
                        {modal10.isOpen && (
                            <motion.ModalBody
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={animationVariants}
                                borderRadius="15px"
                                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                            >
                                <Center>
                                    <VStack alignItems="center" justifyContent="space-between" py="20%">
                                        <Text color={textColor}>Congratulations!!</Text>
                                        <Text color={textColor}>Bid successful. Have fun.</Text>
                                    </VStack>
                                </Center>
                            </motion.ModalBody>
                        )}
                    </AnimatePresence>
                </ModalContent>
            </Modal>
            {/* =================Modal for successfull bid ends===========*/}
            {/* =======================modal for borrow amount starts=================== */}
            <Modal isOpen={modal11.isOpen} onClose={modal11.onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Borrow Amount</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleBorrowOffer}>
                            <HStack py="30px">

                                <Box w="35%">
                                    <Select placeholder='Select option' value={biddingCoin ?? ""}
                                        onChange={(e) => setBiddingCoin(e.target.value)} required={true}
                                    >
                                        <option value='algo'> Algo</option>

                                    </Select>
                                </Box>
                                <Box w="65%">
                                    <Input type='number'
                                        name='amount'
                                        placeholder='Insert borrow amount'
                                        value={borrowAmount ?? ""}
                                        required={true}
                                        onChange={(e) => setBorrowAmount(e.target.value)}
                                    />
                                </Box>


                            </HStack>
                            <Button type='submit' width="100%" bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "whiteAlpha.900" }} py="25px" my="15px">Proceed</Button>
                        </form>
                    </ModalBody>

                    {/* <ModalFooter>


                    </ModalFooter> */}
                </ModalContent>
            </Modal>
            {/* =======================modal for borrow amount ends=================== */}
            {/* ===================Modal for borrow functionality====================== */}
            <Modal isOpen={modal12.isOpen} onClose={modal12.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Borrow Action</ModalHeader>
                    <ModalBody py="30px">
                        <VStack>
                            {item1 ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    App Initialize
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    App Initialize
                                </Alert>
                            }
                            <Divider />
                            {item2 ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Initialize Escrow
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Initialize Escrow
                                </Alert>
                            }
                            <Divider />
                            {item3 ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Make Borrow Offer
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Make Borrow Offer
                                </Alert>
                            }
                            <Divider />

                            {item4 ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Fund Escrow
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Fund Escrow
                                </Alert>
                            }

                            <Divider />

                            {/* {item5 ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Sell Offer
                                </Alert> :
                                <Alert status='info'>
                                    <AlertIcon />
                                    Sell Offer
                                </Alert>
                            } */}

                        </VStack>
                    </ModalBody>
                    <VStack>

                    </VStack>

                </ModalContent>
            </Modal>
            {/*========================== Modal for borrow functionality ends==================*/}
            {/* ==================successful Borrow offer modal start=================== */}
            <Modal isOpen={modal13.isOpen} onClose={modal13.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Congratulations!!</Text>
                                <Text color={textColor}>You successfully make the BORROW OFFER. Have fun.</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={modal14.isOpen} onClose={modal4.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Congratulations!!</Text>
                                <Text color={textColor}>Auction started. Have fun.</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* ==================successful Borrow offer modal ends=================== */}

            {/* =================sell offer modal================================ */}
            <Modal isOpen={modal15.isOpen} onClose={modal15.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Congratulations!!</Text>
                                <Text color={textColor}>You successfully made the SELL OFFER. Have fun.</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* =================sell offer modal ends============================ */}

            {/* =================close auction modal================================ */}
            <Modal isOpen={modal16.isOpen} onClose={modal16.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />

                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color={textColor}>Congratulations!!</Text>
                                <Text color={textColor}>You successfully closed the AUCTION. Have fun.</Text>
                            </VStack>
                        </Center>

                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* =================close auction modal ends============================ */}

            {/* ==================Loader Modal====================== */}
            <Modal isOpen={modal17.isOpen} onClose={modal17.onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>In progress.....</ModalHeader>
                    <ModalBody alignItems="center" py="20px">
                        <HStack alignItems="center" justifyContent="space-evenly">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                h="120px"
                                w="120px"
                            />
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* ==================Loader Modal ends================= */}
        </>
    )
}

export default AuctionUSDC
