import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Box, Center, VStack, Image, Heading, Button, Container, HStack, ButtonGroup, Stack, IconButton, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, Divider, AspectRatio, Alert, AlertIcon, AlertTitle,
} from "@chakra-ui/react";
import dummyImage from '../components/images/Turtle-with-Scooter.png';
import bgImage from '../components/images/bg.jpg';
import { ArrowRightIcon, PhoneIcon, SunIcon, ArrowForwardIcon, AtSignIcon, EmailIcon, LinkIcon } from '@chakra-ui/icons';
//import MyAlgoConnect from "@randlabs/myalgo-connect";
import { useNavigate } from 'react-router-dom';
//import WalletConnect from "@walletconnect/client";
//import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { myAlgoWalletInfo, WalletConnecAction, myPeraWalletInfo, getAllAssetsAction } from '../components/redux/actions';
import media from '../components/images/media.json';
import { API_URL } from '../url';
import algosdk from 'algosdk';
//import { Buffer } from 'buffer';
//import TParticles from '../components/TParticles'
//import StarfieldAnimation from 'react-starfield-animation'
import "../components/css/galaxy.css";
//window.Buffer = window.Buffer || require("buffer").Buffer;
// Buffer.from("anything", "base64");
// window.Buffer = window.Buffer || require("buffer").Buffer;
import { PeraWalletConnect } from "@perawallet/connect";
export const peraWallet = new PeraWalletConnect();

function WalletNew(props) {
    const dispatch = useDispatch();
    //props.funcNav(false);
    //const [algoAddress, setalgoAddress] = useState("");
    const [accAddress, setAccAddress] = useState("");
    const [error, setError] = useState(false);
    const [algoAmount, setalgoAmount] = useState("");
    const [walletCon, setWalletCon] = useState("");
    const [connector, setConnector] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const modal2 = useDisclosure();
    const [tokens, setTokens] = useState([]);
    //const myAlgoWallet = new MyAlgoConnect();
    const navigate = useNavigate();
    const algodServer = 'https://testnet-algorand.api.purestake.io/ps2';
    const algodPort = '';
    const algodToken = `${API_URL}`;
    const algoAddress = useSelector((state) => state.token.myAlgoAddress);

    // for playing video
    const videoEl = useRef(null);
    const attemptPlay = () => {
        videoEl &&
            videoEl.current &&
            videoEl.current.play().catch(error => {
                console.error("Error attempting to play", error);
            });
    };

    // ================= start working from here for pera wallet ===============

    const [accountAddress, setAccountAddress] = useState(null);
    const isConnectedToPeraWallet = !!accountAddress;
    //const { display: displayToast } = useToaster();
    // const { accountInformationState, refetchAccountDetail } = useGetAccountDetailRequest({
    //     chain: chainType,
    //     accountAddress: accountAddress || ""
    // });

    useEffect(() => {
        // Reconnect to the session when the component is mounted
        const reconnectWallet = async () => {
            try {
                const newAccounts = await peraWallet.reconnectSession();
                // Setup the disconnect event listener
                peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);

                if (newAccounts.length) {
                    setAccountAddress(newAccounts[0]);
                    await fetchAndUpdateWalletData(newAccounts[0]);
                    // Redirect to the dashboard page after successful reconnection
                    navigate("/dashboard");
                }
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        reconnectWallet();
    }, []);

    async function fetchAndUpdateWalletData(address) {
        try {
            const response = await axios(`${API_URL}swap/checkOptin?address=` + address);

            if (response.status === 200) {
                const optinResult = response.data;

                console.log(response.data);
                console.log(response.data.clientInfo);
                dispatch(myAlgoWalletInfo({
                    algoAddress: address,
                    algoBalance: response.data.clientInfo.amount / 1000000
                }));

                dispatch(getAllAssetsAction({
                    assets_from_account: response.data.clientInfo["created-assets"],
                    assets_from_account_2: response.data.clientInfo.assets,
                }));
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    }

    async function handleConnectWalletClick() {
        onClose();
        peraWallet
            .connect()
            .then(async (newAccounts) => {
                peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);
                const address = newAccounts[0];
                // console.log(newAccounts);
                // console.log(address);
                setAccountAddress(address);
                await fetchAndUpdateWalletData(address);

                navigate("/dashboard");
            })
            .catch((error) => {
                onOpen();
                if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
                    setError(true);
                    console.error("Error connecting wallet", error);
                }
            });
    }

    function handleDisconnectWalletClick() {
        peraWallet.disconnect();
        setAccountAddress(null);
    }
    // ================= end working from here for pera wallet ===============
    //console.log(accountAddress);

    return (
        <>

            {/* <Center w="full" minHeight="100vh" color="whiteAlpha.800" backgroundPosition="center" backgroundImage={bgImage} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundColor="#000"> */}
            <Box className="bg"></Box>

            <Box className="star-field">
                <Box className="layer"></Box>
                <Box className="layer"></Box>
                <Box className="layer"></Box>
            </Box>
            <Center minH="100vh">

                <Box py="100px">

                    <VStack spacing={[4, 6, 6]}>
                        {/* <TParticles /> */}

                        <Image h="300px" src={dummyImage} alt='cartoon' />
                        <Heading fontSize="21px" color="whiteAlpha.900">Please connect your wallet</Heading>
                        {/* <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} width="300px" onClick={onOpen} borderRadius="12px" py="25px">
                            Connect wallet
                        </Button> */}
                        <Button leftIcon={<ArrowRightIcon />} bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} py="25px" width="300px" onClick={handleConnectWalletClick/*(e) => walletConnectInit()*/} borderRadius="12px">
                            Connect with Pera Wallet
                        </Button>
                    </VStack>

                </Box>
            </Center>

            {/* Modal code */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" alignItems='center'>
                    <ModalHeader width="100%" bgGradient='linear(to-r, #345a9f, #6919da)' color="whiteAlpha.900">
                        Connect Wallet
                    </ModalHeader>

                    <ModalCloseButton />
                    <ModalBody backgroundColor="blackAlpha.200" width="100%" py={8}>
                        <VStack
                            direction={{ base: 'column', md: 'row' }}
                            py={{ base: '4', md: '2' }}
                            px="10px"
                        >
                            <Button leftIcon={<SunIcon />} bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} variant='solid' py="30px" width="100%"
                                onClick={
                                    handleConnectWalletClick
                                }>Connect to Pera Wallet
                            </Button>
                        </VStack>
                        <VStack
                            direction={{ base: 'column', md: 'row' }}
                            py={{ base: '4', md: '2' }}
                            px="10px"
                        >
                            <Button leftIcon={<SunIcon />} bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} variant='solid' py="30px" width="100%">
                                Defly
                            </Button>
                        </VStack>
                        <VStack
                            direction={{ base: 'column', md: 'row' }}
                            py={{ base: '4', md: '2' }}
                            px="10px"
                        >
                            <Button leftIcon={<SunIcon />} bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} variant='solid' width="100%" py="30px">
                                Exodus
                            </Button>
                        </VStack>

                        <VStack
                            direction={{ base: 'column', md: 'row' }}
                            py={{ base: '4', md: '2' }}
                            px="10px"
                        >
                            <Button rightIcon={<ArrowForwardIcon />} bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} variant='solid' width="100%" py="30px">
                                More
                            </Button>
                        </VStack>


                        <VStack py={4} spacing={4}>
                            <Text color="gray.700">Haven’t got a crypto wallet yet?</Text>
                            <Link to="https://docs.galapago.app/connect-wallet" target="_blank">
                                <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} size='lg' py="30px">
                                    Learn How to Connect ?
                                </Button>
                            </Link>
                        </VStack>
                    </ModalBody>
                    {error ?
                        <Alert status='error'>
                            <AlertIcon />
                            <AlertTitle>Check your BACKEND!! It may not CONNECTED.</AlertTitle>
                        </Alert> : null
                    }
                </ModalContent>
            </Modal>

            {/* Opt-in Modal starts from here*/}
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose} isCentered>
                <ModalOverlay />
                <ModalContent width="340px" height="200px">
                    <ModalCloseButton />
                    <ModalBody borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" >
                        <Center>
                            <VStack alignItems="center" justifyContent="space-between" py="20%">
                                <Text color="gray.800">Please OPT-IN your account</Text>
                                <Button bgColor="#A2CFA6" color="blackAlpha.900" onClick={handleConnectWalletClick/*myAlgo*/} w="200px">Please opt-in</Button>
                            </VStack>
                        </Center>
                    </ModalBody>

                </ModalContent>
            </Modal>
            {/* Opt-in Modal starts ends here*/}
        </>
    )
}

export default WalletNew;
