import React, { useEffect } from 'react'
import { Box, HStack, Text, Image, VStack, Input, useColorModeValue, Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton } from '@chakra-ui/react';
import dummyImage from '../images/Draft.png';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NFTborrowAmountAction, availableToBorrowAction } from '../redux/actions';
import { IoIosRadioButtonOff, IoIosEgg } from "react-icons/io";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where
} from "firebase/firestore";
import { db } from '../firebase/FirebaseConfig';
import { rates } from '../UtilizationRate/UtilizationRateData';
import axios from 'axios';
import { API_URL } from '../../url';
import algosdk, { getApplicationAddress } from 'algosdk';
import { toast } from "react-toastify"


function SecondSlide({ setIsThirdStepNextButtonDisabled, setShowAlert }) {
    const textColor = useColorModeValue("gray.600", "whiteAlpha.500");
    const [borrowAmount, setBorrowAmount] = useState();
    const dispatch = useDispatch();
    const borrowItemFromRedux = useSelector(state => state.token.borrow_item);
    const supply_amount = useSelector(state => state.token.supply_amount_algo);
    const borrow_AppID = useSelector(state => state.token.borrow_app_id);
    const totalBorrowAmount = useSelector(state => state.token.total_borrow_amount);
    const totalDepositAmount = useSelector(state => state.token.total_deposit_amount);
    const BorrowAPR = useSelector(state => state.token.borrow_apr);
    const item = useSelector(state => state.token.borrow_item);

    const price = item.price;
    const [value, setValue] = useState(0);
    //const borrowPrice = (item.price * 0.4).toFixed(2);
    // const borrowPrice = ((item.price ? (Number(item.price) + Number(supply_amount) * 4.73) : Number(supply_amount) * 4.73) * 0.4).toFixed(2);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const aprRateRef = collection(db, "borrowAPR");
    const [totalBorrow, setBorrow] = useState();
    const [itemWithHealthFactor, setBorrowforHealthFactor] = useState();
    const [deposit, setDeposit] = useState();
    const nftBorrowRef = collection(db, "borrow");
    const nftDepositRef = collection(db, "deposit");
    const borrowInfoRef = collection(db, "borrow-info");
    const summaryTableRef = collection(db, "summaryTable");
    const [nftDetails, setNftDetails] = useState();
    const [borrowInfo, setBorrowInfo] = useState()
    const [allAssets, setsetAllAssets] = useState();
    const [exchange_Rate, setExchangeRate] = useState();
    const [priceStatus, setPriceStatus] = useState(false);
    const [checkStatus, setCheckStatus] = useState(false);
    const [pool_amount, setPool] = useState();
    const appIdFromRedux = useSelector(state => state.token.borrow_app_id);
    const [sliderValue, setSliderValue] = useState(0);
    console.log(exchange_Rate);
    let marks = {

        0: <strong>0%</strong>,
        25: '25%',
        50: '50%',
        75: '75%',
        100: {
            style: {
                color: 'green',
            },
            label: <strong>100%</strong>,
        },
    };


    let totalAPRAmount = 0;
    let total_Diposit_Amount = 0;
    let total_Borrow_Amount = 0;
    //const total_supply_amount = (amount * exchange_Rate?.usdc_price) / exchange_Rate?.algo;
    console.log(borrowItemFromRedux);
    useEffect(() => {

        const aprAmount = async () => {

            const data = await getDocs(aprRateRef);
            data.docs.map((doc) => {
                totalAPRAmount += parseInt(doc.data().amount)
            })
        };

        aprAmount();
        let amount = 0;
        const borrowAmount = async () => {
            const data = await getDocs(nftBorrowRef);
            data.docs.map((doc) => {
                amount += parseFloat(doc.data().amount)
                setBorrow(amount);
            })
        };

        borrowAmount();

        const depositAmount = async () => {
            const data = await getDocs(nftDepositRef);
            data.docs.map((doc) => {
                total_Diposit_Amount += parseFloat(doc.data().amount)
                setDeposit(total_Diposit_Amount);
            })
        };

        depositAmount();

        const getNftRange = async () => {
            let rate = totalBorrow / deposit;
            let checkRate = Math.floor(rate);
            let checkRate2 = checkRate + 5;
            const q2 = query(collection(db, "range"), where("utilization_rate", ">", checkRate), where("utilization_rate", "<", checkRate2));

            const data2 = await getDocs(q2);

            data2.forEach(async (doc) => {
                let docData = doc.data();

                await setNftDetails({ ...nftDetails, nftDetails: docData, nftId: doc.id })
            });

        }

        const getBorrowInfoFromFirebase = async () => {
            const data = await getDocs(borrowInfoRef);
            setBorrowInfo(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getBorrowInfoFromFirebase();


        const getAllInfoFromFirebaseSummaryTable = async () => {
            const data = await getDocs(summaryTableRef);
            setsetAllAssets(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getAllInfoFromFirebaseSummaryTable();

        const exchangeRate = () => {

            // await axios('http://192.168.0.135:12000/swap/checkOptin?address=' + address)
            axios(`${API_URL}swap/assetPrice`)
                .then(response => {
                    if (response.status === 200) {
                        setExchangeRate(response.data)
                    }
                })
                .catch(error => {
                    console.error("Error fatching data", error);

                })
        }

        exchangeRate();


        const pool_amount_func = async () => {
            const algodClient = new algosdk.Algodv2("", 'https://testnet-api.algonode.cloud', '');

            const escrowAddress = await getApplicationAddress(appIdFromRedux);
            let accountInfo = await algodClient.accountInformation(escrowAddress).do();
            let accountAmount = algosdk.microalgosToAlgos(accountInfo.amount)
            //setDepositAccAmount(accountAmount);
            //dispatch(PoolAmountAction(accountAmount));
            setPool(accountAmount);
        }

        pool_amount_func();

        setTimeout(() => {
            getNftRange();
        }, 3000);


    }, []);

    //const total_supply_amount = (supply_amount * exchange_Rate?.usdc_price) / exchange_Rate?.algo;
    const total_supply_amount = supply_amount;
    const algoAssetInfo = allAssets?.find((data) => data.asset === "Algo");
    const FlemishAssetInfo = allAssets?.find((data) => data.asset === "Flemish");
    const UsdcAssetInfo = allAssets?.find((data) => data.asset === "USDC");
    const MingoAssetInfo = allAssets?.find((data) => data.asset === "Mingo");
    const AiGonnaAssetInfo = allAssets?.find((data) => data.asset === "AI_Gonna");

    let nftTotalPrice = 0;
    for (let i = 0; i < borrowItemFromRedux.length; i++) {
        nftTotalPrice += parseInt(borrowItemFromRedux[i].price);
    }
    //calculate total borrow amount
    // let total_amount_for_borrow = item.price ? (item.price * algoAssetInfo?.MaxCF) + ((Number(supply_amount) * 4.73) * UsdcAssetInfo?.MaxCF) : ((Number(supply_amount) * 4.73) * UsdcAssetInfo?.MaxCF);

    // let total_amount_for_borrow = nftTotalPrice ? (nftTotalPrice * AiGonnaAssetInfo?.MaxCF) + (Number(supply_amount) * UsdcAssetInfo?.MaxCF) : (Number(supply_amount) * UsdcAssetInfo?.MaxCF);
    let total_amount_for_borrow = 0;
    console.log(nftTotalPrice);
    if (nftTotalPrice && supply_amount) {
        total_amount_for_borrow = ((nftTotalPrice / 1000) * AiGonnaAssetInfo?.MaxCF) + (Number(total_supply_amount) * UsdcAssetInfo?.MaxCF)
    } else if (nftTotalPrice) {
        total_amount_for_borrow = ((nftTotalPrice / 1000) * AiGonnaAssetInfo?.MaxCF)
    } else {
        total_amount_for_borrow = (Number(total_supply_amount) * UsdcAssetInfo?.MaxCF)
    }
    //if (item.type ===)
    //const borrowPrice = ((item.price ? (Number(item.price) + Number(supply_amount) * 4.73) : Number(supply_amount) * 4.73) * 0.4).toFixed(2);
    // const borrowPrice = item.price ? (item.price * algoAssetInfo?.MaxCF) + ((Number(supply_amount) * 4.73) * UsdcAssetInfo?.MaxCF) : ((Number(supply_amount) * 4.73) * UsdcAssetInfo?.MaxCF);

    const borrowPrice = total_amount_for_borrow;
    const borrow_rate = nftDetails ? nftDetails.nftDetails.borrow_rate : 0;

    const borrow_rate_new = borrowInfo?.map((item) => {
        if ((totalBorrow / pool_amount) < item.Uoptimal) {
            return item.R0 + ((totalBorrow / pool_amount) / item.Uoptimal) * item.Rslope1
        } else {
            return item.R0 + item.Rslope1 + ((totalBorrow / pool_amount) - item.Uoptimal) / (1 - item.Uoptimal) * item.Rslope2
        }

    })

    const borrow_apr = borrow_rate_new ? borrow_rate_new[0].toFixed(2) : "";

    const valueRate = rates.filter((data) => data.utilization === Math.round(totalBorrow / deposit));

    dispatch(availableToBorrowAction(total_amount_for_borrow ? total_amount_for_borrow : ""));


    if (!borrowAmount) {
        setIsThirdStepNextButtonDisabled(false)
    } else {
        setIsThirdStepNextButtonDisabled(true)
    }
    const handleBorrowAmount = (e) => {
        // setCheckStatus(true)
        // setBorrowAmount(e.target.value);
        // if (e.target.value > total_amount_for_borrow) {
        //     setPriceStatus(true);
        //     dispatch(NFTborrowAmountAction(""));
        //     return 0;
        // } else {
        //     setPriceStatus(false);
        //     dispatch(NFTborrowAmountAction(e.target.value));
        //     setShowAlert(false);
        // }


        setCheckStatus(true)

        if (e.target.value >= 0 <= total_amount_for_borrow) {
            const inputValue = e.target.value;
            if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= total_amount_for_borrow) {
                setBorrowAmount(inputValue);
                const newSliderValue = (inputValue / total_amount_for_borrow) * 100;
                setSliderValue(newSliderValue);
                dispatch(NFTborrowAmountAction(e.target.value * exchange_Rate?.algo));
            } else if (inputValue < 0) {
                //console.log("erereoreor");
                toast.error("Invalid Amount");
                dispatch(NFTborrowAmountAction(""));
            } else if (inputValue > total_amount_for_borrow) {
                toast.error("Not enough balance");
                dispatch(NFTborrowAmountAction(""));
            }
        }
    }

    function log(value) {
        //setBorrowAmount(borrowPrice * value / 100);
        setSliderValue(value);
        const newAmount = total_amount_for_borrow * value / 100;
        setBorrowAmount(newAmount.toFixed(2));
        dispatch(NFTborrowAmountAction((borrowPrice * value / 100) * exchange_Rate?.algo));
    }

    return (
        <>
            <HStack py="40px">
                <HStack w={{ base: "20%", md: "35%" }}>
                    <Image src={borrowItemFromRedux[0]?.imageFromApi} w="250px" h="250px" objectFit="cover" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" borderRadius="12px" />
                </HStack>
                <VStack w={{ base: "80%", md: "65%" }} h={{ base: "300px", md: "250px" }} px="20px" spacing={10}>
                    <HStack justifyItems="center" justifyContent="space-between" width="100%">
                        <VStack align="left" w="33%">
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>Collection</Text>
                            <Text fontSize={{ base: "14px", md: "18px" }} color={textColor}>{borrowItemFromRedux[0]?.name}</Text>
                        </VStack>
                        <VStack align="left" w="33%">
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>Token ID</Text>
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>{borrowItemFromRedux[0]?.assetID}</Text>
                        </VStack>
                    </HStack>

                    <HStack justifyItems="center" justifyContent="space-between" width="100%">
                        <VStack align="left" w="33.33%">
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>Total collateral value
                            </Text>
                            {/* <Text fontSize="18px" color={textColor}>{borrowItemFromRedux.price == undefined ? (supply_amount) : (Number(borrowItemFromRedux.price) + Number(supply_amount))}</Text> */}
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>{nftTotalPrice == 0 ? ((total_supply_amount) * exchange_Rate?.algo) : (((Number(nftTotalPrice) / 1000) + Number(total_supply_amount)) * exchange_Rate?.algo).toFixed(2)} USDC</Text>
                        </VStack>
                        <VStack align="left" w="33.33%">
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>Utilization rate
                            </Text>
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>{(((totalBorrow / pool_amount)) * 100).toFixed(2)}
                                %</Text>
                        </VStack>

                    </HStack>

                    <HStack justifyItems="center" justifyContent="space-between" width="100%">
                        <VStack align="left" w="33.33%">
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>Available to borrow

                            </Text>
                            {/* <Text fontSize="18px" color={textColor}>{((item.price ? (Number(item.price) + Number(supply_amount) * 4.73) : Number(supply_amount) * 4.73) * 0.4).toFixed(2)}</Text> */}
                            <Text fontSize={{ base: "14px", md: "14px" }} color={textColor}>{(total_amount_for_borrow * exchange_Rate?.algo).toFixed(2)} USDC</Text>

                        </VStack>
                        <VStack align="left" w="33.33%">
                            <Text fontSize="14px" color={textColor}>Borrow Rate</Text>

                            <Text fontSize="18px" color={textColor}>{(borrow_apr * 100).toFixed(2)}%</Text>

                        </VStack>
                        {/* <VStack align="left" w="33.33%">
                            <Text fontSize="14px" color={textColor}>Liquidation price

                            </Text>
                            <Text fontSize="18px" color={textColor}>{(0.9 * item.price).toFixed(2)}</Text>
                        </VStack> */}
                    </HStack>
                </VStack>
            </HStack>

            {!isNaN(total_amount_for_borrow * exchange_Rate?.algo) && (
            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="20px" width="100%" bgColor={bgColor} borderRadius="15px">
                <VStack w="100%" align="left">
                    <Text fontSize={{ base: "14px", md: "16px" }} fontWeight="600" fontStyle="italic">To avoid run out of liquidity each TestNet loan can only be less than 5 USDC!</Text>
                    <Text fontSize={{ base: "14px", md: "14px" }} fontWeight="600" textAlign="left">Amount</Text>
                    <Input
                        type="number"
                        name='Amount'
                        value={(borrowAmount * exchange_Rate?.algo).toFixed(2)}
                        required={true}
                        border="none"
                        color="gray.500"
                        bgColor={'gray.200'}
                        placeholder='Please enter borrow amount'
                        py="30px"
                        min="0"
                        onChange={(e) => handleBorrowAmount(e)}
                    />
                </VStack>

            </HStack>
                )}
            {!isNaN(total_amount_for_borrow * exchange_Rate?.algo) && (
            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="30px" width="100%" bgColor={bgColor} m="10px 0px 40px 0px" borderRadius="15px">
                <Slider min={0} marks={marks} step={1} max={100} onChange={log} value={sliderValue} />

            </HStack>
            )}

            {priceStatus ? (
                <Alert status="error" borderRadius="15px" mb="1em">
                    <AlertIcon />
                    <AlertTitle mr={2}>Error!</AlertTitle>
                    <AlertDescription>You shouldn't enter more than {total_amount_for_borrow}</AlertDescription>
                    {/* <CloseButton position="absolute" right="8px" top="8px" onClick={() => setPriceStatus(false)} /> */}
                </Alert>

            ) : ""}

        </>
    )
}

export default SecondSlide
