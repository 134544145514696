import { Flex, VStack, FormControl, FormLabel, NumberInput, NumberInputField, Box, Button, Collapse, Container, HStack, Image, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Tooltip, Select, ModalFooter, Img } from '@chakra-ui/react';
import React from 'react'
import { useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import { IoIosMedkit } from "react-icons/io";
import dummyImage from '../components/images/pago-head.png';
import { useState, useEffect, useMemo } from 'react';
import { db } from '../components/firebase/FirebaseConfig';
import { Link, useNavigate } from 'react-router-dom';
import { Search2Icon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import Header from '../components/header/Header';
import img from "../components/images/coin.png";
import img1 from "../components/images/algoCoin.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { setMinutes, setHours } from 'date-fns';
import MyAlgoConnect from "@randlabs/myalgo-connect";
import axios from 'axios';
import algosdk, { bigIntToBytes, decodeAddress, getApplicationAddress } from 'algosdk';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where,
    update
} from "firebase/firestore";
import moment from 'moment';
import { API_URL } from '../url';
import { peraWallet } from "./WalletNew";
//import { PeraWalletConnect } from "@perawallet/connect";
import { ReactComponent as UsdcCoinImg } from '../components/images/Icons/usd-coin-usdc-logo.svg';
import { ReactComponent as NFTCoinImg } from '../components/images/Icons/nft-gold-coin.svg';

//const peraWallet = new PeraWalletConnect();


function WhiteListCollection() {
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const nftBorrowRef = collection(db, "WhiteListedNFTCollection");
    const UsdcBorrowRef = collection(db, "borrow-usdc");
    const [borrowNFT, setBorrowNFT] = useState([]);
    const [allUsdcBorrowNFT, setAllUsdcBorrowNFT] = useState([]);
    const navigate = useNavigate();
    const [searchText, setSearchTest] = useState('');
    const [allAssets, setsetAllAssets] = useState();
    const [summaryTable, setSummaryTable] = useState();
    const summaryTableRef = collection(db, "summaryTable");
    const [borrowInfo, setBorrowInfo] = useState();
    const borrowInfoRef = collection(db, "borrow_info_usdc");
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const [healthFilter, setHealthFilter] = useState(1.5);


    useEffect(() => {

        const BorrowNFT = async () => {
            const data = await getDocs(nftBorrowRef);
            setBorrowNFT(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        BorrowNFT();

        const getAllUsdcBorrowNft = async () => {
            const data = await getDocs(UsdcBorrowRef);
            setAllUsdcBorrowNFT(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getAllUsdcBorrowNft();
    }, []);

    console.log(borrowNFT);
    console.log(allUsdcBorrowNFT);
    const Yieldings = allUsdcBorrowNFT.filter((item) => item.name.substring(0, 3) == "YLD");
    const Mngo = allUsdcBorrowNFT.filter((item) => item.name.substring(0, 3) == "MNG");
    const goanna = allUsdcBorrowNFT.filter((item) => item.name.substring(0, 3) == "goa");
    const Mostly = allUsdcBorrowNFT.filter((item) => item.name.substring(0, 3) == "MFE");
    const Crazy = allUsdcBorrowNFT.filter((item) => item.name.substring(0, 3) == "CGF");
    const Dragon = allUsdcBorrowNFT.filter((item) => item.name.substring(0, 3) == "ADG");
    const Flemish = allUsdcBorrowNFT.filter((item) => item.name.substring(0, 2) == "FG");

    console.log(Yieldings);
    console.log(Mngo);
    console.log(goanna);
    console.log(Mostly);
    console.log(Crazy);
    console.log(Dragon);
    console.log(Flemish);


    return (
        <>
            <Header />
            <Box py="50px">

                <Box w="100%" minH="100vh">
                    <Container maxW={'7xl'} my="80px">
                        <HStack justify="center" my="30px">
                            <IoIosMedkit size="40px" color='#6faa6b' />
                            <Text fontSize={{ base: "20px", md: "30px" }} fontWeight="600" >White Listed NFT Collection</Text>
                        </HStack>

                        <TableContainer>
                            <Table variant='simple'>

                                <Thead >
                                    <Tr bgColor={bgColor} borderRadius="50%">
                                        <Th>Collection</Th>
                                        <Th>NFT's in collection</Th>
                                        <Th>Floor Price</Th>
                                        <Th>Active collaterals</Th>
                                        <Th>Available to borrow</Th>
                                        <Th>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody overflow="auto">
                                    {borrowNFT.map((data, index) => (
                                        <>
                                            <Tr >
                                                <Td><HStack>
                                                    <Image src={data.url} w="50px" h="50px" />
                                                    <Text fontSize="18px"
                                                        fontWeight="600">{data.collection_name}</Text>

                                                </HStack>
                                                </Td>
                                                <Td>
                                                    {data.total_item}
                                                </Td>
                                                <Td>
                                                    {data.floor_price}
                                                </Td>
                                                <Td>
                                                    {data.name_of_collection == "yieldings" ? Yieldings?.length : data.name_of_collection == "dragonfi_alpha_dragons" ? Dragon.length : data.name_of_collection == "mostly_frens" ? Mostly.length : data.name_of_collection == "flemish_giant" ? Flemish.length : data.name_of_collection == "crazy_goose_flocks" ? Crazy.length : data.name_of_collection == "ai_goanna" ? goanna.length : data.name_of_collection == "mngo" ? Mngo.length : 0}
                                                </Td>
                                                <Td>
                                                    {(data.floor_price * 40) / 100}
                                                </Td>
                                                <Td>
                                                    <Link to="/borrow">
                                                        <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}>Borrow</Button>
                                                    </Link>
                                                </Td>
                                            </Tr>
                                        </>
                                    ))}

                                </Tbody>

                            </Table>
                        </TableContainer>
                    </Container>
                </Box>
            </Box>


        </>
    )
}

export default WhiteListCollection
